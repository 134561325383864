<script setup lang="ts">
import { computed } from 'vue';

import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

const props = defineProps({
  closable: {
    type: Boolean,
    default: false,
  },
  modalImg: {
    type: String,
    default: () => null,
  },
  text: {
    type: Array<Array<string>>,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
});

defineEmits(['close']);

const imageSource = computed(() => new URL(`/src/assets/img/${props.modalImg}.png`, import.meta.url).href);

function formatBulletPoint(bulletPoint: Array<string>) {
  const fullText = bulletPoint[0];
  const highlightedText = bulletPoint[1];
  return fullText.replace(
    highlightedText,
    `<span class="modal-blocked__highlighted">${highlightedText}</span>`,
  );
}
</script>

<template>
  <Modal
    modal-class="modal-blocked"
    :class="{ 'modal-blocked__backdrop': !closable }"
    :width="modalImg ? 850 : 500"
    :has-close-btn="closable"
    :hide-footer="true"
    :forbid-closing="!closable"
    :persistent="!closable"
    :attach="!closable ? '#blockedView' : undefined"
    @close="$emit('close')"
  >
    <template #body>
      <div class="modal-blocked__body">
        <div class="modal-blocked__left" :class="{ 'modal-blocked__left--full-width': !modalImg }">
          <div class="modal-blocked__lock">
            <span class="modal-blocked__lock-background"><v-icon>fa:fas fa-lock</v-icon></span>
          </div>
          <div class="modal-blocked__pretitle">{{ $t('pretitle') }}</div>
          <div class="modal-blocked__title">{{ title }}</div>
          <div v-for="(bulletPoint, index) in text" :key="index" class="modal-blocked__text">
            <v-icon class="modal-blocked__icons">fa:fas fa-arrow-circle-right</v-icon>
            <span v-html="formatBulletPoint(bulletPoint)"></span>
          </div>
          <Btn class="modal-blocked__btn" link-url="https://web.pysae.com/contact" type="primary">
            <v-icon class="modal-blocked__icons">fa:fas fa-plus</v-icon>
            <span>{{ $t('cta') }}</span>
          </Btn>
          <Btn class="modal-blocked__btn" link-url="https://web.pysae.com/contact" type="secondary">
            <v-icon class="modal-blocked__icons">fa:fas fa-question-circle</v-icon>
            <span>{{ $t('learnMore') }}</span>
          </Btn>
        </div>
        <div v-if="modalImg" class="modal-blocked__right">
          <img class="modal-blocked__img" :src="imageSource" />
        </div>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-blocked {
  &__backdrop {
    backdrop-filter: blur(5px);
  }

  .modal__body {
    overflow-y: initial;
    margin-bottom: 0;
    padding: 10px;
  }

  .modal__header {
    display: none;
  }

  &__icons.v-icon {
    margin-right: 10px;
    font-size: 14px;
  }

  &__btn {
    width: 100%;
    font-weight: $font-weight-semi-bold;
  }

  &__btn:first-of-type {
    margin-bottom: 10px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__highlighted {
    color: $primary-light;
  }

  &__img {
    width: 100%;
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 48%;
    padding-left: 15px;

    &--full-width {
      width: 100%;
    }
  }

  &__lock {
    display: flex;
    justify-content: center;
  }

  &__lock-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: $background-variant;

    .v-icon {
      font-size: 18px;
    }
  }

  &__right {
    width: 48%;
  }

  &__title {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid $light-border;
    color: $primary-light;
    font-weight: $font-weight-bold;
    font-size: 24px;
    text-align: center;
  }

  &__text {
    padding-bottom: 10px;
    color: $text-dark;
    font-weight: $font-weight-semi-bold;
    font-size: 14px;

    &:last-of-type {
      padding-bottom: 25px;
    }
  }

  &__pretitle {
    padding-top: 10px;
    font-weight: $font-weight-bold;
    font-size: 20px;
    text-align: center;
  }

  .ui-btn + .ui-btn {
    margin-left: 0 !important;
  }
}
</style>

<i18n locale="fr">
  {
    "pretitle": "Profitez de la fonctionnalité",
    "cta": "Activer l'option",
    "learnMore": "En savoir plus"
  }
  </i18n>

<i18n locale="en">
  {
    "pretitle": "Enjoy this feature",
    "cta": "Activate feature",
    "learnMore": "Learn more"
  }
  </i18n>
