import type { TagProperties } from '@/components/ui/Tag.vue';

enum colors {
  LIGHT_GREEN = '#00B871',
  FLAT_GREEN = '#E6F7F1',
  LIGHT_ORANGE = '#F99C49',
  FLAT_ORANGE = '#FEEBDB',
  DARK_RED = '#A41414',
  LIGHT_RED = '#FBDDDD',
  DARK_GRAY = '#333333',
  LIGHT_GRAY = '#EBEBEB',
}

export const TagTypes: { [name: string]: TagProperties } = {
  SUCCESS: {
    color: colors.LIGHT_GREEN,
    backgroundColor: colors.FLAT_GREEN,
  },
  WARNING: {
    color: colors.LIGHT_ORANGE,
    backgroundColor: colors.FLAT_ORANGE,
  },
  DANGER: {
    color: colors.DARK_RED,
    backgroundColor: colors.LIGHT_RED,
  },
  NEUTRAL: {
    color: colors.DARK_GRAY,
    backgroundColor: colors.LIGHT_GRAY,
  },
};
