<script setup lang="ts">
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';

defineEmits(['close']);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  body: {
    type: String,
    default: () => undefined,
  },
});
</script>

<template>
  <Modal modal-class="activate-feature-modal" @close="$emit('close')">
    <template #title>
      {{ title }}
    </template>

    <template #body>
      <div class="activate-feature-modal__body-container">
        <template v-if="body">
          {{ body }}
        </template>
        <template v-else>
          {{ $t('activateFeature') }}
        </template>
      </div>
    </template>

    <template #cta>
      <!-- class used by GTM to trigger intercom new message -->
      <Btn class="new-message-intercom" type="primary">
        {{ $t('contactUs') }}
      </Btn>
    </template>
  </Modal>
</template>

<style lang="scss">
.activate-feature-modal {
  &__body-container {
    padding: 10px 0;
  }
}
</style>

<i18n locale="fr">
{
  "activateFeature": "Pour activer cette fonctionnalité, veuillez nous contacter.",
  "contactUs": "Nous contacter"
}
</i18n>

<i18n locale="en">
{
  "activateFeature": "To activate the feature please contact us.",
  "contactUs": "Contact us"
}
</i18n>
