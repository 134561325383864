<script setup lang="ts">
import api, { tripUpdates as ApiTripUpdates } from '@/api';

import MapboxDevices from '@/components/map/MapboxDevices.vue';
import NoDeviceConnected from './NoDeviceConnected.vue';

import {
  HighlightType,
  type DeviceAdditionalDatas,
  type DeviceFiltered,
  type MapStop,
  type MapTrip,
} from '@/@types/mapbox';
import { dateObjToGtfsFormat } from '@/libs/helpers/dates';
import { localeCompareSort } from '@/libs/helpers/strings';

import LiveMapDeviceList, { SortField } from './LiveMapDeviceList.vue';
import LiveMapRoutesDiagrams from './LiveMapRoutesDiagrams.vue';
import LiveMapDeviceFilter, { type FilterEvent } from './LiveMapDeviceFilter.vue';
import ModalMapOptions from './ModalMapOptions.vue';

import MapboxMap from '@/components/map/MapboxMap.vue';
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { AlternativeState, deviceTeamsFilterFunction, OFF_ITINERARY } from '@/store/devices';
import { SortDirection, type SortFilter } from '@/components/common/SortFilterButtons.vue';
import { useVehiclesStore } from '@/store-pinia/vehicles';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useStore } from 'vuex';
import type { Route, Stop, Trip } from '@/@types/gtfs';
import type { MapDepot } from '@/store-pinia/depots';
import type { Device } from '@/@types/device';
import type { DropdownOption } from '@/components/common/SelectFiltersDropdown.vue';
import { useI18n } from 'vue-i18n';
import { useTripUpdates } from '@/store-pinia/trip-updates';

const vehiclesStore = useVehiclesStore();
const store = useStore();
const tripUpStore = useTripUpdates();
const i18n = useI18n();

const enum DisplayMode {
  DEVICE = 0,
  ROUTE = 1,
}

const props = defineProps({
  date: {
    type: [String, Number, Date],
    default: null,
  },
  mapContainerHeight: {
    type: String,
    default: '100%',
  },
  minimalVersion: {
    type: Boolean,
    default: false,
  },
});

const displayMode = ref<DisplayMode>(DisplayMode.DEVICE);
const formattedTripNames = ref<{ [deviceId: string]: string }>({});
const mapBounds = ref<mapboxgl.LngLatBounds>();
const mapLoaded = ref<boolean>(false);
const vehicleTagModalShown = ref<boolean>(false);
const selectedDeviceId = ref<string>();
const mapInstance = ref<mapboxgl.Map>();
const searchedDevices = ref<Array<string>>();
const sortFilter = ref<SortFilter>();
const deviceListFormated = ref<Array<DeviceAdditionalDatas>>([]);
const mapDepots = ref<Array<MapDepot>>([]);
const filters = ref<FilterEvent>({
  routes: [],
  teams: [],
  categories: [],
});
const showVehiclesLabels = ref<boolean>(
  localStorage.getItem('settings.op.showMapDeviceLabel') === 'false' ? false : true,
);

const displayTripUpdateInfos = ref<boolean>(false);

const deactivatedRoutes = computed<string[]>(() => store.getters.group.deactivated_routes);
const devices = computed<{ [deviceId: string]: Device }>(() => store.getters['devices/visibleDevices']);
const groupId = computed<string>(() => store.getters.group._id);
const gtfsId = computed<string>(() => store.getters.group.current_file);
const gtfsRoutes = computed<{ [routeId: string]: Route }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'routes'),
);
const gtfsStops = computed<{ [stopId: string]: Stop }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'stops'),
);
const gtfsTrips = computed<{ [tripId: string]: Trip }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'trips'),
);
const routesFilterOptions = computed<Array<DropdownOption>>(() => {
  const routeOptions = Object.values(gtfsRoutes.value).reduce((options: Array<DropdownOption>, route) => {
    options.push({
      label: route.route_short_name,
      id: route.route_id,
      isDeactivated: deactivatedRoutes.value.includes(route.route_id),
      routeProperties: {
        route_short_name: route.route_short_name,
        route_text_color: route.route_text_color,
        route_color: route.route_color,
      },
    });
    return options;
  }, []);
  return routeOptions.sort((a, b) =>
    localeCompareSort(a.label as string, b.label as string, i18n.locale.value),
  );
});

const lineFilterTripsIds = computed<string[]>(() => {
  const routesIds = filters.value.routes.reduce((ids: { [routeId: string]: boolean }, option) => {
    ids[option] = true;
    return ids;
  }, {});

  return Object.keys(gtfsTrips.value).filter(tripId => routesIds[gtfsTrips.value[tripId].route_id]);
});

const mapDevices = computed<Array<DeviceFiltered>>(() => {
  return sortedDevices.value.map(id => ({
    device: devices.value[id],
    id,
    highlight: id === selectedDeviceId.value,
    additionalDatas: deviceListFormated.value.find(d => d._id === id),
  }));
});

const mapStops = computed<Array<MapStop>>(() => {
  const selectedDevice = selectedDeviceId.value ? devices.value[selectedDeviceId.value] : null;

  // Filtered trips is based on selectedDevice, or  lineFilterTripsIds (from map filters)
  const displayedFilteredTrips =
    selectedDevice && selectedDevice.trip_id
      ? Object.keys(gtfsTrips.value).filter(tripId => tripId === selectedDevice.trip_id)
      : lineFilterTripsIds.value;

  const stopIds = Object.keys(
    // Get Stop Id from filtered Trips, or display every stop in gtfs
    displayedFilteredTrips && displayedFilteredTrips.length > 0
      ? displayedFilteredTrips.reduce((acc: { [stopId: string]: boolean }, tripId) => {
          gtfsTrips.value[tripId].stop_times.forEach(st => {
            acc[st.stop_id] = true;
          });
          return acc;
        }, {})
      : gtfsStops.value,
  );

  return stopIds.map(id => ({ id, highlight: false }));
});

const mapTrips = computed<Array<MapTrip>>(() => {
  const tripIds =
    lineFilterTripsIds.value.length > 0 ? lineFilterTripsIds.value : Object.keys(gtfsTrips.value);

  const selectedDevice = selectedDeviceId.value ? devices.value[selectedDeviceId.value] : null;
  // If a device is selected, only display trip from this device
  if (selectedDevice && selectedDevice.trip_id != null) {
    return tripIds
      .filter(id => id === selectedDevice.trip_id)
      .map(id => ({
        id,
        highlight: HighlightType.MORE,
      }));
  }
  return tripIds.map(id => ({ id, highlight: HighlightType.NONE }));
});

const sortedDevices = computed<string[]>(() => {
  const deviceTeamsFilter =
    filters.value.teams?.length > 0 && filters.value.teams.length !== teams.value.length
      ? deviceTeamsFilterFunction(filters.value.teams)
      : undefined;

  const devicesIds = Object.keys(devices.value).filter(deviceId => {
    const device = devices.value[deviceId];

    if (
      lineFilterTripsIds.value?.length > 0 &&
      !lineFilterTripsIds.value.includes(device.trip_id as string)
    ) {
      return false;
    }

    if (deviceTeamsFilter && !deviceTeamsFilter(device)) {
      return false;
    }

    // handle delay state
    let delayCategory = device.delay != null ? store.getters['devices/getDelayState'](device.delay) : null;

    // handle dead running
    if (device.trip_id == null) delayCategory = AlternativeState.DEAD_RUNNING;
    // handle routing
    if (device.trip_pending) delayCategory = AlternativeState.ROUTING;

    if (device.off_itinerary) delayCategory = OFF_ITINERARY;

    if (filters.value.categories?.length > 0) {
      if (!filters.value.categories.includes(delayCategory)) {
        return false;
      }
    }
    if (searchedDevices.value) {
      if (searchedDevices.value.length === 0 || !searchedDevices.value.includes(deviceId)) return false;
    }
    return true;
  });

  const collator = new Intl.Collator(i18n.locale.value, {
    numeric: true,
  });

  const isOnline = (id: string): boolean => !!store.state.devices.online[id];
  const isOnTrip = (id: string): boolean => !!devices.value[id].trip_id;

  const getRouteName = (id: string): string => {
    const tripId = devices.value[id].trip_id || '';
    const route = gtfsRoutes.value[(gtfsTrips.value[tripId] || {}).route_id] || {};
    return route.route_short_name || route.route_long_name;
  };

  const getDelay = (id: string): number => {
    let delay = devices.value[id].delay;
    // Handle case when delay not set, those values must stay last in order sort
    if (delay === null || delay == undefined)
      if (sortFilter.value?.direction)
        sortFilter.value.direction === SortDirection.ASC ? (delay = Infinity) : (delay = -Infinity);
      else delay = Infinity;
    return delay;
  };

  const getVehicleLoad = (id: string): number => devices.value[id]?.vehicle_load || 0;
  const compareBoolean = (a: boolean, b: boolean): number => (a === b ? 0 : a ? -1 : 1);
  const compareNumber = (a: number, b: number): number => (a < b ? -1 : 1);

  const orderByFilter = (a: string, b: string): number => {
    const firstValue = !sortFilter.value || sortFilter.value.direction === SortDirection.ASC ? a : b;
    const secondValue = firstValue !== a ? a : b;
    switch (sortFilter.value?.field?.id) {
      case SortField.PASSENGER_COUNT:
        return compareNumber(getVehicleLoad(firstValue), getVehicleLoad(secondValue));
      case SortField.DELAY:
        return compareNumber(getDelay(firstValue), getDelay(secondValue));
      case SortField.ROUTE:
      default:
        return collator.compare(getRouteName(firstValue), getRouteName(secondValue));
    }
  };

  devicesIds.sort(
    (a, b) =>
      compareBoolean(isOnline(a), isOnline(b)) ||
      compareBoolean(isOnTrip(a), isOnTrip(b)) ||
      orderByFilter(a, b) ||
      collator.compare(formattedTripNames.value[a], formattedTripNames.value[b]),
  );

  return devicesIds;
});

const teams = computed<Array<DropdownOption>>(() => {
  return [
    { id: '', label: i18n.t('noTeam') },
    ...store.getters.activeTeams.map(({ team_id, name }: { team_id: string; name: string }) => ({
      id: team_id,
      label: name ?? team_id,
    })),
  ];
});

watch(
  () => groupId.value,
  async () => {
    loadDrivers();
    await loadVehicles();
    loadActivityLog();
  },
);
watch(
  () => gtfsId.value,
  () => {
    fetchRoutes();
    fetchStops();
  },
  { immediate: true },
);

// #region Device tracking on map
const deviceTrackingInterval = ref<NodeJS.Timeout | null>(null);
const isDeviceTrackedOnMap = ref<boolean>(false);

// When a device is selected, the map bounds are ajusted every 5 seconds to follow the device
function startMapCenterUpdate() {
  deviceTrackingInterval.value = setInterval(() => {
    const updatedDevice = mapDevices.value.find(d => d.id === selectedDeviceId.value);
    if (updatedDevice?.device?.latlng) {
      mapInstance.value?.panTo([updatedDevice.device.latlng[1], updatedDevice.device.latlng[0]]);
    }
  }, 2000);
}

function stopMapCenterUpdate() {
  if (deviceTrackingInterval.value) {
    clearInterval(deviceTrackingInterval.value);
  }
  isDeviceTrackedOnMap.value = false;
}

// We stop the device tracking on the map if the map is dragged
function onMapDragStart() {
  stopMapCenterUpdate();
  selectedDeviceId.value = undefined;
}
// #endregion

watch(
  () => selectedDeviceId.value,
  async () => {
    if (!mapInstance.value) return;
    if (selectedDeviceId.value !== undefined) {
      const mapDevice = mapDevices.value.find(d => d.id === selectedDeviceId.value) || null;
      startMapCenterUpdate();
      isDeviceTrackedOnMap.value = true;
      if (mapDevice) {
        mapInstance.value.setZoom(14);
        if (mapDevice.device.latlng)
          mapInstance.value.panTo([mapDevice.device.latlng[1], mapDevice.device.latlng[0]]);

        if (mapDevice.device.trip) {
          try {
            const tripUpdate = await ApiTripUpdates.getTripUpdatesByTrip(
              groupId.value,
              mapDevice.device.trip!.start_date,
              mapDevice.device.trip!.gtfs_id,
              mapDevice.device.trip!.trip_id,
            );
            if (tripUpdate) {
              tripUpStore.initStore(tripUpdate.gtfs_id, tripUpdate.trip_id, tripUpdate);
              displayTripUpdateInfos.value = true;
            } else resetTripUpdateDisplay();
          } catch (error) {
            resetTripUpdateDisplay();
          }
        }
      }
    } else {
      stopMapCenterUpdate();
      resetTripUpdateDisplay();
    }
  },
);

watch(
  () => displayMode.value,
  () => {
    // handle nice ease-n-out animation between components since map is not reactive by default
    const resizeInterval = setInterval(() => mapInstance.value?.resize(), 30);
    setTimeout(() => {
      clearInterval(resizeInterval);
    }, 3000);
  },
);

function resetTripUpdateDisplay() {
  tripUpStore.$reset();
  displayTripUpdateInfos.value = false;
}

onMounted(async () => {
  loadDrivers();
  await loadVehicles();
  loadActivityLog();
  loadDepots();

  const liveMap = document.getElementById('live-map');
  liveMap?.addEventListener('click', evt => removeTooltipOnClickOutsideMap(evt));
});

async function fetchRoutes() {
  await store.dispatch('gtfs/getRoutesMap', { gtfsId: gtfsId.value });
}

async function fetchStops() {
  await store.dispatch('gtfs/getStopsMap', { gtfsId: gtfsId.value });
}

/**
 * Remove potential tooltips on click outside mapbox
 */
function removeTooltipOnClickOutsideMap(evt: MouseEvent) {
  const mapboxGlCanvas = document.getElementsByClassName('mapboxgl-canvas')[0];
  if (evt.target !== mapboxGlCanvas && mapInstance.value) MapboxHelper.removeAllTooltips(mapInstance.value);
}

function loadActivityLog() {
  store.dispatch(
    'activityLog/loadEntries',
    dateObjToGtfsFormat(props.date ? new Date(props.date) : new Date()),
  );
}

/**
 * Load drivers list for assigned values.
 */
function loadDrivers() {
  store.dispatch('drivers/loadList');
}

/**
 * Load vehicles list for assigned values.
 */
async function loadVehicles() {
  await vehiclesStore.loadList();
}

function onDevicesMouseEnter() {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = 'pointer';
}

function onDevicesMouseLeave() {
  if (mapInstance.value) mapInstance.value.getCanvasContainer().style.cursor = '';
}

function onMapClick(event: mapboxgl.MapLayerMouseEvent) {
  const feature = event.features ? event.features[0] : null;

  // Click on a selected/tracked device
  if (feature?.properties?.id === selectedDeviceId.value) {
    selectedDeviceId.value = undefined;
  }
  // Click on a new device
  else if (feature?.properties) {
    selectedDeviceId.value = feature?.properties?.id;
  } else {
    selectedDeviceId.value = undefined;
  }
}

function onMapLoad({ map }: { map: mapboxgl.Map }) {
  map.once('idle', () => {
    mapInstance.value = map;
    mapLoaded.value = true;
  });
}
/**
 * update filter Object
 */
function handleFilters(newFilters: FilterEvent) {
  filters.value = newFilters;
  selectedDeviceId.value = undefined;
}
function updateSearchedDevices(devices: Array<string>) {
  selectedDeviceId.value = undefined;
  searchedDevices.value = devices;
}
function updateSortFilter(sortingFilter: SortFilter) {
  sortFilter.value = sortingFilter;
}
function updateDeviceListFormated(deviceList: Array<DeviceAdditionalDatas>) {
  deviceListFormated.value = deviceList;
}

const mapboxMapComponent = useTemplateRef('mapboxMapComponent');

async function loadDepots() {
  const depots = await api.depot.getDepots(groupId.value);
  // Convert Depot to MapDepot
  mapDepots.value = depots.map(depot => ({
    id: depot.id,
    latitude: depot.location.latitude,
    longitude: depot.location.longitude,
    radius: depot.radius,
    name: depot.name,
  }));
}

function toggleShowVehiclesLabels(value: boolean) {
  showVehiclesLabels.value = value;
  localStorage.setItem('settings.op.showMapDeviceLabel', value.toString());
}
</script>

<template>
  <div id="live-map" class="live-map">
    <div v-show="!minimalVersion" class="live-map__header">
      <!-- Switch part -->
      <v-btn-toggle
        v-model="displayMode"
        class="live-map__header__toggle-selector"
        variant="outlined"
        color="success"
        rounded="lg"
        mandatory
        divided
      >
        <v-btn>
          <v-icon class="mr-2">fa:fas fa-bus</v-icon>
          <span>{{ $t('trips') }}</span>
        </v-btn>
        <v-btn>
          <v-icon class="mr-2">$routesStops</v-icon>
          <span>{{ $t('routes') }}</span>
        </v-btn>
      </v-btn-toggle>

      <LiveMapDeviceFilter
        class="live-map__filters"
        :teams-filter-options="teams"
        :routes-filter-options="routesFilterOptions"
        @updateFilters="handleFilters"
      />
    </div>

    <div class="live-map__content">
      <div
        v-show="!minimalVersion"
        class="live-map__devices-or-routes"
        :class="{ 'live-map__devices-or-routes__extended': displayMode === DisplayMode.ROUTE }"
      >
        <NoDeviceConnected v-if="sortedDevices.length === 0" class="live-map__content__no-device" />
        <template v-else>
          <Transition name="fadeEnterOnly">
            <LiveMapDeviceList
              v-if="displayMode === DisplayMode.DEVICE"
              v-model:selected-device-id="selectedDeviceId"
              :sorted-devices="sortedDevices"
              @searchFilterList="updateSearchedDevices"
              @updateSortFilter="updateSortFilter"
              @deviceListFormated="updateDeviceListFormated"
            />
          </Transition>

          <Transition name="fadeEnterOnly">
            <LiveMapRoutesDiagrams
              v-if="displayMode === DisplayMode.ROUTE"
              v-model:selected-device-id="selectedDeviceId"
              :filtered-devices="sortedDevices"
            />
          </Transition>
        </template>
      </div>
      <div class="live-map__right-side" :style="`height: ${mapContainerHeight}`">
        <div class="live-map__map" :class="{ 'live-map__map__no-margin': minimalVersion }">
          <MapboxMap
            ref="mapboxMapComponent"
            v-model:bounds="mapBounds"
            :full-screen-option="minimalVersion ? false : true"
            :gtfs-id="gtfsId"
            display-tooltip
            :stops="mapStops"
            :depots="mapDepots"
            :trip-update-mode="displayTripUpdateInfos"
            :trips="mapTrips"
            view-name="liveMap"
            :show-vehicle-tag-dropdown-option="!minimalVersion"
            :dropdown-style-option-only="minimalVersion"
            :is-device-tracked-on-map="isDeviceTrackedOnMap"
            @click="onMapClick"
            @click:device="onMapClick"
            @dragStart="onMapDragStart"
            @load="onMapLoad"
            @openVehicleTagModal="vehicleTagModalShown = true"
          >
            <MapboxDevices
              v-if="mapInstance"
              :devices="mapDevices"
              :gtfs-id="gtfsId"
              :map="mapInstance"
              :show-labels="showVehiclesLabels"
              display-tooltip
              @click="e => mapboxMapComponent?.onMapClick(e, 'device')"
              @mouseenter="onDevicesMouseEnter"
              @mouseleave="onDevicesMouseLeave"
            />
          </MapboxMap>
        </div>
      </div>
    </div>
    <ModalMapOptions
      v-if="vehicleTagModalShown"
      :show-vehicles-label="showVehiclesLabels"
      @toggleShowVehiclesLabels="toggleShowVehiclesLabels"
      @close="vehicleTagModalShown = false"
    />
  </div>
</template>

<style lang="scss">
.live-map {
  $header-height: 60px;

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $canvas;

  &__header {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    max-height: $header-height;
    padding: 12px;

    &__toggle-selector {
      height: 36px !important;
      color: $text-dark;

      .v-btn:not(:last-child) {
        border-inline-end-color: $primary-light;
      }

      .v-btn {
        width: 50%;
        cursor: pointer;
      }

      .v-btn--active {
        border-width: 1px;
        border-color: $primary-light;

        path {
          fill: $primary-light;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;
    max-height: calc(100vh - $navbar-top - $header-height);

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
    }

    &__no-device {
      width: 100%;
      margin: $view-standard-padding;

      @media (max-width: 500px) {
        font-size: 12px;
      }

      @media (max-width: 400px) {
        background-image: none !important;
      }
    }
  }

  &__devices-or-routes {
    display: flex;
    flex: 1;
    min-width: 400px;
    border-top: 1px solid $light-border;
    transition: all 0.3s ease-in-out;

    @media (max-width: 500px) {
      min-width: 0;
      height: 50%;
    }

    @media (max-width: 1200px) {
      height: 50%;
    }

    &__extended {
      min-width: 70%;
    }
  }

  &__right-side {
    display: flex;
    flex: 3;
    flex-direction: column;
  }

  &__filters {
    flex: 0 1 auto;
    justify-content: right;
    border-bottom: $light-border;

    @media (max-width: 1200px) {
      justify-content: start;
      margin-left: $view-standard-padding;
    }
  }

  &__map {
    position: relative;
    flex: 1 1 auto;
    height: 100%;

    &__layers {
      position: relative;
      z-index: $map-layers-index;
      margin: 16px;
    }

    &__no-margin {
      margin: 0;
    }
  }
}
</style>

<i18n locale="fr">
{
  "trips": "Courses",
  "routes": "Lignes",
  "filter": "Filtre",
  "previousMap": "Interface précédente",
}
</i18n>

<i18n locale="en">
{
  "trips": "Trips",
  "routes": "Routes",
  "filter": "Filter",
  "previousMap": "Previous map",
}
</i18n>
