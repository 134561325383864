<template>
  <Modal modal-class="modal-new-message" @close="close">
    <template #title>
      {{ $t('newMessage') }}
    </template>

    <template #body>
      <div class="form-group">
        <label class="form-group__label" for="selected-entities">
          {{ $t('selectRecipients') }}
        </label>

        <Selector
          id="selectedEntities"
          v-model:value="selectedEntities"
          class="new-message-selector"
          mode="tags"
          groups
          :placeholder="$t('selectDevices')"
          :options="entitiesOptions"
          group-options="options"
          object
        />

        <MultipleTeamSelector
          id="selectedTeams"
          :selected-teams="selectedTeams"
          class="new-message-selector"
          edit-mode
          classic-ui
          classic-input-display
          @valueChanged="updateSelectedTeams"
        />

        <v-checkbox id="select-all" v-model="allSelected" color="success" hide-details>
          <template #label>
            {{ $t('allDevices') }}
          </template>
        </v-checkbox>
      </div>

      <div class="form-group">
        <textarea
          id="message-content"
          v-model="messageContent"
          :placeholder="$t('placeholder')"
          class="form-group__input form-group__input--area"
          rows="5"
          :maxlength="maxLength"
        />
      </div>

      <div v-show="messageContent.length >= maxLength" class="form-group">
        {{ $t('tooMuchCharacters', [maxLength]) }}
      </div>

      <div class="form-group">
        <v-checkbox id="urgent" v-model="urgent" color="success" hide-details>
          <template #label>
            {{ $t('urgentMessage') }}
          </template>
        </v-checkbox>
      </div>
    </template>

    <template #cta>
      <Btn type="primary" :disabled="!formIsValid" @click="submit">
        {{ $t('send') }}
      </Btn>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import Selector from '@/components/ui/Selector.vue';
import MultipleTeamSelector from '@/components/ui/MultipleTeamSelector.vue';
import { filterSameValueInList } from '@/libs/helpers/objects';
import { deviceTeamsFilterFunction } from '@/store/devices';

/** @enum {string} */
export const RecipientType = {
  DEVICE: 'device_id',
  ROUTE: 'route_id',
  TRIP: 'trip_id',
};

export default {
  name: 'ModalMessageNew',

  components: {
    Btn,
    Modal,
    Selector,
    MultipleTeamSelector,
  },

  props: {
    /** @type {Object} */
    message: {
      type: Object,
      default: () => ({}),
    },
    /** @type {Vue.PropOptions<Array<Recipient>>} */
    recipients: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['close', 'sent'],

  data: () => ({
    /** @type {boolean} */
    allSelected: false,

    /** @type {string} */
    messageContent: '',

    /** @type {Array<Object>} */
    entitiesOptions: [],

    /** @type {Array<Object>} */
    selectedEntities: [],

    /** @type {Array<String>} */
    selectedTeams: [],

    /** @type {boolean} */
    urgent: false,
  }),

  computed: {
    /** @return {Array<Object>} */
    allDevicesOptions() {
      // Get devices list (archived ones excluded).
      const devices = Object.values(this.$store.state.devices.list).filter(device => !device.archived);

      // Construct final devices map.
      return devices.map(d => ({
        label: `${!d.name ? '' : `${d.name} `}<${d.device_id}>`,
        value: d.device_id,
        type: RecipientType.DEVICE,
      }));
    },

    /** @return {boolean} */
    formIsValid() {
      const isContentValid = this.messageContent && this.messageContent.length < this.maxLength;
      const areRecipientsValid =
        this.selectedTeams.length || this.selectedEntities.length || this.allSelected;
      return isContentValid && areRecipientsValid;
    },

    /** @return {number} */
    maxLength() {
      return this.urgent ? 50 : 300;
    },
  },

  created() {
    // Get all needed data for devices list.
    this.getOptionsDevice();
    this.recipients.forEach(recipient => this.preselectRecipient(recipient));
    this.messageContent = this.message.content || '';
    this.urgent = !!this.message.urgent;
  },

  methods: {
    /**
     * Build recipient object from device id
     * @param {string} deviceId
     */
    buildRecipient(deviceId) {
      return {
        address: `device_id:${deviceId}`,
      };
    },

    /**
     * Close this component.
     */
    close() {
      this.$emit('close');
    },

    /**
     * Get unique & active GtfsRoutes.
     * @return {Promise<Array<Route>>}
     */
    async getActiveGtfsRoutes() {
      const od = this.getOnlineDevices();
      const gtfsId = await this.$store.dispatch('gtfs/getGtfsAt', {
        ts: new Date().getTime() / 1000,
      });
      const gtfsRoutes = await this.$store.dispatch('gtfs/getRoutesMap', gtfsId);

      // Get unique routes for online devices...
      let result = Object.values(od)
        .filter(value => value.route_id)
        .map(value => {
          // Looking for route short name
          return {
            label: gtfsRoutes[value.route_id]?.route_short_name,
            value: value.route_id,
            type: RecipientType.ROUTE,
          };
        });

      result = filterSameValueInList(result, 'value');
      return result;
    },

    /**
     * Get unique & active GtfsTrips.
     * @return {Promise<Array<Trip>>} - Trip: { label, value, type }
     */
    async getActiveGtfsTrips() {
      const od = this.getOnlineDevices();

      // Get unique trips for online devices...
      let result = await Promise.all(
        Object.values(od)
          .filter(device => device.trip_id)
          .map(async device => {
            const name = await this.$store.dispatch('gtfs/formatTripName', {
              tripId: device.trip_id,
              date: new Date(device.ts * 1000),
            });

            return {
              label: name,
              value: device.trip_id,
              type: RecipientType.TRIP,
            };
          }),
      );

      result = filterSameValueInList(result, 'value');
      return result;
    },

    /**
     * Get all online devices.
     * @return {{[deviceId: string]: import('@/store/devices').Device}}
     */
    getOnlineDevices() {
      return this.$store.getters['devices/onlineDevices'];
    },

    /**
     * Get current group devices, active routes & active trips.
     */
    async getOptionsDevice() {
      const routesPromises = this.getActiveGtfsRoutes();
      const tripsPromises = this.getActiveGtfsTrips();
      const [routes, trips] = await Promise.all([routesPromises, tripsPromises]);
      this.entitiesOptions = [
        {
          label: this.$t('devices'),
          options: this.allDevicesOptions,
        },
        {
          label: this.$t('trackedRoutes'),
          options: routes,
        },
        {
          label: this.$t('trackedTrips'),
          options: trips,
        },
      ];
    },

    /**
     * If a recipient is definied in props, preselect it in devices list.
     */
    async preselectRecipient(recipient) {
      if (!(recipient && recipient.type && recipient.id)) {
        return;
      }

      const tag = {
        value: recipient.id,
        type: recipient.type,
      };

      if (recipient.type === RecipientType.DEVICE) {
        const device = this.$store.state.devices.list[recipient.id];
        tag.label = `${device.label ?? ''}${device.label ? ' ' : ''}<${recipient.id}>`;
      }

      // A tag is defined. Let's add it to devices list.
      if (tag.value) {
        this.selectedEntities.push(tag);
      }
    },

    /** @return {Promise<device_id[]>} */
    getRecipients() {
      if (this.allSelected) {
        return this.allDevicesOptions.map(({ value }) => this.buildRecipient(value));
      }

      const recipientsDevices = [];

      if (this.selectedTeams.length) {
        Object.values(this.$store.state.devices.list)
          .filter(deviceTeamsFilterFunction(this.selectedTeams))
          .forEach(device => recipientsDevices.push(device.device_id));
      }

      const routes = [];
      const trips = [];

      this.selectedEntities.forEach(({ type, value }) => {
        switch (type) {
          case RecipientType.DEVICE:
            recipientsDevices.push(value);
            break;
          case RecipientType.ROUTE:
            routes.push(value);
            break;
          case RecipientType.TRIP:
            trips.push(value);
            break;
          default:
            break;
        }
      });

      // break fast
      if (!routes.length && !trips.length) {
        return recipientsDevices.map(this.buildRecipient);
      }

      const onlineDevices = this.getOnlineDevices();
      Object.values(onlineDevices).forEach(onlineDevice => {
        if (routes.includes(onlineDevice.route_id) || trips.includes(onlineDevice.trip_id)) {
          recipientsDevices.push(onlineDevice.device_id);
        }
      });

      return recipientsDevices.map(this.buildRecipient);
    },

    /**
     * Submit to create a message.
     * @emits ModalMessageNew#sent
     */
    async submit() {
      // All elements defined?
      if (!this.formIsValid) return;

      const recipients = await this.getRecipients();
      // Message assembly
      const message = {
        sender: {
          client: 'op',
          user_id: this.$store.state.user._id,
        },
        recipients,
        subject: this.messageContent,
      };

      if (this.urgent) {
        message.urgent = true;
      }

      // Post message.
      this.$store.dispatch('messages/post', message);

      // Emit sent event.
      this.$emit('sent');

      // Close this component.
      this.close();
    },

    updateSelectedTeams(selectedTeams) {
      this.selectedTeams = selectedTeams.value;
    },
  },
};

/** @typedef {Object} Recipient
 * @property {string} id
 * @property {RecipientType} type
 * @property {string} [label]
 */

/**
 * @typedef {Object} Route
 * @property {string} label
 * @property {string} value
 * @property {string} type
 */

/**
 * @typedef {Object} Trip
 * @property {string} label
 * @property {string} value
 * @property {string} type
 */
</script>

<style lang="scss">
.modal-new-message {
  .modal__body {
    overflow-y: initial;
  }

  .new-message-selector {
    margin-bottom: 15px;
  }

  .v-label {
    font-weight: $font-weight-semi-bold;
  }
}
</style>

<i18n locale="fr">
{
  "selectDevices": "Appareils",
  "selectTeams": "Équipes",
  "placeholder": "Votre message...",
  "allDevices": "Tous les appareils",
  "newMessage": "Nouveau message",
  "selectRecipients": "À",
  "send": "Envoyer",
  "title": "Titre",
  "tooMuchCharacters": "Nombre de caractères max : {0}",
  "trackedRoutes": "Lignes suivies",
  "trackedTrips": "Courses suivies",
  "noDevice": "Pas d'appareil",
}
</i18n>

<i18n locale="en">
{
  "selectTeams": "Teams",
  "selectDevices": "Devices",
  "placeholder": "Type here...",
  "allDevices": "All devices",
  "newMessage": "New message",
  "selectRecipients": "To",
  "send": "Send",
  "title": "Title",
  "tooMuchCharacters": "Character limit: {0}",
  "trackedRoutes": "Tracked routes",
  "trackedTrips": "Tracked trips",
  "noDevice": "No device",
}
</i18n>

<i18n locale="cz">
{
  "newMessage": "Zpráva pro řidiče:",
  "selectRecipients": "Vyberte příjemce",
  "title": "Nadpis",
  "tooMuchCharacters": "Limit počtu znaků: {0} ",
  "trackedRoutes": "Sledované trasy",
  "trackedTrips": "Sledované jízdy",
  "allDevices": "Všechna zařízení",
  "send": "Odeslat"
}
</i18n>

<i18n locale="de">
{
  "newMessage": "Nachricht an die Fahrer:",
  "selectRecipients": "Empfänger auswählen",
  "title": "Titel",
  "tooMuchCharacters": "Max. Zeichenanzahl: {0}",
  "trackedRoutes": "Überwachte Strecken",
  "trackedTrips": "Überwachte Fahrten",
  "allDevices": "Alle Geräte",
  "send": "Senden"
}
</i18n>

<i18n locale="es">
{
  "newMessage": "Mensaje para los conductores:",
  "selectRecipients": "Selecciona los destinatarios",
  "title": "Título",
  "tooMuchCharacters": "Límite de caracteres: {0}",
  "trackedRoutes": "Rutas registradas",
  "trackedTrips": "Viajes registrados",
  "allDevices": "Todos los dispositivos",
  "send": "Enviar"
}
</i18n>

<i18n locale="it">
{
  "newMessage": "Messaggio agli autisti:",
  "selectRecipients": "Seleziona i destinatari",
  "title": "Titolo",
  "tooMuchCharacters": "Limite di caratteri: {0}",
  "trackedRoutes": "Percorsi tracciati",
  "trackedTrips": "Viaggi tracciati",
  "allDevices": "Tutti i dispositivi",
  "send": "Invia"
}
</i18n>

<i18n locale="pl">
{
  "newMessage": "Wiadomość do kierowców:",
  "selectRecipients": "Wybierz odbiorców",
  "title": "Tytuł",
  "tooMuchCharacters": "Limit znaków: {0}",
  "trackedRoutes": "Śledzone trasy",
  "trackedTrips": "Śledzone podróże",
  "allDevices": "Wszystkie urządzenia",
  "send": "Wyślij"
}
</i18n>
