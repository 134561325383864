export const COLUMN_KEY_ACTION = 'actions';
export const NO_DATA = '-';

/** @enum {string} */
export const StateMergingChildren = {
  SHOWN: 'shown',
  HIDDEN: 'hidden',
};

/** @enum {string} */
export const SortOrder = {
  ASC: 'asc',
  DESC: 'desc',
};

export class DataGridDataCell {
  /**
   * Creates an instance of DataGridDataCell.
   * @param {Object} args
   * @param {import("vue").Component | String} [args.component] - Component used to render data
   * @param {Object} [args.props] - Props passed to component
   * @param {Object} [args.events] - Event listeners passed to component
   * @param {String | Number} [args.value = null] - Value displayed as a child of the component
   */
  constructor({ component, props, events, value = null }) {
    this.component = component;
    this.props = props;
    this.events = events;
    this.value = value;
  }
}

export class DataGridColumn {
  /**
   * Creates an instance of DataGridColumn.
   * @param {Object} args
   * @param {String} args.title - The displayed column name
   * @param {String} [args.tooltip] - The column tooltip
   * @param {String} args.key - The column key
   * @param {function} [args.value = null] - function to get value from object, by default item => item[key]
   * @param {CellBuilder} [args.cellBuilder] - The function used to build the cell, if not defined = default cell
   * @param {Boolean} [args.selectable = true] - Is the column selectable / unselectable ?
   * @param {Boolean} [args.defaultSelected = true] - Is the column selected by default ?
   * @param {Boolean} [args.sortable = true] - Is the column sortable ?
   * @param {Boolean | Function} [args.filterable = false] - Is the column filterable ? ? If defined as a function, this will be used as {value, label} object factory for DataGridColumnFilter.
   * @param {String} [args.i18nPrefix = null] - only used if filterable column, string used to add a prefix on filters values for translation
   * @memberof DataGridColumn
   */
  constructor({
    title,
    tooltip = null,
    key,
    value = null,
    cellBuilder,
    selectable = true,
    defaultSelected = true,
    sortable = true,
    filterable = false,
    i18nPrefix = null,
  }) {
    let valueFunction = value;
    // default value is custom not set
    if (!value) valueFunction = item => item[key];
    this.title = title;
    this.tooltip = tooltip;
    this.key = key;
    this.value = valueFunction;
    this.cellBuilder = cellBuilder;
    this.selectable = selectable;
    this.defaultSelected = defaultSelected;
    this.sortable = sortable;
    this.filterable = filterable;
    this.i18nPrefix = i18nPrefix;
  }

  /**
   * Is the column selected ?
   *
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isSelected() {
    return this.defaultSelected;
  }

  /**
   * Is the column selectable / unselectable ?
   *
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isSelectable() {
    return this.selectable;
  }

  /**
   * Returns string used to add a prefix on possible filters values
   *
   * @return {String}
   * @memberof DataGridColumn
   */
  getI18nPrefix() {
    return this.i18nPrefix;
  }

  /**
   * Is equal ?
   *
   * @param {DataGridColumn} column
   * @return {Boolean}
   * @memberof DataGridColumn
   */
  isEqual(column) {
    return Boolean(column && column.getKey() === this.getKey());
  }

  /**
   * Returns column type
   *
   * @return {String}
   * @memberof DataGridColumn
   */
  getKey() {
    return this.key;
  }

  /**
   * Is the column filterable?
   * @returns {boolean}
   */
  isFilterable() {
    return this.filterable;
  }
}

export class DataGrid {
  /**
   * Creates an instance of DataGrid.
   * @param {Object} args
   * @param {?Array<DataGridColumn>} [args.columns=[]]
   * @param {string} [args.name = ''] - Datagrid name, used for localStorage (save columns size, column to display...)
   * @param {Boolean} [args.showColumnSelector = true] - display column selector or not
   * @param {?Array<string>} [args.searchFields = null] - fields searched by input search
   * @param {?String} [args.columnSelectionLocalStorageKey = null] - used & required for inner columnSelectorDatagrid in Datagrid header
   * @param {Boolean} [args.selectableList = false] - display checkbox for each rows if true
   * @param {String} [args.rowIdKey = 'id'] - object field used as key to identify a row
   * @param {Boolean} [args.hasActions = false ] - used to automatically create an action column, actions are passed by a slot to datagrid
   * @param {Array<SortBy>} [args.defaultSortBy = []] - default column sorting by
   * @param {Boolean} [args.useCustomNoData = false] - custom display "noData" instead of default text
   * @param {?Boolean} [args.hasFirstRowSticky = true] - set first row sticky
   * @memberof DataGrid
   */
  constructor({
    columns = [],
    name = '',
    searchFields = null,
    showColumnSelector = true,
    columnSelectionLocalStorageKey = null,
    selectableList = false,
    rowIdKey = 'id',
    hasActions = false,
    defaultSortBy = [],
    useCustomNoData = false,
    hasFirstRowSticky = true,
  }) {
    this.initializeColumns(columns, hasActions);
    this.name = name;
    this.showColumnSelector = showColumnSelector;
    this.searchFields = searchFields;
    this.columnSelectionLocalStorageKey = columnSelectionLocalStorageKey;
    this.selectableList = selectableList;
    this.rowIdKey = rowIdKey;
    this.defaultSortBy = defaultSortBy;
    this.hasActions = hasActions;
    this.useCustomNoData = useCustomNoData;
    this.hasFirstRowSticky = hasFirstRowSticky;
  }

  initializeColumns(columns, hasActions) {
    if (hasActions) {
      columns.push(
        new DataGridColumn({
          key: COLUMN_KEY_ACTION,
          title: 'column.actions',
          defaultSelected: true,
          selectable: false,
          sortable: false,
          value: () => null,
        }),
      );
    }
    this.columns = columns;
  }

  /**
   * get default sort by
   * @return {Array<SortBy>}
   * @memberof DataGrid
   */
  getDefaultSortBy() {
    return this.defaultSortBy;
  }

  // new here
  /**
   * Is this list selectable
   *
   * @return {Boolean}
   * @memberof DataGrid
   */
  isSelectableList() {
    return this.selectableList;
  }

  /**
   * Return local Storage key for saved filters of this datagrid
   * @return {String}
   * @memberof DataGrid
   */
  localStorageFilterName() {
    return `settings.op.${this.name}`;
  }

  /**
   * get row id key
   *
   * @return {String}
   * @memberof DataGrid
   */
  getRowIdKey() {
    return this.rowIdKey;
  }

  /**
   * Returns filterable columns
   *
   * @return {Array<DataGridColumn>}
   */
  getFilterableColumns() {
    return this.getColumns().filter(c => c.isFilterable());
  }

  /**
   * Returns selectable columns
   *
   * @return {Array<DataGridColumn>}
   * @memberof DataGrid
   */
  getSelectableColumns() {
    return this.getColumns().filter(c => c.selectable);
  }

  /**
   * Get Column selection LocalStorage key (used for inner Datagrid ColumnSelection)
   * @returns {?String}
   */
  getColumnSelectionLocalStorageKey() {
    return this.columnSelectionLocalStorageKey;
  }

  /**
   * Returns selected columns by default
   *
   * @return {Array<DataGridColumn>}
   * @memberof DataGrid
   */
  getSelectedColumnsByDefault() {
    return this.getSelectableColumns().filter(c => c.isSelected());
  }

  /**
   * Returns columns
   *
   * @return {Array<DataGridColumn>}
   * @memberof DataGrid
   */
  getColumns() {
    return this.columns;
  }

  /**
   * Return a column from a column name
   *
   * @param {String} columnType
   * @return {DataGridColumn}
   * @memberof DataGrid
   */
  getColumn(columnType) {
    return this.columns.find(c => c.columnType === columnType);
  }

  /**
   * Returns locales keys
   *
   * @return {Array<String>}
   * @memberof DataGrid
   */
  getLocaleKeys() {
    return this.columns.map(columns => columns.localeKey);
  }

  /**
   * Return datagrid Name (used to save filters)
   * @returns {string}
   * @memberof DataGrid
   */
  getName() {
    return this.name;
  }

  /**
   * Get fields used for searchBar
   * @returns {?Array<string>}
   */
  getSearchFields() {
    return this.searchFields;
  }

  /**
   * Returns api field names
   *
   * @return {Array<Array<String>>}
   * @memberof DataGrid
   */
  getApiFieldNames() {
    return this.columns.map(columns => columns.apiFieldNames);
  }
}

/**
 * Callback used to build the cell
 *
 * @callback CellBuilder
 * @param {Array} values - Values resulting in the mapping between data and apiFieldNames
 * @param {any} extra - Useful extra data to build the cell
 * @return {DataGridDataCellDef | null}
 */

/**
 * @typedef {Object} DataGridDataCellDef
 * @property {import("vue").Component | String} [component]
 * @property {Object} [props]
 * @property {Object} [events]
 * @property {String | Number} [value]
 */

/**
 * @typedef {Object} ColumnFilterState
 * @property {{[key: string]: boolean}} state - contains current checked state for each value in `list`.
 * @property {Array<string>} list - contains all values, sorted.
 * @property {string} columnName - name of the column.
 * @property {string} groupId - groupId selected, in order to save filter in LS.
 * @property {string} i18nPrefix - string used to add a prefix on possible filters values
 * @property {boolean} isActive - is `false` when all values are checked in `state`. Automatically updated in `DataGridColumnFilter` component.
 */

/**
 * @typedef {Object} SortBy
 * @property {string} key - key to sort.
 * @property {SortOrder} order - order by desc or asc.
 */
