<script setup lang="ts">
import { computed, onMounted, ref, type Ref, type PropType } from 'vue';
import Btn from '@/components/ui/Btn.vue';
import type { DisplayOptions, LayerOptions } from '@/@types/mapbox';

const props = defineProps({
  modelValue: {
    type: Object as PropType<LayerOptions>,
    default: () => {},
  },
  showOnlyStyleOptions: {
    type: Boolean,
    default: false,
  },
  showVehiclesOption: {
    type: Boolean,
    default: false,
  },
  displayOptions: {
    type: Object as PropType<DisplayOptions>,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue', 'open-modal', 'switchStyle']);

const openedDropdown = ref<boolean>(false);
const mapStyle = ref<string>('map');

const dropdown = ref<HTMLElement>() as Ref<HTMLElement>;

const localModelValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

onMounted(() => {
  mapStyle.value = localStorage.getItem('map/style') || 'map';
});

/** Close dropdown */
function dropdownClose(event?: Event) {
  // Prevent closing the dropdown when the click
  // comes from a child element, except the button.
  const findParent = (elem: any) => {
    let found = false;

    while (!found && elem) {
      found = elem.parentElement === dropdown.value;
      // eslint-disable-next-line no-param-reassign
      elem = elem.parentElement;
    }

    return found;
  };

  if (event && event.target && findParent(event.target)) return;

  openedDropdown.value = false;
  window.removeEventListener('click', dropdownClose);
}

/** Open dropdown */
function dropdownOpen() {
  openedDropdown.value = true;
  window.removeEventListener('click', dropdownClose);
  setTimeout(() => window.addEventListener('click', dropdownClose), 10);
}

/** Toggle dropdown */
function dropdownToggle() {
  if (!openedDropdown.value) {
    dropdownOpen();
  } else {
    dropdownClose();
  }
}

function selectType(type: string) {
  mapStyle.value = type;
  emit('switchStyle', type);
}
</script>

<template>
  <div class="layers-dropdown">
    <div ref="dropdown" class="dropdown" :class="{ 'dropdown--open': openedDropdown }">
      <button
        :aria-expanded="openedDropdown"
        aria-haspopup="true"
        class="dropdown__button"
        @click="dropdownToggle()"
        @mousedown.prevent=""
      >
        <font-awesome-icon icon="fa-layer-group" />
      </button>

      <div :aria-hidden="!openedDropdown" class="dropdown__menu">
        <div class="layers-dropdown__content">
          <div class="btn-group">
            <Btn
              class="btn"
              :class="{ 'ui-btn--active': mapStyle === 'map' }"
              type="secondary"
              @click="selectType('map')"
              @mousedown.prevent
            >
              <div>
                <font-awesome-icon icon="fa-map" />
                <div>{{ $t('map') }}</div>
              </div>
            </Btn>
            <Btn
              class="btn"
              :class="{ 'ui-btn--active': mapStyle === 'satellite' }"
              type="secondary"
              @click="selectType('satellite')"
              @mousedown.prevent
            >
              <div>
                <font-awesome-icon icon="fa-satellite" />
                <div>{{ $t('satellite') }}</div>
              </div>
            </Btn>
          </div>
          <div v-if="!showOnlyStyleOptions" class="dropdown__separator"></div>
          <div v-if="showVehiclesOption" class="layers-dropdown__vehicle-tags" @click="$emit('open-modal')">
            <font-awesome-icon icon="fa-gear" />
            {{ $t('vehicleTags') }}
          </div>
          <ul v-if="!showOnlyStyleOptions">
            <template v-for="(value, key) in displayOptions" :key="key">
              <li v-if="value" class="dropdown__item c-layer">
                <v-checkbox v-model="localModelValue[key]" color="success" hide-details>
                  <template #label>
                    {{ $t(key) }}
                  </template>
                </v-checkbox>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.layers-dropdown {
  width: 30px;

  &__content {
    display: flex;
    flex-direction: column;
    width: 155px;
    font-size: 12px;
  }

  &__vehicle-tags {
    margin: 0 0 12px;
    padding: 4px 6px;
    border: 1px solid $border;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }
  }

  .btn-group {
    color: $text-dark-variant;
    font-size: 12px;

    > .ui-btn {
      width: 50%;
      height: 58px;
      padding: 12px $view-standard-padding;
      background-color: transparent;
      font-weight: $font-weight-medium;
      text-align: center;

      .fa-map {
        font-size: 12px;
      }

      svg:not(:last-child) {
        margin: 0;
      }

      &:hover,
      &--active {
        border-color: $primary-light;
        background-color: $transparent-primary;
        color: $primary-light;
      }

      &:last-child {
        &:hover,
        &--active {
          border-left-color: $border;
        }
      }
    }
  }

  .c-layer {
    &__param {
      margin-left: auto;
      padding-right: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }

  .dropdown--open .dropdown__menu {
    padding: 16px;
  }

  .dropdown {
    &__button {
      padding: 5px;
      border: 1px solid $border-variant;
      border-radius: 5px;
      background-color: $canvas;
      color: $mapbox-icons;
      line-height: 14px;

      svg {
        font-size: 15px;
      }

      &:hover {
        background-color: change-color($color: $canvas, $alpha: 0.8);
      }
    }

    .v-label {
      font-size: 12px;
    }

    &__item {
      display: flex;
      padding: 0;

      &:not(:last-of-type) {
        padding-bottom: 8px;
      }

      &:hover {
        background-color: transparent;
      }
    }

    &__menu {
      margin-top: 10px;
      border: 1px solid $border-variant;
      border-radius: 8px;
      background-color: white;
      box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
    }

    &__separator {
      height: 1px;
      margin: 12px -16px;
      border-top: 1px solid $light-border;
    }
  }
}
</style>

<i18n locale="fr">
{
  "linesShapes": "Tracés de lignes",
  "stations": "Stations",
  "stopsZones": "Zones d'arrêt",
  "traffic": "Trafic",
  "vehicleTags": "Etiquettes véhicules",
  "map": "Plan",
  "satellite": "Satellite",
  "depots": "Dépôts"
}
</i18n>

<i18n locale="en">
{
  "linesShapes": "Line plots",
  "stations": "Stations",
  "stopsZones": "Stop zones",
  "traffic": "Traffic",
  "vehicleTags": "Vehicle tags",
  "map": "Map",
  "satellite": "Satellite",
  "depots": "Bus depots"
}
</i18n>

<i18n locale="cz">
{
  "stations": "Stanic",
  "stopsZones": "Okruh zastávky",
  "vehicles": "Vozidla",
  "traffic": "Provoz",
  "linesShapes": "Plány linek"
}
</i18n>

<i18n locale="de">
{
  "stations": "Stationen",
  "stopsZones": "Haltezonen",
  "vehicles": "Fahrzeuge",
  "traffic": "Verkehr",
  "linesShapes": "Fahrtrouten"
}
</i18n>

<i18n locale="es">
{
  "stations": "Estaciones",
  "stopsZones": "Zonas de parada",
  "vehicles": "Vehículos",
  "traffic": "Tráfico",
  "linesShapes": "Gráfica de líneas"
}
</i18n>

<i18n locale="it">
{
  "stations": "Stazioni",
  "stopsZones": "Aree di sosta",
  "vehicles": "Veicoli",
  "traffic": "Traffico",
  "linesShapes": "Tracciato delle linee"
}
</i18n>

<i18n locale="pl">
{
  "stations": "Stacje",
  "stopsZones": "Strefy postoju",
  "vehicles": "Pojazdy",
  "traffic": "Ruch uliczny",
  "linesShapes": "Wykaz tras"
}
</i18n>
