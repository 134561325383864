<script setup lang="ts">
import { watch, type PropType } from 'vue';
import { MapboxHelper } from '@/components/map/mapboxHelper';

const URGENCY_SOURCE_ID = 'urgencySource';
const URGENCY_LAYER_ID = 'urgencyLayer';

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  positionSource: {
    type: Object as PropType<GeoJSON.Feature<GeoJSON.Point>>,
    required: true,
  },
});

const addUrgencyLayer = () => {
  MapboxHelper.addLayer(props.map, {
    id: URGENCY_LAYER_ID,
    type: 'symbol',
    source: URGENCY_SOURCE_ID,
    layout: {
      'icon-image': 'urgencyIcon',
      'icon-size': 0.7,
      'icon-allow-overlap': true,
    },
  });
};

// Init source and layer
MapboxHelper.createEmptySource(props.map, URGENCY_SOURCE_ID);
addUrgencyLayer();

// Update source when positionSource changes
watch(
  () => props.positionSource,
  () => {
    MapboxHelper.updateSource(props.map, URGENCY_SOURCE_ID, [props.positionSource]);
  },
  { deep: true, immediate: true },
);
</script>

<template>
  <div class="mapbox-urgency-layer"></div>
</template>
