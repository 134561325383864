<script setup lang="ts">
import { POSITION, useToast } from 'vue-toastification';
import ModalComment from '@/components/common/ModalComment.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { DriverCodeValidation, ColumnKey, getDatagrid } from '@/pages/DriverListPage/DriverList.conf';
import { Permission } from '@/auth';
import ModalImportResource from '@/components/common/ModalImportResource.vue';
import { drivers as driversAPI } from '@/api';
import cloneDeep from 'clone-deep';
import { computed, onMounted, ref } from 'vue';
import type { DataGrid } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import type { Driver } from '@/@types/driver';
import type { Group } from '@/@types/group';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const toast = useToast();
const { t } = useI18n();
const store = useStore();

enum ModalType {
  COMMENT = 'comment',
  ARCHIVE_DRIVER = 'archive_driver',
  IMPORT_DRIVER = 'import_driver',
}

const NEW_LINE_ID = 'new-line';

enum DropdownActions {
  NEW_DRIVER = 'newDriver',
  IMPORT_DRIVER = 'importDriver',
}

// In computed because of i18n translations...
const dropdownItems = computed(() => [
  { label: t('manually'), action: DropdownActions.NEW_DRIVER, iconClass: 'fas fa-pen' },
  {
    label: t('import'),
    action: DropdownActions.IMPORT_DRIVER,
    iconClass: 'fa fa-arrow-circle-up',
  },
]);

const datagrid = ref<DataGrid>(getDatagrid());
const inEditionId = ref<string>();
const loading = ref<boolean>(true);
const modalDriverInitialState = ref<Driver>();
const modalShown = ref<ModalType>();
const driversFormatted = ref<Array<Driver>>([]);
const driverInEditionOriginalState = ref<Driver>();
const driverCodeList = ref<Array<string>>([]);
const renderedDataLength = ref<number>();

const group = computed<Group>(() => store.getters.group);
const driverList = computed<Array<Driver>>(() => Object.values(store.state.drivers.list));
const lineInEdition = computed<boolean>(() => inEditionId.value != null);

const buildCellInjectors = computed<{
  [key in ColumnKey]?: (apiData: { apiData: Driver }) => {
    editMode?: boolean;
    valueChanged?: (params: { value: any; field: keyof Driver }) => void;
    showModal?: () => void;
  };
}>(() => {
  const bindActionModal = (apiDataRow: Driver, name: ModalType) => (): void => {
    showModal(apiDataRow, name);
  };

  const bindValueChanged =
    (apiDataRow: Driver) =>
    ({ value, field }: { value: any; field: keyof Driver }): void => {
      updateValue(apiDataRow, value, field);
    };

  return {
    [ColumnKey.COMMENT]: ({ apiData }) => ({
      showModal: bindActionModal(apiData, ModalType.COMMENT),
    }),
    [ColumnKey.DRIVER_NAME]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
    [ColumnKey.CODE]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
    [ColumnKey.STAFF_NUMBER]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
    [ColumnKey.TEAMS]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
  };
});

onMounted(async () => {
  onCreate();
});

function addNewDriver() {
  if (!driversFormatted.value.find(v => v.id === NEW_LINE_ID)) {
    inEditionId.value = NEW_LINE_ID;
    driversFormatted.value.unshift({
      id: NEW_LINE_ID,
      comment: '',
      driver_name: '',
      staff_number: '',
      team_id: undefined,
    });
  }
}

/** Close the opened modal */
function closeModal() {
  modalShown.value = undefined;
  modalDriverInitialState.value = undefined;
}

/** Close the import modal and refresh data */
function closeModalImport() {
  closeModal();
  getDataList();
}

async function downloadData() {
  await driversAPI.download(group.value._id);
}

async function onCreate() {
  loading.value = true;
  await store.dispatch('drivers/loadList');
  getDataList();

  loading.value = false;
}

function resetLine(id: string) {
  const elementIndex = driversFormatted.value.findIndex(el => el.id === id);
  if (id === NEW_LINE_ID) {
    driversFormatted.value.splice(elementIndex, 1);
  } else {
    // give the element its original values back
    if (driverInEditionOriginalState.value)
      driversFormatted.value[elementIndex] = { ...driverInEditionOriginalState.value };
  }
}

/**
 * Edit driver
 */
async function saveEdits(apiDataRow: Driver) {
  if (apiDataRow.code === '') apiDataRow.code = undefined;
  if (apiDataRow.id === NEW_LINE_ID) {
    if (apiDataRow.driver_name === '') {
      apiDataRow.driver_name = t('newDriver');
    }
    const driverToSend = cloneDeep(apiDataRow);
    try {
      await store.dispatch('drivers/createDriver', driverToSend);
    } catch (e) {
      return;
    }
    await store.dispatch('drivers/loadList');
    getDataList();
  } else {
    // Update existing driver :
    const previousDriverCode = driverInEditionOriginalState.value?.code;
    const newDriverCode = apiDataRow.code || undefined;
    if (newDriverCode !== previousDriverCode && !validateDriverCode(newDriverCode)) return;
    const driver: Partial<Driver> = { ...apiDataRow, code: newDriverCode };
    delete driver.archived;
    await store.dispatch('drivers/updateDriver', {
      driverId: apiDataRow.id,
      driver,
    });
    getDataList();
  }
  inEditionId.value = undefined;
}

async function setDriverComment(comment: Driver['comment']) {
  if (modalDriverInitialState.value) {
    modalDriverInitialState.value.comment = comment;
    const apiDataRow = driversFormatted.value.find(
      element => element.id === modalDriverInitialState.value!.id,
    );
    if (apiDataRow) updateValue(apiDataRow, comment, 'comment');
    // the comment is not saved immediately in case of a new driver or edition mode
    if (
      modalDriverInitialState.value.id !== NEW_LINE_ID &&
      modalDriverInitialState.value.id !== inEditionId.value
    ) {
      const driver: Partial<Driver> = { ...modalDriverInitialState.value };
      delete driver.id;
      delete driver.archived;
      await store.dispatch('drivers/updateDriver', {
        driverId: modalDriverInitialState.value!.id,
        driver,
      });
    }
    closeModal();
  }
}

/**
 * Show a modal
 */
function showModal(apiDataRow: Driver | undefined, modalName: ModalType) {
  modalDriverInitialState.value = apiDataRow ? { ...apiDataRow } : undefined;
  modalShown.value = modalName;
}

/**
 * Delete driver
 */
async function submitModalRemove() {
  await store.dispatch('drivers/deleteDriver', modalDriverInitialState.value!.id);
  getDataList();
  closeModal();
}

/**
 * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
 */
function toggleEditionMode(apiDataRow: Driver, editionMode: boolean) {
  if (editionMode) {
    // if another edition was in progress, cancel it
    if (inEditionId.value) {
      resetLine(inEditionId.value);
    }
    inEditionId.value = apiDataRow.id;
    // keep a copy of the element about to be edited in case of edit cancellation
    driverInEditionOriginalState.value = { ...apiDataRow };
  } else {
    resetLine(apiDataRow.id);
    inEditionId.value = undefined;
    driverInEditionOriginalState.value = undefined;
  }
}

/**
 * Update a value - triggered upon focus lost on an EditableCell
 */
function updateValue(apiDataRow: Driver, value: any, field: keyof Driver) {
  // @ts-ignore
  apiDataRow[field] = value;
}

function getDataList() {
  const data = driverList.value
    .map(driver => {
      const newObject = driver;
      newObject.team_id =
        driver.team_id && !['', 'None'].includes(driver.team_id) ? driver.team_id : undefined;
      newObject.comment = driver.comment !== 'None' ? driver.comment : undefined;
      return newObject;
    })
    .sort((a, b) => {
      if (a.driver_name.toLowerCase() > b.driver_name.toLowerCase()) return 1;
      if (a.driver_name.toLowerCase() < b.driver_name.toLowerCase()) return -1;
      return 0;
    });
  const driversCodes = data.map(driver => driver.code).filter(code => code != null);
  driverCodeList.value = driversCodes;
  driversFormatted.value = data;
}

function validateDriverCode(driverCode: Driver['code']): boolean {
  if (!DriverCodeValidation.IS_FOUR_DIGIT_OR_EMPTY(driverCode)) {
    const toastId = toast.error(t('validation.fourDigitCode'), { position: POSITION.BOTTOM_RIGHT });
    setTimeout(() => toast.dismiss(toastId), 5000);
    return false;
  }
  return true;
}

function handleDropdownSelect(action: DropdownActions) {
  if (action === DropdownActions.NEW_DRIVER) {
    addNewDriver();
  } else if (action === DropdownActions.IMPORT_DRIVER) {
    showModal(undefined, ModalType.IMPORT_DRIVER);
  }
}
</script>

<template>
  <div class="drivers">
    <div class="drivers__header">
      <div class="drivers__header-side">
        <Btn
          v-if="store.getters.hasPermission(Permission.EXPORT_DRIVERS)"
          type="secondary"
          class="drivers__btn"
          @click="downloadData"
        >
          <font-awesome-icon icon="fa-download" />
          {{ $t('download') }}
        </Btn>

        <v-menu offset="8">
          <template #activator="{ props }">
            <Btn type="primary" class="dropdown-btn" :disabled="lineInEdition" v-bind="props">
              <i class="fas fa-plus" aria-hidden="true"></i>
              {{ $t('addDriver') }}
            </Btn>
          </template>
          <v-list class="dropdown-content topbar-dropdown__list">
            <v-list-item
              v-for="(item, index) in dropdownItems"
              :key="index"
              class="dropdown-item"
              @click="handleDropdownSelect(item.action)"
            >
              <template #prepend>
                <v-icon :class="item.iconClass" />
              </template>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('drivers', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="driversFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id)"
          :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id) ? [] : ['edit', 'archive']"
          :object="propsAction.object"
          @archive="dataLine => showModal(dataLine, ModalType.ARCHIVE_DRIVER)"
          @edit="dataLine => toggleEditionMode(dataLine, true)"
          @save="saveEdits"
          @switchOffEditionMode="dataLine => toggleEditionMode(dataLine, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalComment
      v-if="modalShown === ModalType.COMMENT && modalDriverInitialState"
      :comment="modalDriverInitialState.comment ? modalDriverInitialState.comment : undefined"
      :title-name="modalDriverInitialState.driver_name"
      @close="closeModal"
      @submit="setDriverComment"
    />

    <ModalImportResource
      v-if="modalShown === ModalType.IMPORT_DRIVER"
      resource-type="drivers"
      @close="closeModalImport"
    />

    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE_DRIVER && modalDriverInitialState"
      :title="$t('archiveDriver')"
      :body="$t('confirmArchiveDriver', [modalDriverInitialState.driver_name])"
      @close="closeModal"
      @submit="submitModalRemove"
    />
  </div>
</template>

<style lang="scss">
.drivers {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
  }

  &__header-side {
    display: flex;
  }

  .dropdown-btn i {
    margin-right: 8px;
  }

  .fa-download {
    margin-right: 8px;
  }
}
</style>

<i18n locale="fr">
{
  "archiveDriver": "Archiver le conducteur",
  "confirmArchiveDriver": "Êtes-vous sûr de vouloir archiver le conducteur \"{0}\" ?",
  "addDriver": "Ajouter un conducteur",
  "drivers": "conducteur | conducteurs",
  "validation": {
    "fourDigitCode": "Le code conducteur doit être composé de 4 chiffres"
  }
}
</i18n>

<i18n locale="en">
{
  "archiveDriver": "Archive the driver",
  "confirmArchiveDriver": "Do you want to archive the driver \"{0}\"?",
  "addDriver": "Add Driver",
  "drivers": "driver | drivers",
  "validation": {
    "fourDigitCode": "Driver code must be 4 digits long"
  }
}
</i18n>
