<template>
  <div class="datagrid-column-filter">
    <v-menu
      v-model="isMenuOpen"
      class="datagrid-column-filter__menu"
      :close-on-content-click="false"
      offset="8"
    >
      <template #activator="{ props }">
        <v-btn
          icon="fa:fas fa-filter"
          v-bind="props"
          variant="plain"
          :ripple="false"
          :disabled="disabled"
          :color="allChecked ? 'default' : 'success'"
        />
      </template>

      <v-list>
        <div class="filter-search">
          <font-awesome-icon icon="fa-search" class="filter-search__icon" />
          <input v-model="searchInput" class="filter-search__input" :placeholder="$t('search')" type="text" />
        </div>
        <v-divider></v-divider>

        <v-list-item min-height="20px">
          <div class="filter__actions">
            <a href="#select-all" @click.prevent="toggleAll">
              {{ $t(allChecked ? 'uncheckAll' : 'checkAll') }}
            </a>
          </div>
        </v-list-item>
        <v-divider></v-divider>
        <div class="checkbox-list">
          <v-list-item v-for="{ value, label } in values" :key="value" min-height="20px">
            <v-checkbox :id="value" v-model="states[value]" :ripple="false" color="success" hide-details>
              <template v-if="customFilter && customFilter.dataType == DataType.SEMANTIC_VERSION" #label>
                <div class="info-tag" :class="getSemanticVersion(value).statusClass">
                  {{ getSemanticVersion(value).version }}
                </div>
              </template>
              <template v-else #label>
                {{ i18nPrefix ? $t(`${i18nPrefix}.${value}`) : label }}
              </template>
            </v-checkbox>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { normalize, RE_ESCAPE } from '@/libs/helpers/strings';
import { DatagridLocalStorageHelper } from '@/libs/datagridLocalStorageHelper';
import { DataType } from '@/components/Table/DataGridVuetify/index.vue';
import { AppVersionStates } from '@/pages/DeviceListPage/cells/AppVersionCell.vue';

export default {
  name: 'DataGridColumnFilter',

  props: {
    /** @type {import('vue').Prop<import('@/components/Table/DataGridVuetify/models/DataGrid.models').ColumnFilterState>} */
    filters: {
      required: true,
      type: Object,
    },
    lsFiltersName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /** @type {import('vue').Prop<import('@/components/Table/DataGridVuetify/index.vue').CustomFilterData>} */
    customFilter: {
      type: Object,
      default: null,
    },
  },

  emits: ['toggle-all', 'update:isOpened'],

  data: () => ({
    searchInput: '',
    lsHelper: null,
    fetchedState: null,
    i18nPrefix: null,
    isMenuOpen: false,
    DataType,
  }),

  computed: {
    /** @return {import('@/components/Table/DataGridVuetify/models/DataGrid.models').ColumnFilterState} */
    copiedFilters() {
      const copiedFilters = this.filters;
      if (this.fetchedState) copiedFilters.state = this.fetchedState;
      return copiedFilters;
    },
    /** @return {boolean} */
    allChecked() {
      return Object.values(this.copiedFilters.state).every(checked => checked);
    },

    /** @return {{[key: string]: boolean}} */
    states() {
      return this.copiedFilters.state || {};
    },

    /** @return {Array<string>} */
    values() {
      const list = this.copiedFilters.list || [];
      let resultList = list;

      if (this.searchInput !== '') {
        const input = normalize(this.searchInput).replace(RE_ESCAPE, '\\$&');
        const re = new RegExp(input, 'i');

        if (this.customFilter?.dataType === DataType.SEMANTIC_VERSION) {
          const versionListMap = list.reduce((acc, { value }) => {
            const { version } = this.getSemanticVersion(value);
            acc[value] = version;
            return acc;
          }, {});
          resultList = list.filter(({ value }) => re.test(normalize(versionListMap[value])));
        } else {
          resultList = list.filter(({ label }) => re.test(normalize(label)));
        }
      }
      // if teams specific case, replace values :
      if (this.customFilter?.dataType === DataType.TEAMS) resultList = this.customFilter.data;

      // Perform sorting here based on the condition
      if (this.customFilter?.dataType === DataType.SEMANTIC_VERSION) {
        resultList.sort((a, b) =>
          b.value.localeCompare(a.value, undefined, { numeric: true, sensitivity: 'base' }),
        );
      } else if (this.customFilter?.dataType === DataType.ROLE) {
        const roleOrder = {
          admin: 1,
          operator: 2,
          reader: 3,
          external_reader: 4,
          driver: 5,
          technical: 6,
        };
        resultList.sort((a, b) => roleOrder[a.value] - roleOrder[b.value]);
      } else {
        resultList.sort((a, b) => a.label.localeCompare(b.label));
      }
      return resultList;
    },
  },

  watch: {
    allChecked: {
      deep: true,
      handler() {
        this.copiedFilters.isActive = !this.allChecked;
      },
    },
    states: {
      deep: true,
      handler() {
        if (Object.keys(this.states).length !== 0) {
          if (this.allChecked) this.lsHelper.removeFilter();
          else this.lsHelper.addOrUpdateFilter(this.states);
        }
      },
    },
    'copiedFilters.list': {
      handler() {
        this.initLsHelperAndSetFetchedState();
      },
    },
    isMenuOpen: {
      handler() {
        this.$emit('update:isOpened', this.isMenuOpen);
      },
    },
  },
  created() {
    this.i18nPrefix = this.filters.i18nPrefix;
    this.initLsHelperAndSetFetchedState();
  },

  methods: {
    toggleAll() {
      this.$emit('toggle-all', [!this.allChecked]);
    },
    initLsHelperAndSetFetchedState() {
      const filter = {};
      filter[this.copiedFilters.columnName] = this.copiedFilters.state;
      this.lsHelper = new DatagridLocalStorageHelper(
        this.filters.groupId,
        this.lsFiltersName,
        this.filters.columnName,
        filter,
      );
      this.fetchedState = this.lsHelper.getLocalFilter();
    },

    /**
     * @param {string} value
     * @return {{version: string, statusClass: string}}
     */
    getSemanticVersion(value) {
      const versionObject = this.customFilter?.data?.find(v => {
        return v.sortableAppVersion === value;
      });
      if (!versionObject) {
        return { version: value, statusClass: '' };
      }
      const { version, versionStatus: status } = versionObject;
      let statusClass;
      switch (status) {
        case AppVersionStates.UP_TO_DATE:
          statusClass = 'info-tag--success';
          break;
        case AppVersionStates.TO_UPDATE:
          statusClass = 'info-tag--warning';
          break;
        case AppVersionStates.DEPRECATED:
          statusClass = 'info-tag--danger';
          break;
        default:
          statusClass = 'info-tag--neutral';
      }
      return { version, statusClass };
    },
  },
};
</script>

<style lang="scss">
.datagrid-column-filter {
  overflow: hidden;

  // The width of the filter icon is set to 0 when it is not active or hovered
  width: 0;
  margin-left: auto;

  &--active {
    width: auto;
  }

  .v-btn {
    &--icon.v-btn--density-default {
      width: 12px;
    }

    &--variant-plain {
      opacity: 1;
      cursor: pointer;

      .v-icon {
        font-size: 10px;
      }
    }
  }

  &__menu {
    .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
      padding-inline: 8px;
    }

    .v-list {
      padding: 4px 0;
    }

    .filter-search {
      position: relative;
      display: flex;
      align-items: center;

      &__icon {
        position: absolute;
        padding-left: 7px;
      }

      &__input {
        width: 100%;
        min-width: 15em;
        height: 30px;
        padding: 4px 10px 4px 25px;
        border: 1px solid $border;
        background-color: $background;
        color: inherit;
        font: inherit;
      }
    }

    .checkbox-list {
      overflow: auto;
      max-height: 300px;

      .info-tag {
        font-size: 10px;

        &--neutral {
          border: none;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
