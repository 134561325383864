<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';

import Btn from '@/components/ui/Btn.vue';

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

defineEmits(['update:modelValue', 'remove']);

const { errorMessage } = useField(computed(() => props.name));
</script>

<template>
  <div class="connector-field-hosts-item">
    <div class="form-group">
      <div class="connector-field-hosts-item__input-container">
        <input
          :value="modelValue"
          :name="name"
          class="form-group__input"
          :class="errorMessage ? 'form-group__input--invalid' : ''"
          @input="event => $emit('update:modelValue', event.target)"
        />
        <Btn
          smaller
          outlined
          type="secondary"
          class="connector-field-hosts-item__remove_row_button"
          @click="$emit('remove')"
        >
          <font-awesome-icon icon="fa-trash" />
        </Btn>
      </div>
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.connector-field-hosts-item {
  &__remove_row_button {
    width: 34px;
    margin-left: 5px;
  }

  &__input-container {
    display: flex;
  }
}
</style>

<i18n locale="fr">
{
  "label": "Adresses IP",
  "add_item": "Ajouter"
}

</i18n>

<i18n locale="en">
{
  "label": "IP addresses",
  "add_item": "Add"
}
</i18n>
