<script setup lang="ts">
import { POSITION, useToast } from 'vue-toastification';
import ModalComment from '@/components/common/ModalComment.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';
import { VehicleCapacityValidation, ColumnKey, getDatagrid } from '@/pages/VehicleListPage/VehicleList.conf';
import { Permission } from '@/auth';

import ModalImportResource from '@/components/common/ModalImportResource.vue';
import { useVehiclesStore, type Vehicle } from '@/store-pinia/vehicles';
import { vehicles as vehiclesAPI } from '@/api';
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import type { DataGrid } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import type { Group } from '@/@types/group';
import { useI18n } from 'vue-i18n';

const toast = useToast();
const vehiclesStore = useVehiclesStore();
const store = useStore();
const { t } = useI18n();

enum ModalType {
  COMMENT = 'comment',
  ARCHIVE_VEHICLE = 'archive_vehicle',
  IMPORT_VEHICLE = 'import_vehicle',
}

const NEW_LINE_ID = 'new-line';

enum DropdownActions {
  NEW_VEHICLE = 'newVehicle',
  IMPORT_VEHICLE = 'importVehicle',
}

// In computed because of i18n translations...
const dropdownItems = computed(() => [
  { label: t('manually'), action: DropdownActions.NEW_VEHICLE, iconClass: 'fas fa-pen' },
  {
    label: t('import'),
    action: DropdownActions.IMPORT_VEHICLE,
    iconClass: 'fa fa-arrow-circle-up',
  },
]);

const datagrid = ref<DataGrid>(getDatagrid());
const inEditionId = ref<string>();
const loading = ref<boolean>(true);
const modalVehicleInitialState = ref<Vehicle>();
const modalShown = ref<ModalType>();
const renderedDataLength = ref<number>();
const vehiclesFormatted = ref<Array<Vehicle>>([]);
const vehicleInEditionOriginalState = ref<Vehicle>();

const group = computed<Group>(() => store.getters.group);
const vehiclesList = computed<Array<Vehicle>>(() => Object.values(vehiclesStore.list));
const lineInEdition = computed<boolean>(() => inEditionId.value != null);

const buildCellInjectors = computed<{
  [key in ColumnKey]?: (apiData: { apiData: Vehicle }) => {
    editMode?: boolean;
    valueChanged?: (params: { value: any; field: keyof Vehicle }) => void;
    showModal?: () => void;
  };
}>(() => {
  const bindActionModal = (apiDataRow: Vehicle, name: ModalType) => (): void => {
    showModal(apiDataRow, name);
  };

  const bindValueChanged =
    (apiDataRow: Vehicle) =>
    ({ value, field }: { value: any; field: keyof Vehicle }): void => {
      updateValue(apiDataRow, value, field);
    };

  return {
    [ColumnKey.COMMENT]: ({ apiData }) => ({ showModal: bindActionModal(apiData, ModalType.COMMENT) }),
    [ColumnKey.FLEET_NUMBER]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
    [ColumnKey.LICENSE_PLATE]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
    [ColumnKey.TEAMS]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
    [ColumnKey.CAPACITY]: ({ apiData }) => ({
      editMode: apiData.id === inEditionId.value,
      valueChanged: bindValueChanged(apiData),
    }),
  };
});

onMounted(async () => {
  onCreate();
});

function addNewVehicle() {
  if (!vehiclesFormatted.value.find(v => v.id === NEW_LINE_ID)) {
    inEditionId.value = NEW_LINE_ID;
    vehiclesFormatted.value.unshift({
      id: NEW_LINE_ID,
      comment: '',
      fleet_number: '',
      license_plate: '',
      capacity: undefined,
      team_id: undefined,
      archived: false,
    });
  }
}

async function downloadData() {
  await vehiclesAPI.download(group.value._id);
}

/** Close the opened modal */
function closeModal() {
  modalShown.value = undefined;
  modalVehicleInitialState.value = undefined;
}

/** Close the import modal and refresh data */
function closeModalImport() {
  closeModal();
  getDataList();
}

async function onCreate() {
  loading.value = true;
  await vehiclesStore.loadList();
  getDataList();

  loading.value = false;
}

function resetLine(id: string) {
  const elementIndex = vehiclesFormatted.value.findIndex(el => el.id === id);
  if (id === NEW_LINE_ID) {
    vehiclesFormatted.value.splice(elementIndex, 1);
  } else {
    // give the element its original values back
    if (vehicleInEditionOriginalState.value)
      vehiclesFormatted.value[elementIndex] = { ...vehicleInEditionOriginalState.value };
  }
}

/**
 * Edit vehicle
 */
async function saveEdits(apiDataRow: Vehicle) {
  let valideCapacity = apiDataRow.capacity;
  if (apiDataRow.id === NEW_LINE_ID) {
    if (!validateCapacity(valideCapacity)) {
      if (apiDataRow.id === NEW_LINE_ID) {
        const index = vehiclesFormatted.value.findIndex(v => v.id === NEW_LINE_ID);
        if (index !== -1) {
          vehiclesFormatted.value.splice(index, 1);
        }
        inEditionId.value = undefined;
        return;
      }
    }
    if (apiDataRow.fleet_number === '') {
      apiDataRow.fleet_number = t('newVehicle');
    }
    await vehiclesStore.createVehicle(apiDataRow);
    await vehiclesStore.loadList();
    getDataList();
  } else {
    if (valideCapacity?.toString() === '') {
      valideCapacity = undefined;
    }
    if (!validateCapacity(valideCapacity)) return;
    const vehicle: Vehicle = { ...apiDataRow, capacity: valideCapacity };
    await vehiclesStore.updateVehicle(apiDataRow.id, vehicle);
  }
  inEditionId.value = undefined;
}

async function setVehicleComment(comment: Vehicle['comment']) {
  if (modalVehicleInitialState.value) {
    modalVehicleInitialState.value.comment = comment;
    const apiDataRow = vehiclesFormatted.value.find(
      element => element.id === modalVehicleInitialState.value!.id,
    );
    if (apiDataRow) updateValue(apiDataRow, comment, 'comment');
    // the comment is not saved immediately in case of a new vehicle or edition mode
    if (
      modalVehicleInitialState.value.id !== NEW_LINE_ID &&
      modalVehicleInitialState.value.id !== inEditionId.value
    ) {
      if (apiDataRow) await saveEdits(apiDataRow);
    }
    closeModal();
  }
}

function showModal(apiDataRow: Vehicle | undefined, modalName: ModalType) {
  modalVehicleInitialState.value = apiDataRow ? { ...apiDataRow } : undefined;
  modalShown.value = modalName;
}

/**
 * Delete vehicle
 */
async function submitModalRemove() {
  await vehiclesStore.deleteVehicle(modalVehicleInitialState.value!.id);
  getDataList();
  closeModal();
}

/**
 * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
 */
function toggleEditionMode(apiDataRow: Vehicle, editionMode: boolean) {
  if (editionMode) {
    // if another edition was in progress, cancel it
    if (inEditionId.value) {
      resetLine(inEditionId.value);
    }
    inEditionId.value = apiDataRow.id;
    // keep a copy of the element about to be edited in case of edit cancellation
    vehicleInEditionOriginalState.value = { ...apiDataRow };
  } else {
    resetLine(apiDataRow.id);
    inEditionId.value = undefined;
    vehicleInEditionOriginalState.value = undefined;
  }
}

/**
 * Update a value - triggered upon focus lost on an EditableCell
 */
function updateValue(apiDataRow: Vehicle, value: any, field: keyof Vehicle) {
  // @ts-ignore
  apiDataRow[field] = value;
}

function getDataList() {
  const data = vehiclesList.value
    .map(vehicle => {
      const newObject = vehicle;
      // handle data from V2 version
      newObject.team_id =
        vehicle.team_id && !['', 'None'].includes(vehicle.team_id) ? vehicle.team_id : undefined;
      newObject.comment = vehicle.comment !== 'None' ? vehicle.comment : undefined;
      newObject.capacity = Number(vehicle.capacity) !== 0 ? Number(vehicle.capacity) : undefined;
      return newObject;
    })
    .sort((a, b) => {
      if (a.fleet_number.toLowerCase() > b.fleet_number.toLowerCase()) return 1;
      if (a.fleet_number.toLowerCase() < b.fleet_number.toLowerCase()) return -1;
      return 0;
    });
  vehiclesFormatted.value = data;
}

function validateCapacity(capacity: Vehicle['capacity']): boolean {
  if (!VehicleCapacityValidation.IS_VALID_NUMBER_RANGE(capacity)) {
    const toastId = toast.error(t('validation.capacityFormat'), { position: POSITION.BOTTOM_RIGHT });
    setTimeout(() => toast.dismiss(toastId), 5000);
    return false;
  }
  return true;
}

function handleDropdownSelect(action: DropdownActions) {
  if (action === DropdownActions.NEW_VEHICLE) addNewVehicle();
  else if (action === DropdownActions.IMPORT_VEHICLE) showModal(undefined, ModalType.IMPORT_VEHICLE);
}
</script>

<template>
  <div class="vehicles">
    <div class="vehicles__header">
      <div class="vehicles__header-side">
        <Btn
          v-if="store.getters.hasPermission(Permission.EXPORT_VEHICLES)"
          type="secondary"
          class="vehicles__btn"
          @click="downloadData"
        >
          <font-awesome-icon icon="fa-download" />
          {{ $t('download') }}
        </Btn>

        <v-menu offset="8">
          <template #activator="{ props }">
            <Btn type="primary" class="dropdown-btn" :disabled="lineInEdition" v-bind="props">
              <i class="fas fa-plus dropdown-btn__icon" aria-hidden="true"></i>
              {{ $t('addVehicle') }}
            </Btn>
          </template>
          <v-list class="dropdown-content topbar-dropdown__list">
            <v-list-item
              v-for="(item, index) in dropdownItems"
              :key="index"
              class="dropdown-item"
              @click="handleDropdownSelect(item.action)"
            >
              <template #prepend>
                <v-icon :class="item.iconClass" />
              </template>
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('vehicles', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="vehiclesFormatted"
      :datagrid="datagrid"
      :loading="loading"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id)"
          :actions="[NEW_LINE_ID, inEditionId].includes(propsAction.object.id) ? [] : ['edit', 'archive']"
          :object="propsAction.object"
          @archive="dataLine => showModal(dataLine, ModalType.ARCHIVE_VEHICLE)"
          @edit="dataLine => toggleEditionMode(dataLine, true)"
          @save="saveEdits"
          @switchOffEditionMode="dataLine => toggleEditionMode(dataLine, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalComment
      v-if="modalShown === ModalType.COMMENT && modalVehicleInitialState"
      :comment="modalVehicleInitialState.comment ? modalVehicleInitialState.comment : undefined"
      :title-name="modalVehicleInitialState.fleet_number"
      @close="closeModal"
      @submit="setVehicleComment"
    />

    <ModalImportResource
      v-if="modalShown === ModalType.IMPORT_VEHICLE"
      resource-type="vehicles"
      @close="closeModalImport"
    />
    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE_VEHICLE"
      :body="$t('confirmArchiveVehicle')"
      :title="$t('archiveVehicle')"
      @close="closeModal"
      @submit="submitModalRemove"
    />
  </div>
</template>

<style lang="scss">
.vehicles {
  padding: $view-standard-padding;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 12px;
  }

  &__header-side {
    display: flex;
  }

  .dropdown-btn i {
    margin-right: 8px;
  }

  .fa-download {
    margin-right: 8px;
  }
}
</style>

<i18n locale="fr">
{
  "addVehicle": "Ajouter un véhicule",
  "archiveVehicle": "Archiver le véhicule",
  "confirmArchiveVehicle": "Êtes-vous sûr de vouloir archiver ce véhicule ?",
  "vehicles": "véhicule | véhicules",
  "validation": {
    "capacityFormat": "La capacité du véhicule doit être comprise entre 0 et 999."
  }
}
</i18n>

<i18n locale="en">
{
  "addVehicle": "Add Vehicle",
  "archiveVehicle": "Archive the vehicle",
  "confirmArchiveVehicle": "Do you want to archive the vehicle?",
  "vehicles": "vehicle | vehicles",
  "validation": {
    "capacityFormat": "Vehicle capacity must be between 0 and 999."
  }
}
</i18n>
