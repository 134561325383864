<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';

import ToggleSwitch from '@/components/ui/ToggleSwitch.vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const { value } = useField(computed(() => props.name));
</script>

<template>
  <div class="connector-field-checkbox">
    <div class="form-group">
      <ToggleSwitch
        :id="name"
        :checked="value as boolean"
        class="connector-field-checkbox__toggle-switch"
        :name="name"
        @change="check => (value = check)"
      />
      <label class="form-group__label" :for="name">{{ label }}</label>
    </div>
  </div>
</template>

<style lang="scss">
.connector-field-checkbox__toggle-switch {
  margin: 8px;
}
</style>
