<script setup lang="ts">
import type { Stop, Trip } from '@/@types/gtfs';
import api, { UpdateType } from '@/api';
import Btn from '@/components/ui/Btn.vue';
import Tag from '@/components/ui/Tag.vue';
import { TagTypes } from '@/libs/tags';
import { FstType, type TripUpdates } from '@/store-pinia/trip-updates';
import { computed, onBeforeMount, ref, watch, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  hasChildren: {
    default: true,
    type: Boolean,
  },
  hasEditPermission: {
    default: true,
    type: Boolean,
  },
  tripId: {
    type: String,
    default: null,
  },
  updates: {
    type: Object as PropType<TripUpdates>,
    default: null,
  },
});

const emit = defineEmits(['redirectToModificationPage']);

const skippedStopNames = ref<string[]>([]);
const stops = ref<Stop[]>([]);

const skippedStopsSequences = computed<number[]>(() => {
  return props.updates.skipped_stop_time_seqs;
});

const groupId = computed<string>(() => {
  return store.getters.group._id;
});

const gtfsId = computed<string>(() => {
  return store.getters.group.current_file;
});

const includesCancellation = computed<boolean>(() => {
  return props.updates.is_canceled;
});

onBeforeMount(async () => {
  stops.value = Object.values(await store.dispatch('gtfs/getStopsMap', { gtfsId: gtfsId.value }));
});

const tooltip = computed<string>(() => {
  let text = '';
  // Cancellation
  if (props.updates.is_canceled) text = text.concat('\n', `${t('canceled')}`);

  // Delay
  if (props.updates.delay) text = text.concat('\n', `${t('delayed', [props.updates.delay / 60])}`);

  // Skipped stops
  if (props.updates.skipped_stop_time_seqs) {
    skippedStopNames.value.forEach(stopName => {
      text = text.concat('\n', `${t('noService', [stopName])}`);
    });
  }

  // Displaced stops
  const displacedStops = props.updates.temporary_stops?.filter(ts => ts.type === FstType.SHIFT);
  if (displacedStops) {
    displacedStops.forEach(ts => {
      text = text.concat('\n', `${t('displacedStop', [ts.stop_name])}`);
    });
  }

  // New temporary stops
  const temporaryStops = props.updates.temporary_stops?.filter(ts => ts.type !== FstType.SHIFT);
  if (temporaryStops) text = text.concat('\n', `${t('temporaryStop', { count: temporaryStops.length })}`);

  // Stop delays
  if (props.updates.delays)
    text = text.concat('\n', `${t('stopDelays', { count: props.updates.delays.length })}`);

  // Shape modification
  if (props.updates.shape) text = text.concat('\n', `${t('modifiedShape')}`);

  return text;
});

const updatesCount = computed<number>(() => {
  const updates = { ...props.updates };
  [UpdateType.COMMENT, UpdateType.STOP_INFO].forEach(key => {
    // remove comment and stop info from the updates
    if (updates[key as keyof TripUpdates]) delete updates[key as keyof TripUpdates];
  });
  return Object.entries(updates).reduce((acc, [key, value]) => {
    if (!value) return acc;
    // if value is array but specific case (shape, delays), only increment by 1
    if (['shape', 'delays'].includes(key)) return acc + 1;

    return Array.isArray(value) ? acc + value.length : acc + 1; // count each entry when value is an array
  }, 0);
});

watch(
  skippedStopsSequences,
  () => {
    if (stops.value.length > 0 && skippedStopsSequences.value?.length > 0) {
      getSkippedStopsNames();
    }
  },
  { immediate: true },
);

async function getTrip(tripId: string): Promise<Trip> {
  const trip = await api.trips.getTripFromGtfs(groupId.value, gtfsId.value, tripId);
  return trip;
}

async function getSkippedStopsNames() {
  const stopsName: string[] = [];
  const trip = await getTrip(props.tripId);
  // get stop_id for each skipped stop sequence
  const skippedStopIds = trip.stop_times
    .filter(stopTime => skippedStopsSequences.value.includes(stopTime.stop_sequence))
    .map(stopTime => stopTime.stop_id);

  // get stops names from skipped stops id
  skippedStopIds.forEach(stopId => {
    const stop = stops.value.find(stop => stop.stop_id === stopId);
    if (stop) {
      stopsName.push(stop.stop_name);
    }
  });
  skippedStopNames.value = stopsName;
}

function redirectToModificationPage() {
  if (props.hasEditPermission) {
    emit('redirectToModificationPage');
  }
}
</script>

<template>
  <div class="cell-trip-modification">
    <div v-if="updatesCount > 0">
      <Tag
        :class="{ cursor: hasEditPermission }"
        :text="$t('tripModification', { count: updatesCount })"
        :title="tooltip"
        :color="includesCancellation ? TagTypes.DANGER.color : TagTypes.WARNING.color"
        :background="
          includesCancellation ? TagTypes.DANGER.backgroundColor : TagTypes.WARNING.backgroundColor
        "
        @click="redirectToModificationPage"
      />
    </div>
    <Btn
      v-else
      :disabled="hasChildren || !hasEditPermission"
      type="icon-only"
      @click="redirectToModificationPage"
    >
      <font-awesome-icon icon="fa-plus" />
    </Btn>
  </div>
</template>

<style lang="scss">
.cell-trip-modification {
  .cursor {
    cursor: pointer;
  }
}
</style>

<i18n locale="fr">
{
  "canceled": "Course annulée",
  "advanced": "Avance de {0} min",
  "delayed": "Retard de {0} min",
  "noService": "{0} : ne pas desservir",
  "displacedStop": "{0} : déplacé",
  "temporaryStop": "{count} arrêt temporaire | {count} arrêts temporaires",
  "stopDelays": "{count} horaire de passage modifié | {count} horaires de passages modifiés",
  "modifiedShape": "Modification de tracé",
  "tripModification": "{count} modification de course | {count} modifications de course"
}
</i18n>

<i18n locale="en">
{
  "canceled": "Trip canceled",
  "advanced": "Advance of {0} min",
  "delayed": "Delay of {0} min",
  "noService": "{0}: do not serve",
  "displacedStop": "{0}: displaced",
  "temporaryStop": "{count} temporary stop | {count} temporary stops",
  "stopDelays": "{count} modified stop time | {count} modified stops times",
  "modifiedShape": "Shape modification",
  "tripModification": "{count} trip modification | {count} trip modifications"
}
</i18n>
