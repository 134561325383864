<script setup lang="ts">
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { onBeforeMount, onBeforeUnmount, ref, watch, type PropType } from 'vue';

const DEVICE_TRAIL_SOURCE_ID = 'deviceTrailSource';

const DEVICE_TRAIL_LAYER_ID = 'pointsLayer';

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },
  deviceTrailSource: {
    type: Object as PropType<GeoJSON.Feature<GeoJSON.LineString>>,
    required: true,
  },
});

const emit = defineEmits(['isLoaded']);

const loaded = ref<boolean>(false);

watch(
  () => props.deviceTrailSource,
  () => {
    MapboxHelper.updateSource(props.map, DEVICE_TRAIL_SOURCE_ID, [props.deviceTrailSource]);
  },
  { deep: true },
);

onBeforeMount(() => {
  props.map.once('idle', () => {
    loaded.value = true;
  });
  initSourceAndLayer();
});

onBeforeUnmount(() => {
  MapboxHelper.cleanLayersAndSources(props.map, [DEVICE_TRAIL_LAYER_ID], [DEVICE_TRAIL_SOURCE_ID]);
});

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, DEVICE_TRAIL_SOURCE_ID);
  addTrailLayer();

  emit('isLoaded', true);
}

function addTrailLayer() {
  MapboxHelper.addLayer(props.map, {
    id: DEVICE_TRAIL_LAYER_ID,
    type: 'line',
    source: DEVICE_TRAIL_SOURCE_ID,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },

    paint: {
      'line-width': 4,
      'line-color': '#00b871', // $primary-light
    },
  });
}
</script>

<template>
  <div class="mapbox-history"></div>
</template>
