/**
 * Convert an array (rows) of array (columns) to a csv file.
 */
export function toCSV(data: Array<Array<string | null | undefined>>): string {
  // create content
  let csvContent = '';
  data.forEach(rowArray => {
    if (rowArray) {
      const row = rowArray.join(';');
      csvContent += `${row}\r\n`;
    }
  });

  // to blob
  const blob = new Blob(['\ufeff', csvContent]);

  // create url
  return URL.createObjectURL(blob);
}

/**
 * create a false element to trigger blob download with specified name
 */
export function triggerDownloadCSV(url: string, name: string) {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  a.click();
}
