<script setup lang="ts">
import type { Team } from '@/@types/group';
import { computed, type PropType } from 'vue';
import { useStore } from 'vuex';

interface TeamOption {
  color: string;
  title: string;
  value: string;
}

const store = useStore();

const props = defineProps({
  editMode: {
    default: false,
    type: Boolean,
  },

  classicUi: {
    default: false,
    type: Boolean,
  },

  selectedTeams: {
    default: () => [],
    type: Array as PropType<Array<string>>,
  },

  showAll: {
    default: false,
    type: Boolean,
  },

  classicInputDisplay: {
    default: false,
    type: Boolean,
  },

  noDisplayWrap: {
    default: false,
    type: Boolean,
  },
});
const emit = defineEmits(['valueChanged']);

const allTeamsSelected = computed<boolean>(
  () => allowedUserTeamSelected.value.length === teamsOptions.value.length,
);

// Create a list of editable teams for a user
const allowedUserTeamSelected = computed<Array<string>>(() => {
  const allowedUserTeamSelected: Array<string> = [];
  teamsOptions.value.forEach(team => {
    if (props.selectedTeams.includes(team.value)) allowedUserTeamSelected.push(team.value);
  });
  return allowedUserTeamSelected;
});

// Create a list of non editable teams for a user (can't be overriden)
const notAllowedUserTeamSelected = computed<Array<string>>(() => {
  return props.selectedTeams.filter(teamId => !teamsOptions.value.find(team => team.value === teamId));
});

// Recreate a list of team objects
const teamsOptions = computed<Array<TeamOption>>(() => {
  const teams: Array<Team> = props.showAll ? store.state.teams : store.getters.activeTeams;
  return teams
    .map(({ team_id, name, color }) => ({
      value: team_id,
      title: name ?? team_id,
      color: color ?? '#333333',
    }))
    .sort();
});

function select(teamIds: Array<string>) {
  // Merge teamIds selected from allowedUserTeamSelected which is the base for checkbox selection
  // add notAllowedUserTeamSelected to the list in order to keep values
  emit('valueChanged', {
    value: [...teamIds, ...notAllowedUserTeamSelected.value],
    field: 'teams',
  });
}

function toggle(teamId: string) {
  if (allowedUserTeamSelected.value.includes(teamId)) {
    select(allowedUserTeamSelected.value.filter(t => t !== teamId));
    return;
  }
  select([...allowedUserTeamSelected.value, teamId]);
}

function toggleAll() {
  const value = allTeamsSelected.value ? [] : teamsOptions.value.map(t => t.value);
  select(value);
}

function removeTeam(teamId: string) {
  select(allowedUserTeamSelected.value.filter(t => t !== teamId));
}
</script>

<template>
  <div class="multiple-team-selector" :class="{ 'classic-input-display': classicInputDisplay }">
    <v-select
      id="selected-teams"
      :model-value="allowedUserTeamSelected"
      :placeholder="$t('noTeam')"
      multiple
      tile
      :disabled="!editMode"
      :items="teamsOptions"
      :rounded="classicInputDisplay ? '4' : '0'"
      variant="outlined"
      hide-details
      :class="{
        'multiple-team-selector__access-all-container': allTeamsSelected && !classicUi && !noDisplayWrap,
      }"
      menu-icon="fa:fas fa-chevron-down"
      density="comfortable"
    >
      <template #selection="{ item, index }">
        <div
          v-if="allTeamsSelected && index === 0 && !classicUi && !noDisplayWrap"
          class="multiple-team-selector__access-all"
        >
          <font-awesome-icon class="mr-2" icon="fa-crown" />
          {{ $t('accessAll') }}
        </div>
        <div
          v-else-if="allTeamsSelected && index === 0 && classicUi && !noDisplayWrap"
          class="multiple-team-selector__all-teams"
        >
          {{ $t('allTeams') }}
        </div>
        <span v-else-if="allTeamsSelected && !noDisplayWrap"></span>
        <v-chip v-else :color="item.raw.color" variant="elevated">
          <span>{{ item.title }}</span>
          <font-awesome-icon
            v-if="editMode"
            icon="fa-xmark"
            class="chip-icon"
            @click.stop.prevent.capture="removeTeam(item.value)"
          />
        </v-chip>
      </template>

      <template #prepend-item>
        <v-list-item>
          <v-checkbox
            :model-value="allTeamsSelected"
            :label="$t('selectAll')"
            :indeterminate="
              allowedUserTeamSelected.length !== teamsOptions.length && allowedUserTeamSelected.length > 0
            "
            color="success"
            hide-details
            @click="toggleAll"
          ></v-checkbox>
        </v-list-item>

        <v-divider />
      </template>

      <template #no-data>
        <div>
          {{ $t('noData') }}
        </div>
      </template>

      <template #item="{ props, item }">
        <v-list-item v-bind="props" title="" @click.stop="toggle(item.value)">
          <v-checkbox
            :label="item.title"
            :model-value="allTeamsSelected || allowedUserTeamSelected.includes(item.value)"
            color="success"
          />
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<style lang="scss">
.multiple-team-selector {
  background-color: $white;

  .v-input--disabled {
    .v-field {
      opacity: initial;
    }

    .v-field__append-inner {
      display: none;
    }
  }

  &__all-teams,
  .v-field__input input::placeholder {
    padding-left: 5px;
    font-size: 14px;
    white-space: nowrap;
  }

  .v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
    padding-inline: 10px;
  }

  &__access-all {
    display: inline-block;
    color: $primary-light;
    font-weight: $font-weight-semi-bold;
    font-size: $datagrid-font-size;
    white-space: nowrap;
    padding-inline: 8px;
  }

  &__access-all-container {
    .v-field {
      border: 2px solid $primary-light !important;
      background-color: $transparent-primary;

      i {
        color: $primary-light !important;
      }
    }
  }

  &.classic-input-display {
    .v-field__input {
      min-height: 34px;
    }
  }

  &:not(.classic-input-display) {
    .v-field__input {
      max-height: 47px;
      padding-top: 12px;

      & > input {
        padding-left: 8px;
      }

      .v-select__selection {
        margin-bottom: 10px;

        &:first-child {
          padding-left: 8px;
        }
      }
    }
  }
}

.v-overlay__content.v-select__content {
  padding-top: 2px;

  .v-label {
    font-size: $datagrid-font-size;
  }
}
</style>

<i18n locale="fr">
{
  "accessAll": "Accès à toutes les données",
  "allTeams": "Toutes les équipes",
  "selectAll": "Tout sélectionner",
}
</i18n>

<i18n locale="en">
{
  "accessAll": "Access all data",
  "allTeams": "All teams",
  "selectAll": "Select all",
}
</i18n>
