<script setup lang="ts">
import { computed, ref, watch, type PropType } from 'vue';
import { useStore } from 'vuex';

import { LngLatBounds } from 'mapbox-gl';

import MapboxHistoryLayer from '@/components/map/MapboxHistoryLayer.vue';
import MapboxMap from '@/components/map/MapboxMap.vue';
import VehicleTrackSwitch from '@/pages/TripDetailedPage/VehicleTrackSwitch.vue';

import type { MapDevice, MapStop, MapTrip } from '@/@types/mapbox';
import type { Device, DeviceEvent } from '@/@types/device';

import MapboxDevices from './MapboxDevices.vue';

const store = useStore();

export interface HistoryMapDevice {
  events: DeviceEvent[];
  id: string;
}

const props = defineProps({
  bounds: {
    type: LngLatBounds,
    default: null,
  },

  center: {
    type: Object as PropType<[number, number]>,
    default: null,
  },

  device: {
    type: Object as PropType<HistoryMapDevice>,
    default: null,
  },

  showTrackDisplaySwitch: {
    type: Boolean,
    default: false,
  },

  stops: {
    type: Array as PropType<MapStop[]>,
    required: true,
  },

  trip: {
    type: Object as PropType<MapTrip>,
    default: null,
  },

  tripUpdateMode: {
    type: Boolean,
    default: false,
  },

  ts: {
    type: Number,
    required: true,
  },

  isTripCanceled: {
    type: Boolean,
    default: false,
  },

  dropdownStyleOptionOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'displayWholeVehicleTrack',
  'mouseenter:stop',
  'mouseleave:stop',
  'mouseenter:trip',
  'mouseleave:trip',
  'update:bounds',
  'click:stop',
  'load',
]);

const mapInstance = ref<mapboxgl.Map | null>(null);
const isHistoryLayerLoaded = ref<Boolean>(false);
const displayWholeVehicleTrack = ref<Boolean>(false);
const mapLoaded = ref<Boolean>(false);

const deviceEventIndex = computed<number | null>(() => {
  if (!props.device) return null;

  const index = props.device.events.findIndex(e => e.latlng && e.ts >= props.ts);

  return index !== -1 ? index : null;
});

const deviceTrailSource = computed<Readonly<GeoJSON.Feature<GeoJSON.LineString>>>(() => {
  const coordinates = [];
  const secondsToShow = displayWholeVehicleTrack.value ? Infinity : 180;

  if (props.device && props.device.events && deviceEventIndex.value) {
    let index = deviceEventIndex.value;

    while (index >= 0 && props.device.events[index].ts > props.ts - secondsToShow) {
      const event = props.device.events[index];

      if (event.ts <= props.ts && event.latlng) {
        coordinates.push([event.latlng[1], event.latlng[0]]);
      }

      index -= 1;
    }
  }

  return {
    geometry: {
      type: 'LineString',
      coordinates,
    },
    type: 'Feature',
    properties: {},
  };
});

const gtfsId = computed<string>(() => {
  return store.getters['gtfs/getGtfsAt'](props.ts);
});

const mapDevice = computed<MapDevice | null>(() => {
  if (props.device && props.device.events && deviceEventIndex.value) {
    return {
      device: props.device.events[deviceEventIndex.value] as Device,
      id: props.device.id,
      highlight: false,
    };
  }
  return null;
});

const mapTrips = computed<MapTrip[]>(() => {
  if (props.trip) {
    return [props.trip];
  }
  return [];
});

watch(
  () => displayWholeVehicleTrack.value,
  () => {
    if (displayWholeVehicleTrack.value) {
      emit('displayWholeVehicleTrack', displayWholeVehicleTrack.value);
    }
  },
);

function onMapStopClick(event: mapboxgl.MapLayerMouseEvent) {
  const feature = event.features ? event.features[0] : null;
  if (feature) {
    emit('click:stop', feature.properties?.id);
  }
}

function onMapLoad({ map }: { map: mapboxgl.Map }) {
  map.once('idle', () => {
    mapInstance.value = map;
    mapLoaded.value = true;

    emit('load', { map });
  });
}
</script>

<template>
  <div class="history-map">
    <div class="c-map__layers">
      <VehicleTrackSwitch
        v-if="showTrackDisplaySwitch"
        v-model:display-track="displayWholeVehicleTrack"
        class="history-map__track-switch"
      />
    </div>
    <MapboxMap
      v-if="gtfsId"
      ref="mapboxMap"
      :bounds="
        (showTrackDisplaySwitch && displayWholeVehicleTrack) || !showTrackDisplaySwitch ? bounds : undefined
      "
      :center="center"
      :gtfs-id="gtfsId"
      :stops="stops"
      :trips="mapTrips"
      view-name="historyMap"
      :trip-update-mode="tripUpdateMode"
      :dropdown-style-option-only="dropdownStyleOptionOnly"
      display-tooltip
      @click:stop="onMapStopClick"
      @load="onMapLoad"
      @mouseenter:stop="$emit('mouseenter:stop', $event)"
      @mouseleave:stop="$emit('mouseleave:stop', $event)"
      @mouseenter:trip="$emit('mouseenter:trip', $event)"
      @mouseleave:trip="$emit('mouseleave:trip', $event)"
      @update:bounds="$emit('update:bounds', $event)"
    >
      <div v-if="tripUpdateMode && isTripCanceled" class="canceled-trip-map-layer">
        <span class="canceled-trip-map-layer__title">
          {{ $t('canceledTrip') }}
        </span>
      </div>

      <MapboxDevices
        v-if="isHistoryLayerLoaded && mapInstance"
        :devices="mapDevice ? [mapDevice] : []"
        :gtfs-id="gtfsId"
        :ts="ts"
        :map="mapInstance"
      />

      <MapboxHistoryLayer
        v-if="mapInstance"
        :device-trail-source="deviceTrailSource"
        :map="mapInstance"
        @isLoaded="isHistoryLayerLoaded = true"
      />
    </MapboxMap>
  </div>
</template>

<style lang="scss" scoped>
.history-map {
  &__dropdown {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: $map-dropdown;
  }

  &__track-switch {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: $map-dropdown;
  }
}
</style>
