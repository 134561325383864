<script setup lang="ts">
import type { Team } from '@/@types/group';
import { computed } from 'vue';
import { useStore } from 'vuex';

interface TeamOption {
  color: string;
  title: string;
  value: string;
}

const store = useStore();

const props = defineProps({
  teamIds: {
    type: Array<string>,
    required: true,
  },
});

const filteredTeamsOptions = computed<Array<TeamOption>>(() => {
  const teams: Array<Team> = store.getters.activeTeams;
  return teams
    .filter(team => props.teamIds?.includes(team.team_id))
    .map(({ team_id, name, color }) => ({
      value: team_id,
      title: name ?? team_id,
      color: color ?? '#333333',
    }))
    .sort();
});
</script>

<template>
  <div class="multiple-team-display">
    <template v-if="teamIds?.length === 0">
      {{ $t('visibleByAll') }}
    </template>
    <template v-else>
      <template v-for="teamId in filteredTeamsOptions" :key="teamId">
        <v-chip density="compact" :color="teamId.color" variant="elevated">
          {{ teamId.title }}
        </v-chip>
      </template>
    </template>
  </div>
</template>

<style lang="scss">
.multiple-team-display {
  display: inline-block !important;
  vertical-align: middle;
  white-space: nowrap;

  .v-chip {
    padding: 0 5px;
    border: none;
    border-radius: 3px;
    box-shadow: none;

    &:not(:last-child) {
      margin-right: 6px;
    }
  }
}
</style>

<i18n locale="fr">
{
    "visibleByAll": "Visible par tous",
}
</i18n>

<i18n locale="en">
{
    "visibleByAll": "Visible by all",
}
</i18n>
