<script setup lang="ts">
import { MapboxHelper } from '@/components/map/mapboxHelper';
import { onBeforeMount, onUnmounted, ref, watch, type PropType } from 'vue';

const POINTS_SOURCE_ID = 'pointsSource';
const RADIUS_SOURCE_ID = 'radiusSource';

const POINTS_LAYER_ID = 'pointsLayer';
const RADIUS_LAYER_ID = 'radiusLayer';

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
  },

  radiusSource: {
    type: Array as PropType<Array<GeoJSON.Feature<GeoJSON.Polygon>>>,
    default: null,
  },

  pointsSource: {
    type: Array as PropType<Array<GeoJSON.Feature<GeoJSON.Point>>>,
    default: null,
  },
});

const loaded = ref<boolean>(false);

watch(
  () => props.radiusSource,
  () => {
    MapboxHelper.updateSource(props.map, RADIUS_SOURCE_ID, props.radiusSource);
  },
  { deep: true },
);

watch(
  () => props.pointsSource,
  () => {
    MapboxHelper.updateSource(props.map, POINTS_SOURCE_ID, props.pointsSource);
  },
  { deep: true },
);

onBeforeMount(() => {
  props.map.once('idle', () => {
    loaded.value = true;
  });

  initSourceAndLayer();
});

onUnmounted(() => {
  MapboxHelper.cleanLayersAndSources(
    props.map,
    [POINTS_LAYER_ID, RADIUS_LAYER_ID],
    [POINTS_SOURCE_ID, RADIUS_SOURCE_ID],
  );
});

function initSourceAndLayer() {
  MapboxHelper.createEmptySource(props.map, POINTS_SOURCE_ID);
  MapboxHelper.createEmptySource(props.map, RADIUS_SOURCE_ID);
  addPointsLayer();
  addRadiusLayer();
}

function addPointsLayer() {
  MapboxHelper.addLayer(props.map, {
    id: POINTS_LAYER_ID,
    type: 'circle',
    source: POINTS_SOURCE_ID,
    paint: {
      'circle-radius': 6,
      'circle-color': '#05c0ef',
      'circle-stroke-width': 2,
      'circle-stroke-color': '#000000',
    },
  });
}

function addRadiusLayer() {
  MapboxHelper.addLayer(props.map, {
    id: RADIUS_LAYER_ID,
    type: 'fill',
    source: RADIUS_SOURCE_ID,
    paint: {
      'fill-color': '#ff0000',
      'fill-opacity': 0.35,
    },
  });
}
</script>

<template>
  <div class="mapbox-travel-time"></div>
</template>
