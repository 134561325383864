<template>
  <div class="cell-stop-info">
    <div v-if="stopInfo.length > 0">
      <Tag
        :class="{ cursor: hasEditPermission }"
        :text="$t('stopInfo', { count: stopInfo.length })"
        :title="tooltip"
        :color="TagTypes.SUCCESS.color"
        :background="TagTypes.SUCCESS.backgroundColor"
        @click="showModal"
      />
    </div>
    <Btn v-else type="icon-only" :disabled="hasChildren || !hasEditPermission" @click="showModal">
      <font-awesome-icon icon="fa-plus" />
    </Btn>
  </div>
</template>

<script>
import api from '@/api';
import Btn from '@/components/ui/Btn.vue';
import Tag from '@/components/ui/Tag.vue';
import { TagTypes } from '@/libs/tags';

export default {
  name: 'CellStopInfo',

  components: {
    Btn,
    Tag,
  },

  props: {
    hasChildren: {
      type: Boolean,
      default: false,
    },

    hasEditPermission: {
      default: true,
      type: Boolean,
    },

    /** @type {Vue.PropOptions<Array<import('@/api').StopInfo>>} */
    stopInfo: {
      type: Array,
      default: () => [],
    },

    tripId: {
      type: String,
      default: null,
    },
  },

  emits: ['showModal'],

  data() {
    return {
      TagTypes,

      /** @type {Array<{stopName: string, information: string}>} */
      formattedStopInfos: [],

      /** @type {?{[stopId: string]: import('@/store/gtfs').Stop}} */
      stops: null,

      /** @type {?import('@/store/gtfs').Trip} */
      trip: null,
    };
  },

  computed: {
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },

    /** @return {string} */
    gtfsId() {
      return this.$store.getters.group.current_file;
    },

    /** @return {string} */
    tooltip() {
      let tooltipContent = '';
      this.formattedStopInfos.forEach(info => {
        tooltipContent = tooltipContent.concat('\n', `${this.$t('info', [info.stopName, info.information])}`);
      });
      return tooltipContent;
    },
  },

  watch: {
    stopInfo: {
      async handler() {
        if (this.stopInfo?.length > 0) {
          this.getStopsName();
        }
      },
      immediate: true,
    },
  },

  async created() {
    this.stops = await this.$store.dispatch('gtfs/getStopsMap', { gtfsId: this.gtfsId });
  },

  methods: {
    /**
     * @param {string} tripId
     * @return {Promise<import('@/store/gtfs').Trip>}
     */
    async getTrip(tripId) {
      const trip = await api.trips.getTripFromGtfs(this.groupId, this.gtfsId, tripId);
      return trip;
    },

    async getStopsName() {
      this.formattedStopInfos = [];
      const trip = await this.getTrip(this.tripId);

      const stopInfoWithIds = this.stopInfo.map(stopInfo => {
        const stopId = trip.stop_times.find(
          stopTime => stopTime.stop_sequence === stopInfo.stop_sequence,
        ).stop_id;
        return { ...stopInfo, stopId };
      });

      // get stops names from stop_ids
      stopInfoWithIds.forEach(async stopInfo => {
        const stop = Object.values(this.stops).find(stop => stop.stop_id === stopInfo.stopId);
        if (stop) {
          this.formattedStopInfos.push({ stopName: stop.stop_name, information: stopInfo.information });
        }
      });
    },

    /** Show a modal */
    showModal() {
      this.$emit('showModal');
    },
  },
};
</script>

<style lang="scss">
.cell-stop-info {
  .cursor {
    cursor: pointer;
  }
}
</style>

<i18n locale="fr">
{
"info": "{0} : {1}",
 "stopInfo": "{count} information | {count} informations",
}
</i18n>

<i18n locale="en">
{
  "info": "{0}: {1}",
  "stopInfo": "{count} stop info",
}
</i18n>
