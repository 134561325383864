<template>
  <div class="planning">
    <div class="planning__header">
      <HeaderDatepicker v-model:value="selectedDate" class="planning__datepicker" />
      <Btn type="primary" class="planning__import-btn" @click="modalShown = true">
        <font-awesome-icon icon="fa-arrow-circle-up" />
        <span class="btn-text">{{ $t('import') }}</span>
      </Btn>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('bearing', { count: renderedDataLength })"
      :data="formattedPlanningEntries"
      :datagrid="datagrid"
      :loading="loading"
      :show-header="false"
      @noDataActionTriggered="modalShown = true"
    />

    <Modal v-if="modalShown" @close="modalShown = false">
      <template #title>
        {{ $t('importPlanning') }}
      </template>
      <template #body>
        <div class="form-group">
          <label class="form-group__label" for="informed_entity">
            {{ $t('upload') }}
          </label>

          <DragAndDrop accepted-format=".json,.zip" @uploadedFile="onFileSelected" />
        </div>
      </template>
      <template #cta>
        <Btn
          type="primary"
          class="ui-btn--fullwidth"
          :disabled="!importFile || sendingFile"
          @click="importPlanning"
        >
          {{ $t('import') }}
        </Btn>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { POSITION, useToast } from 'vue-toastification';
import api, { PlanningImportFormat } from '@/api';
import Modal from '@/components/layout/Modal.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatepicker from '@/components/ui/HeaderDatepicker.vue';
import DragAndDrop from '@/components/common/DragAndDrop.vue';
import { dateObjToGtfsFormat, getISODate } from '@/libs/helpers/dates';
import { GroupRoute } from '@/libs/routing';
import { useI18n } from 'vue-i18n';

import { getDatagrid } from './planning.conf';
import { useVehiclesStore } from '@/store-pinia/vehicles';

const { t } = useI18n();
const toast = useToast();

dayjs.extend(utc);

const router = useRouter();
const store = useStore();
const vehiclesStore = useVehiclesStore();

const props = defineProps({
  query: {
    type: Object,
    default: () => ({}),
  },
});

const datagrid = ref(getDatagrid());
const datePicker = ref(null);
const renderedDataLength = ref(null);

/** @type {import('vue').Ref<Array<FormattedPlanningEntry>>} */
const formattedPlanningEntries = ref([]);
/** @type {import('vue').Ref<Boolean>} */
const isCalendarOpen = ref(false);
/** @type {import('vue').Ref<string | File>} */
const importFile = ref();
/** @type {import('vue').Ref<PlanningImportFormat>} */
const importFormat = ref();
/** @type {import('vue').Ref<Boolean>} */
const loading = ref(false);
/** @type {import('vue').Ref<{[routeId: string]: import('@/store/gtfs').Route}>} */
const routes = ref({});
/** @type {import('vue').Ref<{[tripId: string]: import('@/store/gtfs').Trip}>} */
const trips = ref();
/** @type {import('vue').Ref<Boolean>} */
const modalShown = ref(false);
/** @type {import('vue').Ref<Array<import('@/api').PlanningEntry>>} */
const planningEntries = ref([]);
/** @type {import('vue').Ref<Boolean>} */
const sendingFile = ref(false);

const selectedDate = computed({
  /** @return {Date} */
  get() {
    if (props.query.date) {
      const date = dayjs(props.query.date).hour(23).minute(59).second(59).utc().toDate();
      return date;
    }
    return new Date();
  },

  /** @param {Date} value */
  set(value) {
    router.push({
      name: GroupRoute.PLANNING,
      params: {
        groupId: store.getters.group.group_id,
      },
      query: {
        date: getISODate(value),
      },
    });
  },
});

async function formatEntries() {
  const deactivatedRoutes = store.getters.group.deactivated_routes;
  // Format data
  let idIncrementor = 0;
  const result = await Promise.all(
    planningEntries.value.map(async entry => {
      idIncrementor += 1;
      const text = await store.dispatch('gtfs/formatTripName', {
        tripId: entry.trip_id,
        date: dayjs(entry.date).toDate(),
      });
      const link =
        text === t('tripNotFound')
          ? null
          : {
              name: GroupRoute.TRIP_DETAILED,
              params: { tripId: entry.trip_id },
              query: { date: props.query.date ?? null },
            };
      return {
        id: idIncrementor,
        driver: entry.driver_id
          ? (store.getters['drivers/getDriverInfosById'](entry.driver_id) ?? entry.driver_id)
          : '-',
        vehicle: entry.vehicle_id
          ? (vehiclesStore.getVehicleInfosById(entry.vehicle_id) ?? entry.vehicle_id)
          : '-',
        trip: {
          text,
          route: {
            ...routes.value?.[trips.value?.[entry.trip_id]?.route_id],
            isDeactivated: deactivatedRoutes.includes(trips.value?.[entry.trip_id]?.route_id),
          },
          link,
        },
      };
    }),
  );

  // Group by driver-vehicle
  const planningEntriesMap = {};
  formattedPlanningEntries.value = result.reduce((acc, entry) => {
    const groupTripKey = `${entry.driver}.${entry.vehicle}`;

    if (!planningEntriesMap[groupTripKey]) {
      planningEntriesMap[groupTripKey] = { ...entry };
      planningEntriesMap[groupTripKey].trips = [entry.trip];
      acc.push(planningEntriesMap[groupTripKey]);
    } else {
      planningEntriesMap[groupTripKey].trips.push(entry.trip);
    }
    return acc;
  }, []);
}

async function loadPlanning() {
  planningEntries.value = await api.planning.get(
    store.getters.group.group_id,
    dateObjToGtfsFormat(selectedDate.value),
  );
}

async function refreshPlanning() {
  loading.value = true;
  await loadPlanning();
  await formatEntries();
  loading.value = false;
}

async function importPlanning() {
  sendingFile.value = true;
  try {
    const result = await api.planning.post(
      store.getters.group.group_id,
      importFile.value,
      importFormat.value,
    );
    if (result.ok) {
      const toastId = toast.success(t('importSuccess'), {
        icon: 'fas fa-check',
        position: POSITION.BOTTOM_RIGHT,
      });
      setTimeout(() => toast.dismiss(toastId), 5000);
    }
  } catch (e) {
    if (e?.response?.status === 422) {
      const toastId = toast.error(t('apiErrors.unprocessableID'), {
        position: POSITION.BOTTOM_RIGHT,
      });
      setTimeout(() => toast.dismiss(toastId), 5000);
    }
  } finally {
    sendingFile.value = false;
    modalShown.value = false;
    await refreshPlanning();
  }
}

async function loadDrivers() {
  await store.dispatch('drivers/loadList');
}

/**
 * Load vehicles list for assigned values.
 */
async function loadVehicles() {
  await vehiclesStore.loadList();
}

async function getRoutes() {
  routes.value = await store.dispatch('gtfs/getRoutesMap');
}

async function getTrips() {
  trips.value = await store.dispatch('gtfs/getTripsMap', { ts: selectedDate.value.getTime() });
}

async function onCreate() {
  loading.value = true;
  await Promise.all([loadDrivers(), loadPlanning(), loadVehicles(), getRoutes(), getTrips()]);
  await formatEntries();
  trips.value = store.state.gtfs.tripsMap;
  loading.value = false;
}

/** @param {?File} e */
function onFileSelected(e) {
  if (e === undefined) {
    importFile.value = undefined;
    return;
  }

  switch (e.type) {
    case 'application/json': {
      importFormat.value = PlanningImportFormat.JSON;
      const reader = new FileReader();
      reader.onload = e => {
        // @ts-ignore
        importFile.value = e.target.result;
      };
      reader.readAsText(e);
      break;
    }

    case 'application/zip':
      importFormat.value = PlanningImportFormat.GESCAR;
      importFile.value = e;
      break;

    default:
    // Impossible: DragAndDrop component limits file type selection
  }
}

/**
 * This method is aimed to allow to close datepicker when clicking on datepicker cta, otherwise it always opens itself
 * on close/blur on this precise zone
 * @param {boolean} actionOpen
 */
function toggleDatepicker(actionOpen) {
  if (actionOpen) {
    isCalendarOpen.value = true;
    datePicker.value.openMenu();
  } else {
    isCalendarOpen.value = false;
  }
}

watch(selectedDate, async () => {
  await refreshPlanning();
});

onCreate();

/**
 * @typedef {Object} FormattedPlanningEntry
 * @property {string} id
 * @property {string} driver
 * @property {string} vehicle
 * @property {Array<import('@/components/common/DropList.vue').DropListValue>} trips
 */
</script>

<style lang="scss">
.planning {
  padding: $view-standard-padding;

  &__datepicker {
    margin-right: auto;
  }

  &__import-btn {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }
}
</style>

<i18n locale="fr">
{
  "bearing": "roulement | roulement | roulements",
  "importPlanning": "Importer un planning",
  "import": "Importer",
  "upload": "Téléverser un fichier",
}
</i18n>

<i18n locale="en">
{
  "bearing": "bearing | bearings",
  "importPlanning": "Import planning",
  "import": "Import",
  "upload": "Upload file",
}
</i18n>
