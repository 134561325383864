<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import SearchBox from '@/components/common/SearchBox.vue';

const store = useStore();

const isMobileMenuOpen = computed<boolean>(() => store.state.userInterface.isMobileMenuOpen);
const isSearchboxOpen = ref<boolean>(false);

function toggleMenu() {
  store.state.userInterface.isMobileMenuOpen = !store.state.userInterface.isMobileMenuOpen;
}
</script>

<template>
  <div class="header-mobile">
    <div class="header-mobile__brand">
      <img src="@/assets/img/pysae-logo.svg?url" alt="Logo" />
    </div>
    <div class="header-mobile__actions">
      <v-icon v-if="!isMobileMenuOpen && !isSearchboxOpen" @click="isSearchboxOpen = true">
        fa:fas fa-search
      </v-icon>

      <SearchBox v-show="isSearchboxOpen" @closeSearchbox="isSearchboxOpen = false" />

      <v-icon v-if="!isSearchboxOpen" @click="toggleMenu">
        {{ isMobileMenuOpen ? 'fa:fas fa-times' : 'fa:fas fa-bars' }}
      </v-icon>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header-mobile {
  display: flex;
  justify-content: space-between;
  min-height: $header-mobile;
  padding: 0 16px;

  &__brand {
    align-self: center;
    height: 32px;

    img {
      height: 32px;
    }
  }

  &__actions {
    @include small-screen {
      justify-content: flex-end;
      width: 100%;
    }

    display: flex;
    gap: 22px;

    .fas {
      align-self: center;
      font-size: 18px;
    }
  }
}
</style>
