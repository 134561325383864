<script setup lang="ts">
import { defineProps, type PropType } from 'vue';
import { type FormattedSubmenuItem } from './NavBarLeft.vue';
import { useRoute } from 'vue-router';

const activeRouteName = useRoute().name;

const props = defineProps({
  categoryLabel: {
    type: String,
    required: true,
  },
  categoryIcon: {
    type: String,
    required: true,
  },
  items: {
    type: Array as PropType<Array<FormattedSubmenuItem>>,
    required: true,
  },
  counter: {
    type: Number,
    default: 0,
  },
});
</script>

<template>
  <v-list class="mobile-submenu" density="compact">
    <v-list-group class="mobile-submenu__group">
      <template #activator="{ props: activatorProps }">
        <v-list-item
          v-bind="activatorProps"
          :title="categoryLabel"
          :prepend-icon="categoryIcon"
          class="mobile-submenu__activator"
          :active="items.some(item => item.route.name === activeRouteName)"
          active-class="mobile-submenu__activator--active"
        >
          <span v-if="counter > 0" class="mobile-submenu__counter">
            {{ counter }}
          </span>
        </v-list-item>
      </template>

      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :title="item.translation"
        class="mobile-submenu__item"
        active-class="mobile-submenu__item--active"
        density="compact"
        height="30"
        link
        :to="item.route"
      ></v-list-item>
    </v-list-group>
  </v-list>
</template>

<style lang="scss">
.mobile-submenu {
  padding: 0;

  .v-list-item-title {
    max-height: 36px;
    color: $text-dark-variant;
    font-weight: $font-weight-semi-bold;
    font-size: 12px;
  }

  &__activator {
    min-height: 36px !important;
    padding: 0 10px !important;
    border-radius: 8px !important;
    text-decoration: none;

    &:hover:not(.mobile-submenu__activator--active) {
      background-color: $transparent-dark-variant;
      color: $text-dark;
    }

    .v-list-item__spacer {
      display: none;
    }

    .v-list-item__append {
      .fa-chevron-down,
      .fa-chevron-up {
        color: $text-neutral;
        font-size: 14px;
      }
    }

    .fas {
      margin-right: 12px;
      color: $text-dark;
      font-size: 12px;
      opacity: 1;
    }

    &--active {
      background-color: $transparent-primary;
      color: $primary-light !important;
      text-decoration: none;

      .v-list-item-title {
        color: $primary-light !important;
      }

      .fas {
        color: $primary-light;
      }
    }
  }

  &__item.v-list-item {
    min-height: 36px;
    padding: 0 0 0 45px !important;
    border-radius: 8px !important;
    text-decoration: none;

    &:hover:not(.mobile-submenu__item--active) {
      background-color: $transparent-dark-variant;
      color: $text-dark;
      text-decoration: none;
    }

    &--active {
      color: $primary-light !important;

      .v-list-item-title {
        color: $primary-light !important;
      }
    }
  }

  &__counter {
    position: absolute;
    top: 15%;
    left: 15px;
    padding: 1px 3px;
    border-radius: 10px;
    background-color: $danger;
    color: $text-light;
    font-size: 0.6em;
    text-align: center;
  }
}
</style>
