<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  image: {
    type: String,
    required: true,
  },
});

const imageSource = computed(
  () => new URL(`/src/assets/img/dashboard/${props.image}.png`, import.meta.url).href,
);
</script>

<template>
  <div class="card-overlay">
    <img class="card-overlay__img" :src="imageSource" />
    <div class="card-overlay__text d-flex flex-column justify-center align-center">
      <div class="card-overlay__text-1">{{ $t('comingSoon') }}</div>
      <div class="card-overlay__text-2">{{ $t('publish') }}</div>
    </div>
  </div>
</template>

<style lang="scss">
.card-overlay {
  position: relative;
  width: inherit;
  height: inherit;
  color: $text-dark;
  text-align: center;

  &__img {
    object-fit: cover;
    width: 100%;
    opacity: 0.2;
    filter: blur(3px);
  }

  &__text {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 46px);
  }

  &__text-1 {
    font-weight: $font-weight-semi-bold;
    font-size: 16px;
  }

  &__text-2 {
    font-weight: 500;
    font-size: 14px;
  }
}
</style>

<i18n locale="fr">
  {
  "comingSoon": "Bientôt disponible !",
  "publish": "Veuillez publier un plan de transport",
  }
</i18n>

<i18n locale="en">
      {
  "comingSoon": "Coming soon!",
  "publish": "Please publish a transport plan",
  }
</i18n>
