import Geocoding from '@mapbox/mapbox-sdk/services/geocoding';
import type { Map } from 'mapbox-gl';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import type { Urgency } from '@/@types/api/urgency';
import type { Driver } from '@/@types/driver';
import { dateGtfsFormatToObj, DatetimeFormat, dateToFormattedString } from '@/libs/helpers/dates';
import { useVehiclesStore, type Vehicle } from '@/store-pinia/vehicles';

const mapboxGeocoder = Geocoding({
  accessToken: import.meta.env.VITE_MAPBOX_API_KEY,
});
const NO_DATA = '-';

export function useCurrentUrgencies(getUrgencies: () => Array<Urgency>) {
  const store = useStore();
  const vehiclesStore = useVehiclesStore();

  const activeUrgencyIndex = ref(0);
  const formattedTripName = ref('');
  const urgencyAddress = ref<string | undefined>('');
  const map = ref<Map>();
  const mapCenter = ref<[number, number]>();
  const mapLoaded = ref(false);

  const urgencies = computed(() => getUrgencies());

  watch(
    () => urgencies.value.length,
    () => {
      if (urgencies.value.length === 0) {
        activeUrgencyIndex.value = 0;
      } else if (activeUrgencyIndex.value >= urgencies.value.length) {
        activeUrgencyIndex.value = urgencies.value.length - 1;
      }
    },
  );

  const activeUrgency = computed<Urgency | undefined>(() =>
    urgencies.value.length > 0 ? urgencies.value[activeUrgencyIndex.value] : undefined,
  );

  const driverList = computed<Array<Driver>>(() => {
    return Object.values(store.state.drivers.list);
  });

  const vehicleList = computed<Array<Vehicle>>(() => {
    return Object.values(vehiclesStore.list);
  });

  const formattedUrgency = computed<FormattedUrgency | undefined>(() => {
    const urgency = activeUrgency.value;
    if (!urgency) return;

    const driver = driverList.value.find(driver => driver.id === urgency.driver_id);
    let formattedDriver;
    if (!driver) {
      formattedDriver = NO_DATA;
    } else {
      formattedDriver = driver?.staff_number
        ? `${driver?.driver_name} (${driver.staff_number || ''})`
        : driver.driver_name;
    }
    const vehicle = vehicleList.value.find(vehicle => vehicle.id === urgency.vehicle_id);
    const date = dateToFormattedString(urgency.device_time, {
      format: DatetimeFormat.DDMMYYYY,
      unix: true,
    });
    const time = dateToFormattedString(urgency.device_time, {
      format: DatetimeFormat.HHMMSS,
      unix: true,
    });
    const position = `N ${urgency.device_position?.latitude}° E ${urgency.device_position?.longitude}°`;

    return {
      driver: formattedDriver || NO_DATA,
      vehicle: vehicle?.license_plate || NO_DATA,
      device: urgency.device_id || NO_DATA,
      trip: formattedTripName.value || NO_DATA,
      datetime: date && time ? `${date} - ${time}` : NO_DATA,
      position: position || NO_DATA,
      address: urgencyAddress.value || NO_DATA,
    };
  });

  const positionSource = computed<GeoJSON.Feature<GeoJSON.Point> | undefined>(() => {
    if (!activeUrgency.value) return;

    const { longitude, latitude } = activeUrgency.value.device_position;
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude],
      },
      properties: {},
    };
  });

  // Update associated data for selected urgency:
  watch(
    activeUrgencyIndex,
    async () => {
      const urgency = activeUrgency.value;
      if (!urgency) return;

      if (urgency.trip) {
        const date = dateGtfsFormatToObj(urgency.trip.start_date);
        formattedTripName.value = await generateFormattedTripName(urgency.trip.id, date);
      } else {
        formattedTripName.value = '';
      }

      urgencyAddress.value = await getUrgencyAddress(urgency);
      mapCenter.value = [urgency.device_position.longitude, urgency.device_position.latitude];
    },
    { immediate: true },
  );

  async function generateFormattedTripName(tripId: string, date: Date | undefined): Promise<string> {
    return store.dispatch('gtfs/formatTripName', {
      tripId,
      date,
    });
  }

  async function getUrgencyAddress(urgency: Urgency): Promise<string | undefined> {
    const query: [number, number] = [urgency.device_position.longitude, urgency.device_position.latitude];
    const address = await mapboxGeocoder
      .reverseGeocode({ query, limit: 1 })
      .send()
      .then(response => {
        const { features } = response.body;
        if (features.length > 0) {
          return features[0].place_name;
        }
      });
    return address;
  }

  async function loadDrivers() {
    await store.dispatch('drivers/loadList');
  }

  async function loadVehicles() {
    await vehiclesStore.loadList();
  }

  function onMapLoad(event: { map: Map }) {
    map.value = event.map;
    mapLoaded.value = true;
  }

  // Init
  loadDrivers();
  loadVehicles();

  return {
    activeUrgency,
    activeUrgencyIndex,
    formattedUrgency,
    map,
    mapCenter,
    mapLoaded,
    positionSource,
    onMapLoad,
  };
}

export interface FormattedUrgency {
  driver: string;
  vehicle: string;
  device: string;
  trip: string;
  datetime: string;
  position: string;
  address: string;
}
