<script setup lang="ts">
import { computed, ref, useTemplateRef, type PropType } from 'vue';

import type { DonutData } from '@/components/ui/DonutChart.vue';
import DonutChart from '@/components/ui/DonutChart.vue';

import CardHeader from './CardHeader.vue';
import CardOverlay from './CardOverlay.vue';
import type { VCard } from 'vuetify/components';

const props = defineProps({
  data: {
    type: Array as PropType<DonutData[]>,
    required: true,
  },
  hasGtfs: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
});

const donutChart = useTemplateRef<VCard>('donutChart');
const donutChartLegend = ref<HTMLElement>();

// TODO : InstanceType may not be useful here, but it's not working without it for some reason in vue 3.5.13
type CardHeaderType = InstanceType<typeof CardHeader>;
const donutChartHeader = useTemplateRef<CardHeaderType>('donutChartHeader');

defineEmits(['redirect']);

const donutChartHeight = computed<number | null>(() => {
  if (!donutChart.value?.$el || !donutChartLegend.value || !donutChartHeader.value?.$el) return null;
  return (
    donutChart.value.$el.offsetHeight -
    donutChartLegend.value.offsetHeight -
    donutChartHeader.value.$el.offsetHeight
  );
});

const hasOnlineDevices = computed<boolean>(() => {
  if (props.data.find(element => element.value !== 0)) {
    return true;
  }
  return false;
});

function findPercentage(element: DonutData): string {
  const total = props.data.reduce((acc, el) => {
    const result = acc + el.value || 0;
    return result;
  }, 0);
  return total ? Math.floor((element.value / total) * 100) + '%' : '0%';
}

const highlightDataPointIndex = ref<number | null>();

function onChartHover(hoveredDataPointIndex: number) {
  highlightDataPointIndex.value = hoveredDataPointIndex;
}
</script>

<template>
  <v-card ref="donutChart" class="donut-chart-card" elevation="0">
    <CardHeader
      ref="donutChartHeader"
      :title="title"
      show-status-indicator
      :is-active="data.length > 0"
      @click="$emit('redirect')"
    />
    <v-skeleton-loader v-if="loading" type="image" height="inherit" />
    <CardOverlay v-else-if="!hasGtfs" image="donut-chart-placeholder" />
    <div v-else class="donut-chart-card__container d-flex flex-column">
      <div v-if="hasOnlineDevices" ref="donutChartLegend" class="d-flex ga-2 pa-3 flex-wrap">
        <div
          v-for="(element, index) in data"
          :key="index"
          disabled
          class="donut-chart-card__legend-element d-flex justify-space-between px-2 py-1"
          :class="{
            'donut-chart-card__non-highlighted-legend':
              highlightDataPointIndex !== null && index !== highlightDataPointIndex,
          }"
        >
          <div>
            <v-icon :style="{ color: element.color }" class="px-3">fa:fas fa-circle</v-icon>
            {{ element.name }}
          </div>

          <div>{{ findPercentage(element) }}</div>
        </div>
      </div>
      <DonutChart
        v-if="hasOnlineDevices && donutChartHeight"
        :height="donutChartHeight"
        :values="data"
        total-name="dashboard.total"
        @hover="onChartHover"
      />
      <div v-else class="donut-chart-card__empty-message">
        <div>{{ $t('dashboard.noOnlineDevice') }}</div>
      </div>
    </div>
  </v-card>
</template>

<style lang="scss">
.donut-chart-card {
  height: 100%;
  background-color: $canvas;

  &__container {
    height: calc(100% - 46px);
  }

  &__empty-message {
    margin: auto;
    color: $text-dark-variant;
    font-weight: $font-weight-semi-bold;
    text-align: center;
  }

  &__legend-element {
    min-width: 145px;
    padding: 3px;
    border: 1px solid $border;
    border-radius: 100px;
    color: $text-dark;
    font-weight: 500;
    font-size: 12px;

    .v-icon {
      font-size: 14px;
    }
  }

  &__non-highlighted-legend {
    opacity: 0.5;
  }
}
</style>
