<script setup lang="ts">
import type { Device } from '@/@types/device';
import type { Route, Stop, StopTime, Trip } from '@/@types/gtfs';
import { computed, nextTick, ref, watch } from 'vue';
import { useStore } from 'vuex';
import RouteBadge from '@/components/common/RouteBadge.vue';
import { DEVICE_DELAY_COLOR } from '@/components/map/map-const';
import { CurrentStatus } from '@/store/devices';
import cloneDeep from 'clone-deep';
import { useI18n } from 'vue-i18n';

interface RouteService {
  route: Route;
  devices: Device[];
  stopTimes: StopTime[];
  title: string;
  isLoop: boolean;
  duplicateNumber: number;
  isNewLoad: boolean;
}

// Map<routeId, Map<serviceId, RouteService>>
interface GroupedRouteServices extends Map<string, Map<string, RouteService>> {}

// Map<routeId:Array<deviceId>>
interface GroupedDevices extends Map<string, Array<string>> {}

const i18n = useI18n();
const store = useStore();

const viewBoxLoop = '-4 -4 273 20';
const viewBoxLine = '-4 -4 273 8';
const routeLoopGraph = 'M 6,0 L 260,0 A 6,6 0 0 1 260,12 L 6,12 A 6,6 0 0 1 6,0';
const routeLineGraph = 'M 0,0 L 266,0';

const props = defineProps({
  selectedDeviceId: {
    type: String,
    required: false,
    default: null,
  },
  filteredDevices: {
    type: Array<String>,
    required: true,
  },
});

const emit = defineEmits(['update:selectedDeviceId']);

const gtfsId = computed<string>(() => store.getters.group.current_file);

const gtfsRoutes = computed<{ [routeId: string]: Route }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'routes'),
);

const gtfsStops = computed<{ [stopId: string]: Stop }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'stops'),
);

const gtfsTrips = computed<{ [tripId: string]: Trip }>(() =>
  store.getters['gtfs/getCachedGtfsTable'](gtfsId.value, 'trips'),
);

const connectedDevices = computed<{ [deviceId: string]: Device }>(
  () => store.getters['devices/visibleDevices'],
);

const groupedRouteServices = ref<GroupedRouteServices>();
const groupedDevices = ref<GroupedDevices>();
const duplicateServiceName = ref<Array<String>>([]);

watch(
  () => props.selectedDeviceId,
  async () => {
    await nextTick(() => {
      // Scroll to route card
      if (!props.selectedDeviceId || !groupedDevices.value) return;
      let routeToDisplay: string | null = null;
      for (const [key, value] of groupedDevices.value.entries()) {
        if (value.includes(props.selectedDeviceId)) routeToDisplay = key;
      }
      if (!routeToDisplay) return;
      const element = document.getElementById(`route_${routeToDisplay}`);
      if (!element) return;
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    });
  },
);

watch([() => connectedDevices.value, () => props.filteredDevices], () => {
  const groupedServices: GroupedRouteServices = new Map();
  const devicesByTrip: GroupedDevices = new Map();
  const duplicateServiceTitleChecker: { [routeId: string]: Array<string> } = {};
  Object.values(connectedDevices.value).forEach(device => {
    if (!props.filteredDevices.includes(device.device_id)) return;
    const deviceTripId = device.trip_id || device.trip?.trip_id;
    if (deviceTripId) {
      const trip = gtfsTrips.value?.[deviceTripId];
      if (trip) {
        if (!groupedServices.get(trip.route_id)) groupedServices.set(trip.route_id, new Map());
        if (!devicesByTrip.get(trip.route_id)) devicesByTrip.set(trip.route_id, []);
        if (!groupedServices.get(trip.route_id)!.get(trip.shape_id)) {
          // Handle service title & logic for duplicated name
          const serviceTitle = getServiceTitle(trip);
          if (!duplicateServiceTitleChecker[trip.route_id]) duplicateServiceTitleChecker[trip.route_id] = [];
          else {
            if (
              duplicateServiceTitleChecker[trip.route_id].includes(serviceTitle) &&
              !duplicateServiceName.value.includes(serviceTitle)
            )
              duplicateServiceName.value.push(serviceTitle);
          }

          const firstStopId = gtfsStops.value[trip.stop_times[0].stop_id]?.stop_id;
          const lastStopId = gtfsStops.value[trip.stop_times[trip.stop_times.length - 1].stop_id]?.stop_id;

          const isLoop = firstStopId === lastStopId;
          const stopTimes = isLoop
            ? cloneDeep(trip.stop_times).slice(0, trip.stop_times.length - 1)
            : trip.stop_times;

          groupedServices.get(trip.route_id)!.set(trip.shape_id, {
            route: gtfsRoutes.value[trip.route_id],
            devices: [],
            stopTimes: stopTimes,
            title: serviceTitle,
            isLoop: isLoop,
            isNewLoad: groupedRouteServices.value?.get(trip.route_id)?.get(trip.shape_id) ? false : true,
            duplicateNumber: duplicateServiceTitleChecker[trip.route_id].filter(
              title => title === serviceTitle,
            ).length,
          });

          duplicateServiceTitleChecker[trip.route_id].push(serviceTitle);
        }
        // add device
        devicesByTrip.get(trip.route_id)!.push(device.device_id);

        groupedServices.get(trip.route_id)!.get(trip.shape_id)!.devices.push(device);
      }
    }
  });

  const collator = new Intl.Collator(i18n.locale.value, {
    numeric: true,
  });
  // Sort finale list
  groupedRouteServices.value = new Map(
    Array.from(groupedServices).sort((a, b) => {
      return collator.compare(a[0], b[0]);
    }),
  );

  groupedDevices.value = devicesByTrip;
  generateGraphs();
});

function generateGraphs() {
  groupedRouteServices.value?.forEach(line => {
    for (const [serviceId, service] of line.entries()) {
      const track = document.getElementById(`track-${serviceId}`) as SVGPathElement | null;
      const initBus = document.getElementById(`bus-${service.devices[0].device_id}`) as SVGGElement | null;
      const pathLength = track?.getTotalLength();

      if (track && initBus && pathLength) {
        // Only generate stops if they are not generated yet
        if (track.nextElementSibling?.classList[0] !== 'stop')
          generateStops(service.stopTimes, track, pathLength, initBus, service.isLoop);

        service.devices.forEach(device => {
          const bus = document.getElementById(`bus-${device.device_id}`) as SVGGElement | null;
          if (bus) {
            const percentPos = getDevicePosition(device, service.stopTimes);
            updateBusPosition(percentPos, track, pathLength, bus);
          }
        });
      }
    }
  });
}

function generateStops(
  stopTimes: StopTime[],
  track: SVGPathElement,
  pathLength: number,
  bus: SVGGElement,
  isLoop: boolean,
) {
  // Specific loop case, last stop should not be at the end of the distance
  const nbrPoints = isLoop ? stopTimes.length : stopTimes.length - 1;

  // Foreach stopTimes, generate an svg circle element
  for (const [i, stopTime] of stopTimes.entries()) {
    const point = track.getPointAtLength((i / nbrPoints) * pathLength);
    const stop = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    stop.setAttribute('class', 'stop');
    stop.setAttribute('cx', point.x.toString());
    stop.setAttribute('cy', point.y.toString());
    stop.setAttribute('r', '1.4');
    stop.setAttribute('data-content', 'Updated text');
    stop.setAttribute('fill', '#fbfbfb');
    stop.setAttribute('stroke', '#333333');
    stop.setAttribute('stroke-width', '0.2');

    // Get Stop name as title to show name on hover
    var title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
    title.textContent = gtfsStops.value[stopTime.stop_id]?.stop_name ?? null;
    stop.appendChild(title);

    //Add element to parent node
    track.parentNode?.insertBefore(stop, bus);
  }
}

function updateBusPosition(percentPos: number, track: SVGPathElement, pathLength: number, bus: SVGGElement) {
  const point = track.getPointAtLength((percentPos * pathLength) / 100);
  // minus 3 to center bus since img is 6*6
  bus.setAttribute('transform', `translate(${point.x - 3}, ${point.y - 3})`);
}

function isDeactivatedRoute(routeId: string | undefined) {
  if (!routeId) return false;
  return store.getters.group.deactivated_routes?.includes(routeId);
}

function getServiceTitle(trip: Trip): string {
  const firstStopName = gtfsStops.value[trip.stop_times[0].stop_id]?.stop_name;
  const lastStopName = gtfsStops.value[trip.stop_times[trip.stop_times.length - 1].stop_id]?.stop_name;
  return `${firstStopName} > ${lastStopName}`.toUpperCase();
}

function getDeviceStopTimeIndex(device: Device, stopTimes: StopTime[]): number {
  const isAtStop = device.current_status === CurrentStatus.STOPPED_AT;
  const index = stopTimes.findIndex(st => st.stop_sequence === device.current_stop_sequence);
  return isAtStop ? index : index - 1;
}

function getDevicePosition(device: Device, stopTimes: StopTime[]): number {
  const currentStopTimeIndex = getDeviceStopTimeIndex(device, stopTimes);
  const distance = device.shape_dist_traveled;

  if (device.current_stop_sequence && distance !== 0 && !(currentStopTimeIndex >= stopTimes.length - 1)) {
    const currentStopDistance = stopTimes[currentStopTimeIndex]?.shape_dist_traveled;
    const nextStopDistance = stopTimes[currentStopTimeIndex + 1]?.shape_dist_traveled;
    const hasShape = currentStopDistance != null && nextStopDistance != null;
    // Calc distance percentage between 2 stops
    const distBetweenCurrentStops =
      hasShape && distance
        ? (distance - currentStopDistance) / (nextStopDistance - currentStopDistance)
        : 0.5;

    // Result is the total past percentage (nbr of passed stops * percentageBetween2Stops) + distBetweenCurrentStops * percentageBetween2Stops
    const percentageBetween2Stops = (1 / stopTimes.length) * 100;
    return Math.round(
      currentStopTimeIndex * percentageBetween2Stops +
        percentageBetween2Stops * Math.max(distBetweenCurrentStops, 0),
    );
  } else return 0;
}

function getDeviceDelayColor(device: Device): string {
  return `#${DEVICE_DELAY_COLOR[store.getters['devices/getDelayState'](device.delay)]}`;
}

function selectATrip(deviceId: string) {
  if (props.selectedDeviceId === deviceId) {
    emit('update:selectedDeviceId', null);
  } else {
    emit('update:selectedDeviceId', deviceId);
  }
}
</script>

<template>
  <div v-if="groupedRouteServices" class="routes-diagrams hide-scrollbar">
    <div v-for="[routeKey, serviceMap] in groupedRouteServices" :key="routeKey">
      <v-card
        :id="'route_' + routeKey"
        rounded="lg"
        flat
        :class="{
          'opacity-50':
            selectedDeviceId !== null && !groupedDevices?.get(routeKey)?.includes(selectedDeviceId),
        }"
      >
        <v-card-item class="routes-diagrams__card__title">
          <v-card-title @click="emit('update:selectedDeviceId', null)">
            <RouteBadge
              :route="serviceMap.values().next().value!.route"
              :value="serviceMap.values().next().value!.route.route_id"
              :is-experimental="isDeactivatedRoute(serviceMap.values().next().value?.route.route_id)"
              class="mr-3"
            />
            {{ $t('tripsRunning', { count: groupedDevices!.get(routeKey)!.length }) }}
          </v-card-title>
        </v-card-item>
        <template v-for="[serviceKey, service] in serviceMap" :key="serviceKey">
          <v-divider class="my-0"></v-divider>
          <v-card-item class="routes-diagrams__card__service">
            <span
              class="routes-diagrams__card__service__title"
              @click="emit('update:selectedDeviceId', null)"
            >
              {{ service.title }}
              <span
                v-if="duplicateServiceName.includes(service.title)"
                class="routes-diagrams__card__service__subtitle ml-3"
              >
                {{ $t('service', [service.duplicateNumber + 1]) }}
              </span>
            </span>
            <div class="routes-diagrams__card__service__diagram">
              <svg
                v-show="!service.isNewLoad"
                :viewBox="service.isLoop ? viewBoxLoop : viewBoxLine"
                style="width: 100%"
              >
                <path
                  :d="service.isLoop ? routeLoopGraph : routeLineGraph"
                  fill="none"
                  stroke="#333"
                  stroke-width="1.6"
                />
                <path
                  :id="`track-${serviceKey}`"
                  :d="service.isLoop ? routeLoopGraph : routeLineGraph"
                  fill="none"
                  :stroke="'#' + serviceMap.values().next().value?.route.route_color"
                  stroke-width="1.1"
                />
                <g v-for="device in service.devices" :id="`bus-${device.device_id}`" :key="device.device_id">
                  <circle r="3" transform="translate(3, 3)" fill="#fbfbfb"></circle>
                  <image
                    class="device"
                    :class="{
                      device__selected: selectedDeviceId === device.device_id,
                      'device__not-selected':
                        selectedDeviceId !== null && selectedDeviceId !== device.device_id,
                    }"
                    width="6"
                    height="6"
                    xlink:href="@/assets/img/bus-diagram.png?url"
                    @click="selectATrip(device.device_id)"
                  >
                    <title>{{ device.name }}</title>
                  </image>
                  <circle
                    r="1.1"
                    transform="translate(5, 1)"
                    :fill="getDeviceDelayColor(device)"
                    stroke="#fbfbfb"
                    stroke-width="0.2"
                  ></circle>
                </g>
              </svg>
              <v-progress-circular
                v-show="service.isNewLoad"
                color="#b3b3b3"
                indeterminate
                :size="28"
              ></v-progress-circular>
            </div>
          </v-card-item>
        </template>
      </v-card>
    </div>
  </div>
  <div v-else class="ma-3 w-100">
    <div v-for="index in 5" :key="index">
      <v-skeleton-loader class="loading-skeleton mb-3" type="image" />
    </div>
  </div>
</template>

<style lang="scss">
.routes-diagrams {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 12px;

  .route-badge {
    display: inline-flex;
    border-radius: 8px;
  }

  &__card {
    color: $text-dark;

    &__title {
      padding: 16px;
      font-size: 14px;
    }

    &__service {
      padding: 0;
      background-color: $background;

      .v-card-item__content {
        display: flex;
        flex-direction: column;
      }

      &__title {
        margin: 16px 16px 0;
        font-weight: 600;
      }

      &__subtitle {
        color: $text-neutral;
        font-weight: 500;
      }

      &__diagram {
        display: flex;
        margin: 10px 20px 16px;

        .device {
          cursor: pointer;

          &__not-selected {
            opacity: 0.5;
          }

          &__selected {
            transform: scale(1.15);
          }
        }
      }
    }
  }
}

.loading-skeleton {
  height: 120px;

  .v-skeleton-loader__image {
    border-radius: 8px;
  }
}
</style>

<i18n locale="fr">
  {
    "tripsRunning": "{count} course en cours | {count} courses en cours",
    "service": "Desserte {0}"
  }
</i18n>

<i18n locale="en">
  {
    "tripsRunning": "{count} trip running | {count} trips running",
    "service": "Service {0}"
  }
</i18n>
