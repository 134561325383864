<script setup lang="ts">
import { useField, useFieldArray } from 'vee-validate';
import { computed } from 'vue';
import Btn from '@/components/ui/Btn.vue';
import ConnectorFieldHostsItem from '@/pages/IntegrationsPage/ConnectorFieldHostsItem.vue';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const { fields, push, remove } = useFieldArray(computed(() => props.name));
const { errorMessage } = useField(computed(() => props.name));
</script>

<template>
  <div class="connector-field-hosts">
    <div class="form-group">
      <label class="form-group__label" :for="name">
        <slot name="label">
          {{ $t('label') }}
        </slot>
      </label>
      <Btn
        small
        outlined
        type="secondary"
        class="connector-field-hosts__add_row_button"
        @click="() => push('')"
      >
        {{ $t('add_item') }}
        <font-awesome-icon icon="fa-plus" />
      </Btn>
      <ConnectorFieldHostsItem
        v-for="(field, $index) of fields"
        :key="field.key"
        v-model="field.value as string"
        class="connector-field-hosts__input_row"
        :name="`${name}[${$index}]`"
        @remove="() => remove($index)"
      />
      <span v-if="errorMessage" class="form-group__error-label">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.connector-field-hosts {
  margin-right: 10px;

  &__add_row_button {
    margin-bottom: 10px;
    margin-left: 10px;

    svg {
      margin-left: 4px;
    }
  }

  &__remove_row_button {
    width: 34px;
    margin-left: 5px;
  }

  &__input_row {
    margin-bottom: 8px;
  }
}
</style>

<i18n locale="fr">
{
  "label": "Adresses IP",
  "add_item": "Ajouter"
}

</i18n>

<i18n locale="en">
{
  "label": "IP addresses",
  "add_item": "Add"
}
</i18n>
