<script setup lang="ts">
import { computed, ref, onMounted, watch, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import { dateGtfsFormatToObj } from '@/libs/helpers/dates';

import type { DataGrid } from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import type { Urgency } from '@/@types/api/urgency';
import type { Route, Trip } from '@/@types/gtfs';
import type { Driver } from '@/@types/driver';

import { ColumnKey, getDatagrid } from './ArchivedUrgenciesList.conf';

interface UrgencyFormatted {
  acknowledgmentOperator?: string;
  acknowledgmentTime?: Date;
  comment?: string;
  device: string;
  driver?: string;
  id: string;
  isFalseAlert: string;
  receptionTime: Date;
  sendTime?: Date;
  trip?: UrgencyTrip;
  resolutionTime?: Date;
  resolutionOperator?: string;
  route?: Route;
  routeShortName?: string;
}

interface UrgencyTrip {
  gtfs_id: string;
  id: string;
  start_date: string;
}

// TODO remove when StopListPage is migrated to ts
interface StopListItem {
  _id: string;
  stop_code: string;
  stop_name: string;
  stopName: string;
  stopRadius: number;
  routes: Array<Route>;
  routesFormatted: Array<string>;
  parentStationId?: string;
  parentStationName?: string;
}

const props = defineProps({
  archivedUrgencies: {
    type: Array as PropType<Urgency[]>,
    required: true,
  },
  showFullHeight: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const { t } = useI18n();

const archivedUrgenciesFormatted = ref<UrgencyFormatted[]>([]);
const datagrid = ref<DataGrid>(getDatagrid());
const loading = ref<boolean>(true);
const renderedDataLength = ref<number>(0);
const routeList = ref<{ [routeId: string]: Route }>({});

const gtfsTrips = computed<{ [tripId: string]: Trip }>(() => store.state.gtfs.tripsMap);

const buildCellInjectors = computed<{
  [key in ColumnKey]?: (apiData: { apiData: StopListItem }) => {
    groupId: string;
  };
}>(() => {
  return {
    [ColumnKey.TRIP_FORMATTED_NAME]: () => ({ groupId: store.getters.group._id }),
  };
});

const driverList = computed<Driver[]>(() => {
  return Object.values(store.state.drivers.list);
});

async function generateFormattedTripName(tripId: string, date?: Date) {
  return store.dispatch('gtfs/formatTripName', {
    tripId,
    date,
  });
}

async function formatArchivedUrgencies() {
  loading.value = true;
  // Remove non-resolved urgencies
  const filteredUrgencies = props.archivedUrgencies.filter(urgency => urgency.resolution);
  archivedUrgenciesFormatted.value = await Promise.all(
    filteredUrgencies.map(async urgency => {
      const tripFormattedName = urgency.trip
        ? await generateFormattedTripName(urgency.trip.id, dateGtfsFormatToObj(urgency.trip.start_date))
        : undefined;
      const routeId = urgency.trip ? gtfsTrips.value[urgency.trip.id]?.route_id : undefined;
      return {
        id: urgency.id,
        sendTime: new Date(urgency.device_time),
        receptionTime: new Date(urgency.api_time * 1000),
        acknowledgmentTime: urgency.acknowledgment ? new Date(urgency.acknowledgment.time) : undefined,
        acknowledgmentOperator: urgency.acknowledgment?.user_id,
        driver: driverList.value.find(driver => driver.id === urgency.driver_id)?.driver_name,
        device: urgency.device_id,
        trip: urgency.trip,
        resolutionTime: urgency.resolution ? new Date(urgency.resolution.time) : undefined,
        resolutionOperator: urgency.resolution?.user_id,
        tripFormattedName,
        route: routeId ? routeList.value[routeId] : undefined,
        routeShortName: routeId ? routeList.value[routeId]?.route_short_name : undefined,
        isFalseAlert: urgency.resolution?.is_false_alert ? t('Yes') : t('No'),
        comment: urgency.resolution?.comment,
      };
    }),
  );
  loading.value = false;
}

async function loadRoutes() {
  routeList.value = await store.dispatch('gtfs/getRoutesMap');
}

watch(
  () => props.archivedUrgencies,
  () => formatArchivedUrgencies(),
);

onMounted(() => {
  loadRoutes();
});
</script>

<template>
  <DataGridVuetify
    ref="dataGrid"
    v-model:rendered-data-length="renderedDataLength"
    class="archived-urgencies-table"
    :class="{ 'full-height': props.showFullHeight }"
    :title="$t('archivedUrgencies', { count: renderedDataLength })"
    :data="archivedUrgenciesFormatted"
    :datagrid="datagrid"
    :loading="loading"
    :show-header="false"
    :build-cell-injectors="buildCellInjectors"
  />
</template>

<style lang="scss">
.archived-urgencies-table {
  $urgencies-datagrid-height: calc(100vh - ($view-standard-padding * 2) - $navbar-top - 54px - 52px - 280px);
  $urgencies-datagrid-full-height: calc(100vh - ($view-standard-padding * 2) - $navbar-top - 56px);

  &.datagrid-vuetify {
    .v-table__wrapper {
      height: $urgencies-datagrid-height;
    }
  }

  &.full-height {
    &.datagrid-vuetify {
      .v-table__wrapper {
        height: $urgencies-datagrid-full-height;
      }
    }
  }
}
</style>

<i18n locale="fr">
  {
    "archivedUrgencies": "urgence archivée | urgences archivées",
  }
</i18n>

<i18n locale="en">
  {
    "archivedUrgencies": "archived urgency | archived urgencies",
  }
</i18n>
