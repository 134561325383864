<script setup lang="ts">
import Btn from '@/components/ui/Btn.vue';
import { computed, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['uploaded-file']);

const props = defineProps({
  acceptedFormat: {
    type: String,
    required: true,
  },
});

const fileInput = useTemplateRef('fileInput');
const uploadedFile = ref<File>();
const errorMessage = ref<string>();

const acceptedFormatText = computed<string>(() => {
  let text = t('supportedFormat', props.acceptedFormat.split(',').length);
  text += ` ${props.acceptedFormat.replace(',', ', ')}`;
  return text;
});

watch(
  () => uploadedFile.value,
  () => {
    emit('uploaded-file', uploadedFile.value);
  },
);

watch(
  () => errorMessage.value,
  () => {
    if (errorMessage.value) emit('uploaded-file', undefined);
  },
);

function onChangeFile() {
  uploadedFile.value = undefined;
  errorMessage.value = undefined;
  if (fileInput.value?.files && fileInput.value.files.length > 1) {
    errorMessage.value = t('tooManyFilesError');
    return;
  }
  const importedFile: File | undefined = fileInput.value?.files?.[0];
  if (!importedFile) return;
  const fileFormat = `.${importedFile.name.split('.').pop()!.toLowerCase()}`;

  if (!props.acceptedFormat.split(',').includes(fileFormat)) {
    errorMessage.value = t('formatError');
    return;
  }
  uploadedFile.value = importedFile;
}

function removeFile() {
  if (fileInput.value) fileInput.value.value = '';
  uploadedFile.value = undefined;
}

function dragover(event: DragEvent) {
  event.preventDefault();
  const targetedElement = event?.currentTarget as HTMLElement;
  // Add some visual fluff to show the user can drop its files
  if (!targetedElement?.classList.contains('drag-and-drop__dropping')) {
    targetedElement.classList.add('drag-and-drop__dropping');
  }
}

function dragleave(event: DragEvent) {
  // Clean up
  const targetedElement = event?.currentTarget as HTMLElement;
  targetedElement?.classList.remove('drag-and-drop__dropping');
}

function drop(event: DragEvent) {
  event.preventDefault();
  if (fileInput.value?.files) fileInput.value.files = event.dataTransfer?.files as FileList;

  onChangeFile();
  const targetedElement = event?.currentTarget as HTMLElement;
  targetedElement?.classList.remove('drag-and-drop__dropping');
}
</script>

<template>
  <div>
    <div class="drag-and-drop" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <font-awesome-icon class="drag-and-drop__icon" icon="fa-circle-up" />
      <span>{{ $t('dragAndDrop') }}</span>
      <span class="drag-and-drop__separation">{{ $t('or').toUpperCase() }}</span>
      <!-- First input is for drag & drop styling, second is a button reffering to the same id & work with click -->
      <input
        id="fileHandler"
        ref="fileInput"
        type="file"
        :accept="acceptedFormat"
        style="display: none"
        @change="onChangeFile"
      />
      <input
        type="button"
        class="drag-and-drop__browse-btn ui-btn secondary"
        :value="$t('browse')"
        onclick="document.getElementById('fileHandler').click();"
      />
    </div>
    <div class="drag-and-drop__format">{{ acceptedFormatText }}</div>
    <div v-if="errorMessage" class="drag-and-drop__error">{{ errorMessage }}</div>
    <div v-if="uploadedFile" class="drag-and-drop__uploaded-file">
      <div>
        <font-awesome-icon class="mr-2" icon="fa-file" />
        {{ uploadedFile.name }}
      </div>
      <Btn type="icon-only" class="drag-and-drop__uploaded-file__remove" @click="removeFile()">
        <font-awesome-icon icon="fa-xmark" />
      </Btn>
    </div>
  </div>
</template>

<style lang="scss">
.drag-and-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  width: 100%;
  height: 240px;
  padding: 32px;
  border: 2px dashed $border;
  border-radius: 12px;
  background-color: $canvas;
  color: $text-neutral;
  font-weight: 600;
  transition: all 0.3s linear;

  &:hover {
    border-color: $text-dark;
    background-color: $background-variant;
    color: $text-dark;
  }

  &__dropping {
    border-color: $blue;
    background-color: $transparent-blue;
    color: $blue;
  }

  &__separation {
    padding: 24px;
  }

  &__icon {
    padding-bottom: 8px;
    font-size: 36px;
  }

  &__format {
    padding: 12px 0;
    color: $text-neutral;
    font-weight: 500;
  }

  &__error {
    padding-bottom: 12px;
    color: $danger;
    font-weight: 500;
  }

  &__uploaded-file {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;
    width: 100%;
    padding: $view-standard-padding;
    border: 1px solid $border;
    border-radius: 6px;
    background-color: $canvas;
    color: $text-dark-variant;
    font-weight: 600;

    &__remove {
      border-width: 0 !important;

      svg {
        font-size: 18px !important;

        &:hover {
          opacity: 0.7;
        }
      }

      &.ui-btn {
        background-color: transparent !important;
      }
    }
  }
}
</style>
<i18n locale="fr">
  {
    "dragAndDrop": "Glissez / déposez un fichier ici",
    "browse": "Parcourir",
    "supportedFormat": "Format supporté : | Formats supportés : ",
    "formatError": "Format non supporté, veuillez importer un autre type de fichier.",
    "tooManyFilesError": "Veillez à n’importer qu’un seul fichier."
  }
  </i18n>

<i18n locale="en">
  {
    "dragAndDrop": "Drag and drop a file here",
    "browse": "Browse files",
    "supportedFormat": "Supported format : | Supported formats : ",
    "formatError": "Format not supported, please import another file type.",
    "tooManyFilesError": "Please import only one file."
  }
  </i18n>
