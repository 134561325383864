<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { TagProperties } from '@/components/ui/Tag.vue';

import type { TripUpdates } from '@/store-pinia/trip-updates';

import Tag from '@/components/ui/Tag.vue';

enum RegulationType {
  DELAY = 'delay',
  CANCELED = 'canceled',
  STOP_SKIPPED = 'stopSkipped',
}

const props = defineProps<{
  regulation: TripUpdates;
}>();

const { t } = useI18n();

const regulationsInfo = computed<TagProperties[]>(() => {
  const info = [];

  if (props.regulation.is_canceled) {
    info.push({
      text: t(`regulation.${RegulationType.CANCELED}`),
      color: '#a41414', // $danger-dark
      backgroundColor: '#fbdddd', //$light-red
      icon: 'fa-xmark',
    });
  }

  if (props.regulation.skipped_stop_time_seqs?.length > 0) {
    info.push({
      text: t(`regulation.${RegulationType.STOP_SKIPPED}`, {
        count: props.regulation.skipped_stop_time_seqs.length,
      }),
      color: '#f99c49', // $warn
      backgroundColor: '#feebdb', //$flat-orange
      icon: 'fa-arrows-split-up-and-left',
    });
  }

  if (props.regulation.delay) {
    info.push({
      text: props.regulation.delay > 0 ? t(`regulation.${RegulationType.DELAY}`) : t(`regulation.advance`),
      color: '#f99c49', // $warn
      backgroundColor: '#feebdb', //$flat-orange
      icon: 'fa-clock',
    });
  }

  return info;
});
</script>

<template>
  <div class="regulation-status-cell">
    <Tag
      v-for="(info, index) in regulationsInfo"
      :key="index"
      :text="info.text || ''"
      :color="info.color"
      :background="info.backgroundColor"
      :icon="info.icon"
      class="regulation-tag"
    />
  </div>
</template>

<style scoped>
.regulation-status-cell {
  display: flex;
  gap: 8px;
}
</style>
