<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import type { Urgency } from '@/@types/api/urgency';
import { urgencies as ApiUrgencies } from '@/api';
import { useCurrentUrgencies, type FormattedUrgency } from '@/components/common/Urgencies/current-urgencies';
import MapboxMap from '@/components/map/MapboxMap.vue';
import MapboxUrgencyLayer from '@/components/map/MapboxUrgencyLayer.vue';
import Btn from '@/components/ui/Btn.vue';
import ModalMessageNew, { type Recipient, RecipientType } from '@/components/ui/ModalMessageNew.vue';
import Pagination from '@/components/ui/Pagination.vue';
import { GroupRoute } from '@/libs/routing';
import { DeviceConnectionStatus } from '@/pages/DeviceListPage/cells/ConnectionCell.vue';
import type { Device } from '@/store/devices';

import ModalResolveUrgency from './ModalResolveUrgency.vue';

const store = useStore();

const props = defineProps<{
  urgencies: Urgency[];
}>();

const {
  activeUrgency,
  activeUrgencyIndex,
  formattedUrgency,
  map,
  mapCenter,
  mapLoaded,
  positionSource,
  onMapLoad,
} = useCurrentUrgencies(() => props.urgencies);

const urgencyDeviceRecipient = ref<Recipient | null>(null);
const urgencyDeviceStatus = ref('');
const showModalMessage = ref(false);
const showModalResolve = ref(false);
const isFalseAlert = ref(false);

const googleMapsLink = computed(() => {
  const urgency = activeUrgency.value;
  return `https://www.google.com/maps/search/?api=1&query=${urgency?.device_position.latitude},${urgency?.device_position.longitude}`;
});

const groupId = computed(() => store.getters.group._id);

// Update associated data for selected urgency:
watch(
  activeUrgencyIndex,
  async () => {
    const urgency = activeUrgency.value;
    if (!urgency) return;

    const device = await getUrgencyDevice();
    urgencyDeviceStatus.value = store.state.devices.online[device.device_id]
      ? DeviceConnectionStatus.ONLINE
      : DeviceConnectionStatus.OFFLINE;

    urgencyDeviceRecipient.value = {
      id: device.device_id,
      label: device.name,
      type: RecipientType.DEVICE,
    };
  },
  { immediate: true },
);

async function getUrgencyDevice(): Promise<Device> {
  const urgency = activeUrgency.value as Urgency;
  const device = await store.dispatch('devices/getDevice', { deviceId: urgency.device_id });
  return device;
}

// Actions :
async function resolveUrgency(event: string) {
  const urgency = activeUrgency.value as Urgency;
  await ApiUrgencies.resolve(groupId.value, urgency.urgency_id, isFalseAlert.value, event);
  showModalResolve.value = false;
  isFalseAlert.value = false;
}
</script>

<template>
  <div v-if="formattedUrgency && activeUrgency" class="current-urgencies">
    <div class="current-urgencies__header">
      <div class="current-urgencies__count">
        <v-icon>fas fa-exclamation-circle</v-icon>
        {{ $tc('urgencyAlerts', { count: urgencies.length }) }}
      </div>
      <div class="current-urgencies__nav">
        <Pagination
          :items="urgencies"
          :short-format="true"
          :initial-page="activeUrgencyIndex + 1"
          :page-size="1"
          @changePage="
            page => {
              activeUrgencyIndex = page.start;
            }
          "
        />
      </div>
    </div>

    <div class="current-urgencies__body">
      <div class="current-urgencies__infos">
        <table class="urgency-table">
          <tr v-for="key in Object.keys(formattedUrgency)" :key="key" class="urgency-table__row">
            <td class="urgency-table__label">{{ $t(`label.${key}`) }}</td>
            <td v-if="key === 'device'" class="urgency-table__field">
              <span class="connection-cell">
                <font-awesome-icon
                  icon="fa-circle"
                  class="connection-cell__icon"
                  :class="
                    urgencyDeviceStatus === DeviceConnectionStatus.ONLINE
                      ? 'connection-cell__icon--online'
                      : 'connection-cell__icon--offline'
                  "
                />
              </span>
              <router-link
                :to="{ name: GroupRoute.DEVICE_DETAILLED, params: { deviceId: activeUrgency.device_id } }"
              >
                {{ formattedUrgency[key] }}
              </router-link>
            </td>
            <td v-else-if="key === 'trip'" class="urgency-table__field">
              <router-link
                v-if="activeUrgency.trip"
                :to="{ name: GroupRoute.TRIP_DETAILED, params: { tripId: activeUrgency.trip.id } }"
              >
                {{ formattedUrgency[key] }}
              </router-link>
              <span v-else>-</span>
            </td>
            <td v-else-if="key === 'position'" class="urgency-table__field">
              <span>{{ formattedUrgency[key] }}</span>
              <span class="urgency-table__googlemaps">
                <Btn :link-url="googleMapsLink" type="tertiary">
                  <font-awesome-icon icon="fa-map-marker-alt" />
                  {{ $t('googleMaps') }}
                </Btn>
              </span>
            </td>
            <td v-else class="urgency-table__field">{{ formattedUrgency[key as keyof FormattedUrgency] }}</td>
          </tr>
        </table>
        <div class="current-urgencies__cta">
          <Btn small type="primary" :title="$t('resolveAlert')" @click="showModalResolve = true">
            <v-icon>fas fa-check</v-icon>
            <span>{{ $t('resolveAlert') }}</span>
          </Btn>
          <Btn small type="secondary" :title="$t('contactDevice')" @click="showModalMessage = true">
            <v-icon>fas fa-envelope</v-icon>
            <span>{{ $t('contactDevice') }}</span>
          </Btn>
        </div>
      </div>
      <div class="current-urgencies__map">
        <MapboxMap
          border-radius="8px"
          :gtfs-id="activeUrgency.trip?.gtfs_id ?? ''"
          :stops="[]"
          :trips="[]"
          :center="mapCenter"
          dropdown-style-option-only
          @load="onMapLoad"
        >
          <MapboxUrgencyLayer
            v-if="map && mapLoaded && positionSource"
            :map="map"
            :position-source="positionSource"
          />
        </MapboxMap>
      </div>
    </div>
    <ModalMessageNew
      v-if="showModalMessage"
      :recipients="[urgencyDeviceRecipient]"
      @close="showModalMessage = false"
    />
    <ModalResolveUrgency
      v-if="showModalResolve"
      @close="showModalResolve = false"
      @submit="event => resolveUrgency(event)"
    >
      <template #extra-input>
        <v-checkbox id="next-days" v-model="isFalseAlert" color="success" hide-details>
          <template #label>
            <span>
              {{ $t('isFalseAlert') }}
            </span>
          </template>
        </v-checkbox>
      </template>
    </ModalResolveUrgency>
  </div>
</template>

<style lang="scss">
.current-urgencies {
  padding-bottom: $view-standard-padding;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__count {
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: $light-red;
    color: $danger;
    font-weight: 600;
    font-size: 14px;

    .v-icon {
      margin-right: 5px;
      font-size: 14px;
    }
  }

  &__nav {
    .pagination-component {
      margin: 0;
      font-weight: 600;
      font-size: 12px;

      .v-icon {
        font-size: 12px;
      }
    }
  }

  &__body {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__infos {
    flex: 1;
    border: 1px solid $border-variant;
    border-radius: 8px;
    font-size: 12px;

    .urgency-table {
      width: 100%;
      padding: 12px 0;
      border-bottom: 1px solid $background-variant;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: $background;

      &__label {
        width: 25%;
        padding-left: 20px;
        font-weight: 600;
      }

      &__row {
        height: 26px;
      }

      &__field {
        .ui-btn {
          display: inline-block;
          margin-left: 15px;
          padding: 0;
          padding: 2px 5px;
          border: 1px solid $border-variant;
          color: $text-dark-variant;
          font-size: 11px;
          line-height: 1.4;

          .fa-location-dot {
            display: inline-block;
            margin-top: 2px;
            margin-right: 5px;
            font-size: 11px;
          }
        }
      }

      &__googlemaps {
        .ui-btn {
          height: 20px;
        }
      }
    }
  }

  &__cta {
    display: flex;
    padding: 12px 15px;

    .ui-btn {
      font-weight: 500;
      font-size: 12px;

      .v-icon {
        margin-right: 8px;
        font-size: 12px;
      }
    }
  }

  &__map {
    position: relative;
    flex: 1;
    border: 1px solid $border-variant;
    border-radius: 8px;
  }

  .connection-cell {
    &__icon {
      margin-right: 5px;
      padding: 0 3px 1px;
      font-size: 8px;

      &--online {
        color: $primary-light;
      }

      &--offline {
        color: $text-neutral;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "urgencyAlerts": "{count} alerte urgente en cours | {count} alertes urgentes en cours",
  "handleAlerts": "Traiter les alertes",
  "googleMaps": "Voir dans Google Maps",
  "resolveAlert": "Résoudre l'alerte",
  "contactDevice": "Contacter l'appareil",
  "isFalseAlert": "Fausse alerte",
  "label": {
    "driver": "Conducteur",
    "vehicle": "Véhicule",
    "device": "Appareil",
    "trip": "Course",
    "datetime": "Date d'envoi",
    "position": "Position",
    "address": "Dernière adresse"
  }
}
</i18n>

<i18n locale="en">
{
  "urgencyAlerts": "{count} ongoing urgency | {count} ongoing urgencies",
  "handleAlerts": "Handle alerts",
  "googleMaps": "View in Google Maps",
  "resolveAlert": "Resolve alert",
  "contactDevice": "Contact device",
  "isFalseAlert": "False alert",
  "label": {
    "driver": "Driver",
    "vehicle": "Vehicle",
    "device": "Device",
    "trip": "Trip",
    "datetime": "Sent on",
    "position": "Position",
    "address": "Last address"
  }
}
</i18n>
