<template>
  <div
    class="datagrid-vuetify"
    :class="{
      'datagrid-vuetify__is-overflowing': isOverflowing,
      'datagrid-vuetify__with-checkbox': datagrid.isSelectableList(),
    }"
  >
    <div class="datagrid-vuetify__body">
      <template v-if="error">
        <div class="datagrid-vuetify__error">
          {{ error }}
        </div>
      </template>
      <template v-else>
        <v-data-table-virtual
          v-model:sort-by="sortBy"
          v-model="selectedItems"
          return-object
          :show-select="datagrid.isSelectableList()"
          :no-data-text="data.length > 0 ? $t('noDataWithFilters') : $t('noData')"
          :height="height"
          :item-height="48"
          :loading="isDatagridInLoadState"
          :headers="headers"
          :items="renderedData"
          :custom-key-sort="{
            version: compareSemVer,
          }"
          fixed-header
        >
          <template #loading>
            <v-skeleton-loader type="table-row@10"></v-skeleton-loader>
          </template>
          <!-- top bar with title or tabs & datagrid actions -->
          <template #top>
            <div class="d-flex justify-space-between">
              <div class="datagrid-vuetify__top-left-container">
                <v-tabs v-if="localTabs.length > 0" v-model="activeTab" slider-color="#00b871">
                  <v-tab
                    v-for="tab in localTabs"
                    :key="tab.value"
                    :value="tab.value"
                    :prepend-icon="tab.icon"
                  >
                    {{ tab.name }} ({{ tab.counter }})
                  </v-tab>
                </v-tabs>
                <template v-else-if="title">
                  <h3 class="datagrid-vuetify__top-left-title">{{ `${renderedData.length} ${title}` }}</h3>
                </template>
              </div>
              <div ref="topRowRight" class="datagrid-vuetify__top-right-actions d-flex">
                <div class="d-flex">
                  <TableSearchBarV2
                    v-if="datagrid.getSearchFields() !== null"
                    :search-fields="datagrid.getSearchFields()"
                    :search-list="data"
                    :disabled="isInEditionMode"
                    :id-key="datagrid.getRowIdKey()"
                    :show-icon-only="isSmallScreen"
                    @filteredList="resultIdList => (searchBarResultIds = resultIdList)"
                  />
                  <DataGridFilterEraser
                    v-if="datagrid.getFilterableColumns().length > 0"
                    :filters="columnFilters"
                    :disabled="isInEditionMode"
                    :ls-filters-name="datagrid.localStorageFilterName()"
                    :show-icon-only="isSmallScreen"
                    @eraseFilters="removeFilters"
                  />
                  <DataGridColumnSelector
                    v-if="datagrid.showColumnSelector"
                    :options="datagrid.getSelectableColumns()"
                    :local-storage-key="datagrid.getColumnSelectionLocalStorageKey()"
                    :show-icon-only="isSmallScreen"
                    @onChangeColumnsSelection="selection => (selectedColumns = selection)"
                  />
                </div>
              </div>
            </div>
          </template>
          <template
            #headers="{ columns, isSorted, getSortIcon, toggleSort, selectAll, someSelected, allSelected }"
          >
            <tr class="header-row">
              <!-- checkbox select all if neeeded -->
              <th
                v-if="datagrid.isSelectableList()"
                class="header-column header-column--checkbox column-checkbox column-sticky"
                :class="{ 'header-column--selected': someSelected }"
              >
                <v-checkbox
                  :model-value="allSelected"
                  color="success"
                  :indeterminate="someSelected && !allSelected"
                  hide-details
                  @change="selectAll(!allSelected)"
                ></v-checkbox>
              </th>
              <!-- Headers, including "merged tag", sortBy, columnFilters & title -->
              <th
                v-for="header in columns.filter(c => c.key !== 'data-table-select')"
                :id="header.key"
                :key="header.value"
                class="header-column"
                :class="getColumnTypeClass(header.key)"
              >
                <div
                  class="header-content"
                  @click="() => (header.sortable && !isInEditionMode ? toggleSort(header) : null)"
                >
                  <div
                    class="header-content__title"
                    :class="{ 'cursor-pointer': header.sortable && !isInEditionMode }"
                  >
                    <div v-if="!!merging[header.key]" class="datagrid-vuetify__col-merged">
                      {{ $t('merged') }}
                    </div>
                    <div>{{ $t(header.title) }}</div>
                  </div>

                  <v-icon v-if="header.tooltip" :title="header.tooltip" class="header-tooltip">
                    fa:fas fa-question-circle
                  </v-icon>

                  <v-icon
                    v-if="header.sortable && !isInEditionMode"
                    class="header-content__sort-icon cursor-pointer"
                    :class="{ 'header-content__sort-icon--active': isSorted(header) }"
                    :icon="getSortIcon(header)"
                    size="x-small"
                  ></v-icon>

                  <template v-if="header.filterable && columnFilters[header.key]">
                    <DataGridColumnFilter
                      v-model:filters="columnFilters[header.key]"
                      :custom-filter="getCustomFilter(header.title)"
                      :ls-filters-name="datagrid.localStorageFilterName()"
                      :disabled="isInEditionMode"
                      :class="{
                        'datagrid-column-filter--active': columnFilters[header.key].isActive,
                        'datagrid-column-filter--opened': openedFilters[header.key],
                      }"
                      @toggleAll="([check]) => toggleAllFilters(header, check)"
                      @update:isOpened="isOpen => (openedFilters[header.key] = isOpen)"
                    />
                  </template>
                </div>
              </th>
            </tr>
            <!-- replaced header by action multiple if lines are checked -->
            <template v-if="datagrid.isSelectableList() && someSelected">
              <div class="false-header-column-checked">
                <div class="false-header-column-checked__content">
                  <div class="header-selected-element">
                    <div class="header-selected-element__title">
                      {{
                        $tc('selectedItems', selectedItems.length, {
                          count: selectedItems.length,
                        })
                      }}
                    </div>
                    <div class="header-selected-element__actions">
                      <slot name="selectedItemsActions" :objects="selectedItems" :active-tab="activeTab" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <!-- Row values -->
          <template #item="{ item, toggleSelect, isSelected, internalItem }">
            <tr
              :id="item[datagrid.rowIdKey]"
              :class="{
                'row-selected': isSelected(internalItem),
                'row-in-edition': lineIdInEdition === item[datagrid.rowIdKey],
                'row-not-in-edition': lineIdInEdition !== item[datagrid.rowIdKey] && lineIdInEdition !== null,
              }"
            >
              <!-- Potential checkbox -->
              <td v-if="datagrid.isSelectableList()" class="datagrid-cell column-checkbox column-sticky">
                <v-checkbox
                  :model-value="isSelected(internalItem)"
                  hide-details
                  color="success"
                  @change="toggleSelect(internalItem)"
                ></v-checkbox>
              </td>
              <!-- Cell value, can be a slot if column is action, a simple span value if basic data, or a custom DataGridCell  -->
              <td
                v-for="(column, key) in headers"
                :key="key"
                :class="getColumnTypeClass(column.key)"
                class="datagrid-cell"
              >
                <template v-if="column.key === COLUMN_KEY_ACTION">
                  <slot name="actions" :object="item" />
                </template>
                <template v-else-if="!column.cellBuilder">
                  <div :set="(value = column.value(item))">
                    <span class="datagrid-vuetify__default-text" :title="value">
                      {{ value || NO_DATA }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <DataGridCell :data-cell="getDataCellBuilder(column, item)" />
                </template>
              </td>
            </tr>
            <!-- Handle children item -->
            <template
              v-if="
                item.childrenItems &&
                item.childrenItems.length > 0 &&
                item.displayChildren === StateMergingChildren.SHOWN
              "
            >
              <tr v-for="(children, childKey) in item.childrenItems" :key="childKey" class="row-children">
                <td
                  v-for="(column, key) in headers"
                  :key="key"
                  :class="getColumnTypeClass(column.key)"
                  class="datagrid-cell"
                >
                  <template v-if="!column.cellBuilder">
                    <div :set="(value = column.value(children))">
                      <span :title="value">
                        {{ value || NO_DATA }}
                      </span>
                    </div>
                  </template>
                  <template v-else>
                    <DataGridCell :data-cell="getDataCellBuilder(column, children)" />
                  </template>
                </td>
              </tr>
            </template>
          </template>
          <template v-if="data.length === 0 && datagrid.useCustomNoData" #no-data>
            <DataGridNoData :title="title" @noDataActionTriggered="$emit('noDataActionTriggered')" />
          </template>
        </v-data-table-virtual>
      </template>

      <!-- Slot to display custom component in case of no data -->
      <slot></slot>
    </div>

    <slot name="footer" />
  </div>
</template>

<script>
import { defineAsyncComponent, toRaw } from 'vue';
import { isNumeric } from '@/libs/helpers/numbers';

import TableSearchBarV2 from '@/components/Table/TableSearchBarV2.vue';
import { DatagridColumnResizer } from '@/libs/datagridColumnResizer';

import DataGridCell from './DataGridCell.vue';
import DataGridColumnFilter from './DataGridColumnFilter.vue';
import DataGridColumnSelector from './DataGridColumnSelector.vue';
import DataGridFilterEraser from './DataGridFilterEraser.vue';
import DataGridNoData from './DataGridNoData.vue';
import { DataGrid, COLUMN_KEY_ACTION, NO_DATA, StateMergingChildren } from './models/DataGrid.models';

const DefaultCell = defineAsyncComponent(() => import('@/components/Table/cells/DefaultCell.vue'));
export const NoDataCell = { component: DefaultCell, props: { value: NO_DATA }, value: NO_DATA };

/** @enum {string} */
export const DataType = {
  SEMANTIC_VERSION: 'column.appVersion',
  ROLE: 'column.role',
  TEAMS: 'column.teams',
  DEVICE_TEAMS: 'columnTripList.deviceTeam',
  TRIP_TEAMS: 'columnTripList.tripTeams',
};

export default {
  name: 'DataGridVuetify',

  components: {
    DataGridCell,
    DataGridColumnSelector,
    DataGridColumnFilter,
    DataGridFilterEraser,
    TableSearchBarV2,
    DataGridNoData,
  },

  props: {
    /** @type {import('vue').Prop<{[columnKey: string]: any}>} */
    buildCellInjectors: {
      default: () => ({}),
      type: Object,
    },

    /** @type {import('vue').Prop<{[key: string]: boolean}>} only used for trip-list case */
    merging: {
      default: () => ({}),
      type: Object,
    },
    /** @type {import('vue').Prop<DataGrid>} */
    datagrid: {
      required: true,
      type: DataGrid,
    },

    /** @type {import('vue').Prop<Array>} */
    data: {
      required: true,
      type: Array,
    },

    error: {
      default: null,
      type: String,
    },

    loading: {
      default: false,
      type: [Boolean, undefined],
    },

    height: {
      default: null,
      type: String,
    },

    /** @type {import('vue').Prop<Array<Tab>>} minimal definition to pre-filter data in tabs */
    tabs: {
      required: false,
      default: null,
      type: Array,
    },
    reloadOnRefresh: {
      required: false,
      default: true,
      type: Boolean,
    },
    title: {
      required: false,
      default: null,
      type: String,
    },
    isInEditionMode: {
      required: false,
      default: false,
      type: Boolean,
    },
    /** @type {import('vue').Prop<{[x: string]: any[]}>} pre-charged filters (used for "dynamic data" such as trip-list) */
    dataFilters: {
      required: false,
      type: Object,
      default: null,
    },
    lineIdInEdition: {
      required: false,
      type: String,
      default: null,
    },
    isAdminView: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:renderedDataLength', 'noDataActionTriggered'],

  data: () => ({
    StateMergingChildren,
    COLUMN_KEY_ACTION,
    NO_DATA,
    /** @type {{[columnKey: string]: import('./models/DataGrid.models').ColumnFilterState}} */
    columnFilters: {},
    activeTab: null,
    /** @type {boolean} */
    isSmallScreen: false,
    /** @type {Array<Tab>} */
    localTabs: [],
    /** @type {Array<string>} */
    searchBarResultIds: null,
    search: '',
    /** @type {Array<import('./models/DataGrid.models').SortBy>} */
    sortBy: [],
    selectedItems: [],
    /** @type {Array<import('./models/DataGrid.models').DataGridColumn>} */
    selectedColumns: [],
    isOverflowing: false,
    initColumns: true,
    openedFilters: {},
  }),

  computed: {
    /** @return {Array<Object>} */
    renderedData() {
      if (this.tabs && this.activeTab) {
        this.calculateTabsData();
        const selectedTab = this.localTabs.find(tab => tab.value === this.activeTab);
        return toRaw(selectedTab.dataListRendered);
      }
      return this.filterData(this.data);
    },
    /** @return {Array<import('./models/DataGrid.models').DataGridColumn>} */
    headers() {
      return this.datagrid.columns.filter(
        gridCol => !gridCol.isSelectable() || this.selectedColumns.find(sc => gridCol.isEqual(sc)),
      );
    },
    /** @return {string} */
    groupId() {
      return this.$store.getters.group._id;
    },
    isDatagridInLoadState() {
      return (this.reloadOnRefresh && this.loading) || (this.loading && this.data.length === 0);
    },

    // Get last column index depending on if has actionscolumn or not
    /** @return {number} */
    lastColumnIndex() {
      return this.datagrid.hasActions ? this.headers.length - 2 : this.headers.length - 1;
    },
  },

  watch: {
    tabs: {
      deep: true,
      immediate: true,
      handler() {
        if (this.tabs) {
          this.localTabs = this.tabs;
          this.activeTab = this.tabs.find(tab => tab.isDefaultActive)?.value || this.tabs[0]?.value;
        }
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.selectedItems = [];
        // We need immediate at true on some datagrid on date change, therefore we also need to check if the data has been loaded
        if (this.data?.length > 0 && Object.keys(this.columnFilters).length === 0) this.initFilters();
      },
    },
    isInEditionMode() {
      if (this.isInEditionMode) {
        this.removeFilters();
        this.sortBy = [];
      } else {
        this.sortBy = this.datagrid.getDefaultSortBy();
      }
    },
    // Used to display counter properly
    renderedData() {
      this.setColumnResizableFeature();
      this.$emit('update:renderedDataLength', this.renderedData.length);
    },
    columnFilters: {
      deep: true,
      handler() {
        this.goToTopListOnSearch();
      },
    },
    searchBarResultIds: {
      deep: true,
      handler() {
        this.goToTopListOnSearch();
      },
    },

    selectedColumns: {
      deep: true,
      handler() {
        this.adaptDatagridOverflow();
        if (!this.initColumns) this.setColumnResizableFeature(true);
        this.initColumns = false;
      },
    },
    isDatagridInLoadState() {
      if (!this.isDatagridInLoadState) {
        this.setColumnResizableFeature();
      }
    },
  },

  created() {
    this.sortBy = this.datagrid.getDefaultSortBy();
    this.selectedColumns = this.datagrid.getSelectedColumnsByDefault();
  },

  mounted() {
    this.adaptTopRowSize();
    window.addEventListener('resize', () => {
      this.adaptTopRowSize();
    });
    this.adaptDatagridOverflow();

    this.initBaseColumnSizes();
    if (this.isAdminView) this.setColumnResizableFeature(false, 0);
  },

  unmounted() {
    window.removeEventListener('resize', () => {
      this.adaptTopRowSize();
      this.adaptDatagridOverflow();
    });
  },

  methods: {
    /**
     * Compare two semantic version strings (e.g., '1.2.3') by breaking them into major, minor, and patch components.
     *
     * @param {string} a - The first semantic version to compare.
     * @param {string} b - The second semantic version to compare.
     * @returns {number} - Returns a negative number if `a` is less than `b`, a positive number if `a` is greater than `b`, and 0 if they are equal.
     *
     */
    compareSemVer(a, b) {
      const parseVersion = version => version.split('.').map(Number);
      const [aMajor, aMinor, aPatch] = parseVersion(a);
      const [bMajor, bMinor, bPatch] = parseVersion(b);

      if (aMajor !== bMajor) return aMajor - bMajor;
      if (aMinor !== bMinor) return aMinor - bMinor;
      return aPatch - bPatch;
    },
    /**
     * Set LocalTab based on datagrid data using calculateRenderedRows
     */
    calculateTabsData() {
      this.localTabs = this.localTabs.map(tab => {
        let dataRows = [...this.data];
        // Filter dataRows only if has filterValues
        if (tab.filterField && tab.filterValues?.length > 0) {
          // filter values based on field filtered & values required (from Tabs)
          dataRows = [...this.data].filter(item => {
            let arrayItem = [];
            if (item[tab.filterField] !== undefined && item[tab.filterField] !== null) {
              arrayItem = Array.isArray(item[tab.filterField])
                ? item[tab.filterField]
                : [item[tab.filterField]];
            }
            return tab.filterValues.some(value => arrayItem.includes(value));
          });
        }
        // use this filtered data list & calculate rendered rows based on filter/search/order from Datagrid
        tab.dataListRendered = this.filterData(dataRows);
        tab.counter = tab.dataListRendered?.length;
        return tab;
      });
    },
    /**
     * Filter datalist based on column filter & searchbar
     * @param {Array<object>} data
     * @return {Array<object>}
     */
    filterData(data) {
      let dataFiltered = data;
      // filter by column filter
      if (!this.isInEditionMode) {
        this.datagrid.getFilterableColumns().forEach(column => {
          if (this.columnFilters[column.key]?.isActive) {
            const activeValues = Object.keys(this.columnFilters[column.key].state).filter(key => {
              return this.columnFilters[column.key].state[key];
            });
            const isArray = dataFiltered[0] ? Array.isArray(dataFiltered[0][column.key]) : false;
            dataFiltered = dataFiltered.filter(data => {
              // handle array case
              if (isArray) {
                if (!data[column.key]) {
                  if (activeValues.includes(NO_DATA)) return true;
                  return false;
                }
                return (
                  activeValues.some(r => data[column.key]?.includes(r)) ||
                  activeValues.some(r => data[column.key].map(item => item.text).includes(r)) ||
                  (data[column.key]?.length === 0 && activeValues.includes(NO_DATA))
                );
              }
              // else any form of data
              return (
                activeValues.includes(data[column.key]) ||
                (!data[column.key] && activeValues.includes(NO_DATA))
              );
            });
          }
        });
        // filter by search
        if (this.searchBarResultIds)
          return dataFiltered.filter(item =>
            this.searchBarResultIds.includes(item[this.datagrid.getRowIdKey()]),
          );
      }
      return dataFiltered;
    },

    goToTopListOnSearch() {
      // bugfix : when filter is applied, v-data-table-virtual only display 1 element, so we simulate a scroll to fix this behavior.
      const scrollWrapper = document.getElementsByClassName('v-table__wrapper');
      if (scrollWrapper.length > 0) {
        scrollWrapper[0].scrollTo(0, 0);
      }
    },

    /**
     * Get custom data for a specific column
     * @param {string} columnType
     * @return {CustomFilterData | null}
     */
    getCustomFilter(columnType) {
      if (columnType === DataType.SEMANTIC_VERSION) {
        const customData = [...this.data].map(value => {
          return {
            version: value.app_version || NO_DATA,
            sortableAppVersion: value.sortableAppVersion || NO_DATA,
            versionStatus: value.app_version_states,
          };
        });
        return {
          dataType: DataType.SEMANTIC_VERSION,
          data: customData,
        };
      }
      if (columnType === DataType.ROLE) {
        return {
          dataType: DataType.ROLE,
          data: this.data,
        };
      }
      if ([DataType.TEAMS, DataType.DEVICE_TEAMS, DataType.TRIP_TEAMS].includes(columnType)) {
        const isUserListSpecificCase = this.datagrid.name === 'userListDatagrid';
        return {
          dataType: DataType.TEAMS,
          data: this.$store.getters.getAllowedTeamForUser(isUserListSpecificCase),
        };
      }
      return null;
    },

    /**
     * Get cellBuilder based on an item from datalist & column conf
     * @param {import('./models/DataGrid.models').DataGridColumn} column
     * @param {any} itemObject
     * @return {import('./models/DataGrid.models').DataGridDataCellDef}
     */
    getDataCellBuilder(column, itemObject) {
      const columnName = column.key;

      // Api fields values
      // add value & object passed default
      const value = column.value ? column.value(itemObject) : null;

      // Useful extra data to pass to the cellBuilder function
      const extra = this.buildCellInjectors[columnName]
        ? this.buildCellInjectors[columnName]({ column, apiData: itemObject }) // paser l'objet, et la colonne?
        : {};

      return column.cellBuilder([value, itemObject], extra) || NoDataCell;
    },

    /**
     * Iterate through columns, take only filterables ones, based on filterable property, create a list of possible values
     * if no value, use '-' as value, sort this list by ASC then populate object "columnFilters"
     */
    initFilters() {
      // iterate through columns
      this.datagrid.columns
        .filter(column => column.filterable)
        .forEach(column => {
          let list = [];
          // Case pre-charged filters
          if (this.dataFilters && this.dataFilters[column.key]) {
            list = this.dataFilters[column.key];
          } else {
            // case default value as filter
            const data = {};

            const filterableObjectFactory = value => {
              let obj;

              if (typeof column.filterable === 'function') {
                obj = column.filterable(value);
              } else {
                obj = { value };
              }

              if (!obj.label) {
                obj.label = value ?? NO_DATA;
              }

              return obj;
            };

            this.data.forEach(d => {
              const value = column.value(d) ?? NO_DATA;
              if (Array.isArray(value)) {
                value.forEach(item => {
                  data[item] = filterableObjectFactory(item);
                });
              } else {
                data[value] = filterableObjectFactory(value);
              }
            });

            list = Object.values(data).sort(({ label: a }, { label: b }) => {
              if (isNumeric(a) && isNumeric(b)) {
                return parseFloat(a) - parseFloat(b);
              }
              if (typeof a === 'string') {
                return a.localeCompare(b);
              }
              if (typeof b === 'string') {
                return b.localeCompare(a);
              }
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            });
          }

          const state = list.reduce((acc, { value }) => {
            acc[value] = true;
            return acc;
          }, {});

          this.columnFilters[column.key] = {
            isActive: false,
            i18nPrefix: column.getI18nPrefix(),
            groupId: this.groupId,
            columnName: column.title,
            list,
            state,
          };
        });
    },

    /**
     * Handle checkbox column size since it has some limit case with table-layout fixed
     */
    async adaptDatagridOverflow() {
      const tableWrapper = document.getElementsByClassName('v-table__wrapper')?.[0];
      await this.$nextTick();
      if (tableWrapper) {
        // default size is 150px if overflowing, so calculate if overflow is needed based on number of columns
        const shouldOverflow = tableWrapper.offsetWidth - 150 * this.headers.length < 0;
        this.isOverflowing = shouldOverflow;
      }
    },

    /**
     * @param {import('./models/DataGrid.models').DataGridColumn} column
     * @param {boolean} check - State to put checkbox into.
     */
    toggleAllFilters(column, check) {
      Object.keys(this.columnFilters[column.key].state).forEach(key => {
        this.columnFilters[column.key].state[key] = check;
      });
    },

    /**
     * delete a filter, or every filter if no filter passed in param
     * @param {import('@/components/Table/DataGridVuetify/models/DataGrid.models').ColumnFilterState} filter
     */
    removeFilters(filter = null) {
      // get All filterable columns
      let columnsToReset = this.datagrid.getColumns().filter(column => column.filterable);
      // If remove only 1 filter, update list to reset
      if (filter) {
        columnsToReset = columnsToReset.filter(gridCol => filter.columnName === gridCol.title);
      }
      columnsToReset.forEach(column => this.toggleAllFilters(column, true));
    },

    /**
     * Recreate an array of data from dataGridData with formated values defined in conf.js in order to export data afterward
     * @return {?ExportDataObject} an object containing an array of data and localKeys for translations
     */
    exportData() {
      /** @type {ExportDataObject} */
      const exportData = {
        localKeys: [],
        translatedKeys: [],
        data: [],
      };
      // Get every type of data (cells) except action
      this.datagrid.columns.forEach(column => {
        if (column.key !== COLUMN_KEY_ACTION) {
          exportData.translatedKeys.push(this.$t(column.title));
          exportData.localKeys.push(column.key);
        }
      });

      // create new object with names & add to new list
      this.data?.forEach(dataRow => {
        const newObject = {};
        exportData.localKeys.forEach(key => {
          newObject[key] = dataRow[key];
        });
        exportData.data.push(newObject);
      });
      return exportData;
    },

    adaptTopRowSize() {
      this.isSmallScreen = this.$refs.topRowRight?.clientWidth < 450 ? true : false;
    },

    /**
     *
     * @param {string} columnKey
     */
    getColumnTypeClass(columnKey) {
      return {
        'action-column': columnKey === COLUMN_KEY_ACTION,
        'column-sticky': columnKey === this.headers[0].key && this.datagrid.hasFirstRowSticky,
        'last-column': columnKey === this.headers[this.lastColumnIndex].key,
        'selector-column': columnKey === 'role' || columnKey === 'teams',
      };
    },

    /**
     * Set column resize controls
     * @param {boolean} resetSize
     * @param {?number} timeout - used to have actualization after loading of data  to avoid wrong resize controller height
     */
    setColumnResizableFeature(resetSize, timeout = 500) {
      setTimeout(() => {
        var tables = document.getElementsByTagName('table');
        for (var i = 0; i < tables.length; i++) {
          DatagridColumnResizer.addResizeControls(tables[i], this.datagrid.name, resetSize);
        }
      }, timeout);
    },
    initBaseColumnSizes() {
      var tables = document.getElementsByTagName('table');
      for (var i = 0; i < tables.length; i++) {
        DatagridColumnResizer.setInitColsSize(tables[i], this.datagrid.name, false);
      }
    },
  },
};

/**
 * @typedef {Object} CustomFilterData
 * @property {DataType} dataType
 * @property {Array<any>} data
 */

/**
 * @typedef {Object} ExportDataObject
 * @property {Array<Object>} data
 * @property {Array<string>} localKeys
 * @property {Array<string>} translatedKeys
 */

/**
 * @typedef {Object} Tab
 * @property {string} value
 * @property {string} name
 * @property {string} [icon]
 * @property {number} [counter]
 * @property {string} filterField
 * @property {Array<string> | Array<boolean>} [filterValues]
 * @property {Array<object>} [dataListRendered]
 * @property {boolean} isDefaultActive
 */
</script>

<i18n locale="fr">
{
  "merged": "Regroupés",
  "resultCount": "Résultat: {count} ligne | Résultat: {count} lignes",
  "selectedItems": "{count} élément sélectionné | {count} éléments sélectionnés",
  "noData": "Aucune donnée disponible",
  "noDataWithFilters": "Aucune donnée disponible, veuillez modifier vos critères de recherche"
}
</i18n>

<i18n locale="en">
{
  "merged": "Merged",
  "resultCount": "Result: {count} row | Result: {count} rows",
  "selectedItems": "{count} selected item | {count} selected items",
  "noData": "No data available",
  "noDataWithFilters": "No data available, please change your search criteria"

}
</i18n>
