<script setup lang="ts">
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { onBeforeMount, ref } from 'vue';

defineEmits(['close', 'submit']);

const props = defineProps({
  comment: {
    type: String,
    default: () => null,
  },
  titleName: {
    type: String,
    required: true,
  },
});

const localComment = ref<string>('');
const maxLength: number = 200;

onBeforeMount(() => {
  if (props.comment) {
    // To fix drivers and data coming from V2 version
    if (props.comment === 'None') localComment.value = '';
    else localComment.value = props.comment;
  }
});
</script>

<template>
  <Modal modal-class="modal-comment" @close="$emit('close')">
    <template #title>
      {{ $t('comment', [titleName]) }}
    </template>

    <template #body>
      <div>
        <div class="modal-comment__textarea">
          <textarea
            v-model="localComment"
            class="form-group__input form-group__input--area"
            :rows="6"
            :maxlength="maxLength"
          />
          <div class="modal-comment__textarea__counter">{{ localComment.length }}/{{ maxLength }}</div>
        </div>
      </div>
      <div class="modal-comment__extra-input">
        <slot name="extra-input" />
      </div>
    </template>

    <template #cta>
      <Btn type="primary" @click="$emit('submit', localComment.trim())">
        {{ $t('save') }}
      </Btn>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-comment {
  &__textarea {
    position: relative;

    textarea {
      resize: none;
    }

    &__counter {
      position: absolute;
      right: 5px;
      bottom: 3px;
      color: $text-neutral;
      font-size: 10px;
    }
  }

  &__extra-input {
    margin-top: 10px;
  }
}
</style>

<i18n locale="fr">
{
  "comment": "Commentaire : {0}"
}
</i18n>

<i18n locale="en">
{
  "comment": "Comment: {0}"
}
</i18n>

<i18n locale="cz">
{
  "comment": "Komentář: {0}"
}
</i18n>

<i18n locale="de">
{
  "comment": "Kommentar: {0}"
}
</i18n>

<i18n locale="es">
{
  "comment": "Comentario: {0}"
}
</i18n>

<i18n locale="it">
{
  "comment": "Commento: {0}"
}
</i18n>

<i18n locale="pl">
{
  "comment": "Komentarz: {0}"
}
</i18n>
