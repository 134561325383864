<script setup lang="ts">
import { computed, ref, useTemplateRef } from 'vue';

import CardHeader from './CardHeader.vue';
import CardOverlay from './CardOverlay.vue';
import Map from '@/pages/LiveMapPage/index.vue';
import type { VCard } from 'vuetify/components';

const props = defineProps({
  hasGtfs: {
    type: Boolean,
    default: true,
  },
  loading: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    required: true,
  },
});

defineEmits(['redirect']);

const mapCard = useTemplateRef<VCard>('mapCard');
const mapCardHeader = ref<InstanceType<typeof CardHeader>>();

const mapHeight = computed<number | null>(() => {
  if (!mapCard.value || !mapCardHeader.value) return null;
  return mapCard.value?.$el.offsetHeight - mapCardHeader.value.$el.offsetHeight;
});
</script>

<template>
  <v-card ref="mapCard" class="map-card" elevation="0">
    <CardHeader
      ref="mapCardHeader"
      :title="props.title"
      show-status-indicator
      :is-active="props.hasGtfs"
      @click="$emit('redirect')"
    />
    <v-skeleton-loader v-if="props.loading" type="image" :height="mapHeight" />
    <Map v-else-if="props.hasGtfs" :minimal-version="true" :map-container-height="mapHeight + 'px'" />
    <CardOverlay v-else :height="mapHeight" image="map-placeholder" />
  </v-card>
</template>

<style lang="scss">
.map-card {
  height: 100%;
  background-color: $canvas;

  &__text {
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-size: 18px;
    text-align: center;
  }

  #mapdiv {
    border: none;
  }
}
</style>
