<script setup lang="ts">
import type { ApexOptions } from 'apexcharts';
import deepmerge from 'deepmerge';
import { computed, type PropType } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

interface BarSerie {
  data: Array<number>;
  name?: string;
  color?: string;
}

const props = defineProps({
  categories: {
    type: Array<Number>,
    required: true,
  },

  percentage: {
    type: Boolean,
    default: false,
  },

  series: {
    type: Array as PropType<Array<BarSerie>>,
    required: true,
  },

  title: {
    type: String,
    default: '',
  },
});

const options = computed<ApexOptions>(() => {
  let options = {
    chart: {
      toolbar: { show: false },
    },

    colors:
      props.series.length === 1
        ? ['#5279a0', '#5279a0', '#5279a0', '#5279a0', '#5279a0', '#5279a0', '#4f4f4f']
        : props.series.map(s => s.color),

    legend: { show: false },

    title: {
      align: 'center',
      margin: 0,
      text: props.title,
    } as ApexTitleSubtitle,

    xaxis: {
      categories: props.categories,
      type: 'category',
    } as ApexXAxis,
  };

  if (props.series.length > 1) {
    options = deepmerge(options, {
      chart: {
        stacked: true,
        stackType: '100%',
      },
      dataLabels: { enabled: false },
      yaxis: {
        max: 100,
      },
    });
  } else {
    Object.assign(options, {
      plotOptions: {
        bar: {
          dataLabels: { position: 'top' },
          distributed: true,
        },
      },
      tooltip: { enabled: false },
    });
  }

  if (props.percentage) {
    options = deepmerge(options, {
      dataLabels: { formatter: (v: number) => `${v}%` },
      yaxis: {
        max: 100,
      },
    });
  }

  return options;
});
</script>

<template>
  <VueApexCharts type="bar" :options="options" :series="series" />
</template>

<style lang="scss">
.apexcharts-tooltip-text {
  font-family: $font-poppins !important;
}

.apexcharts-tooltip-title {
  font-family: $font-poppins !important;
}
</style>
