<template>
  <div ref="dropdown" class="checkbox-menu">
    <!-- Toggle menu -->
    <div class="checkbox-menu__togglemenu">
      <button
        :aria-expanded="opened ? 'true' : 'false'"
        aria-haspopup="true"
        class="checkbox-menu__button"
        @click="dropdownToggle"
      >
        <font-awesome-icon :icon="filterIcon ? 'fa-filter' : 'fa-layer-group'" />
      </button>
    </div>

    <ul v-if="opened" class="checkbox-menu__popup">
      <!-- Check/uncheck all -->
      <li class="checkbox-menu__item checkbox-menu__item--toggle-all">
        <v-checkbox
          v-model="toggleAll"
          :indeterminate="
            !modelValue.every(option => option.selected) && modelValue.some(option => option.selected)
          "
          color="success"
          hide-details
        >
          <template #label>
            {{ $t('checkAll') }}
          </template>
        </v-checkbox>
      </li>
      <!-- List items -->
      <li v-for="(option, index) in modelValue" :key="index" class="checkbox-menu__item">
        <v-checkbox v-model="option.selected" color="success" hide-details>
          <template #label>
            {{ option.label }}
          </template>
        </v-checkbox>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DropdownCheckbox',
  props: {
    /** @type {import('vue').Prop<Array<CheckboxOption>>} */
    modelValue: {
      type: Array,
      required: true,
    },
    filterIcon: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],
  data: () => ({
    /** @type {boolean} */
    opened: false,
  }),

  computed: {
    toggleAll: {
      /** @returns {boolean} */
      get() {
        // For partial selection
        if (
          !this.modelValue.every(option => option.selected) &&
          this.modelValue.some(option => option.selected)
        ) {
          return true;
        }
        return this.modelValue.every(option => option.selected);
      },
      /** @param {boolean} value */
      set(value) {
        // For partial selection
        if (
          !this.modelValue.every(option => option.selected) &&
          this.modelValue.some(option => option.selected)
        ) {
          this.modelValue.forEach(option => {
            option.selected = false;
          });
        } else {
          this.modelValue.forEach(option => {
            option.selected = value;
          });
        }
      },
    },
  },

  methods: {
    /**
     * Close dropdown.
     */
    dropdownClose(event) {
      // Prevent closing the dropdown when the click
      // comes from a child element, except the button.
      const findParent = elem => {
        let found = false;
        while (!found && elem) {
          found = elem.parentElement === this.$refs.dropdown;
          // eslint-disable-next-line no-param-reassign
          elem = elem.parentElement;
        }
        return found;
      };
      if (event && event.target && findParent(event.target)) return;
      this.opened = false;
      window.removeEventListener('click', this.dropdownClose);
    },

    /**
     * Open dropdown.
     */
    dropdownOpen() {
      this.opened = true;
      window.removeEventListener('click', this.dropdownClose);
      setTimeout(() => window.addEventListener('click', this.dropdownClose), 10);
    },

    /**
     * Toggle dropdown.
     */
    dropdownToggle() {
      if (!this.opened) {
        this.dropdownOpen();
      } else {
        this.dropdownClose();
      }
    },
  },
};

/**
 * @typedef {Object} CheckboxOption
 * @property {string} label
 * @property {any} value
 * @property {boolean} selected
 */
</script>

<style lang="scss">
.checkbox-menu {
  &__button {
    padding: 5px;
    border: 1px solid $border-variant;
    border-radius: 5px;
    background-color: $canvas;
    line-height: 14px;
    cursor: pointer;

    svg {
      color: $text-dark-variant;
      font-size: 15px;
    }
  }

  &__popup {
    position: absolute;
    top: 115%;
    left: 0;
    overflow: auto;
    max-height: 50vh;
    margin: 0;
    padding: 0 5px;
    border: 1px solid $border-variant;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  }

  &__item {
    margin: 8px 5px;

    &--toggle-all {
      padding-bottom: 5px;
      border-bottom: 1px solid $border;
    }
  }

  .v-label {
    font-size: 12px;
  }
}
</style>

<i18n locale="fr">
  {
    "checkAll": "Tout cocher",
  }
</i18n>

<i18n locale="en">
  {
    "checkAll": "Check all",
  }
</i18n>
