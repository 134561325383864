<script setup lang="ts">
import { DelayState } from '@/store/devices';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const DELAY_CLASS: { [stateKey: DelayState]: string } = {
  [DelayState.EARLY]: 'too-early',
  [DelayState.LATE]: 'too-late',
  [DelayState.ON_TIME]: 'on-time',
};

const store = useStore();
const { t } = useI18n();

const props = defineProps({
  //delay in seconds
  delay: {
    type: Number,
    default: () => null,
  },
});

const delayClass = computed<string>(() => {
  if (props.delay == null) return '';
  const delayState = store.getters['devices/getDelayState'](props.delay) as DelayState;
  return `device-delay--${DELAY_CLASS[delayState]}`;
});

const formattedDelay = computed<string>(() => {
  if (props.delay == null) return '-';

  const sign = props.delay >= 0 ? '+' : '-';
  const min = Math.floor(Math.abs(props.delay) / 60);

  return t('delayMn', [sign + min]);
});
</script>

<template>
  <span class="device-delay" :class="delayClass">
    {{ formattedDelay }}
  </span>
</template>

<style lang="scss">
.device-delay {
  &--too-early {
    color: $danger;
  }

  &--too-late {
    color: $warn;
  }

  &--on-time {
    color: $primary-light;
  }
}
</style>

<i18n locale="fr">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="en">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="es">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="de">
{
  "delayMn": "{0} Min."
}
</i18n>

<i18n locale="cz">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="pl">
{
  "delayMn": "{0} min"
}
</i18n>

<i18n locale="it">
{
  "delayMn": "{0} min"
}
</i18n>
