<script setup lang="ts">
import i18n from '@/i18n';
import type { ApexOptions } from 'apexcharts';
import deepmerge from 'deepmerge';
import { computed, onMounted, ref, watch, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import VueApexCharts from 'vue3-apexcharts';

const { t } = useI18n();

export interface ApexChartSerie {
  data: Array<number>;
  color: (data: { value: number; seriesIndex: number; dataPointIndex: number }) => string;
}

interface ApexOptionsExtended extends ApexOptions {
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  barsPerScreen: number;
  page: number;
}

const props = defineProps({
  options: {
    type: Object as PropType<ApexOptions>,
    default: () => ({}),
  },
  barsPerScreen: {
    type: Number,
    default: 15,
  },
  series: {
    type: Array as PropType<Array<ApexChartSerie>>,
    required: true,
  },
  categories: {
    type: Array<Number>,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  yaxisTitle: {
    type: String,
    default: '',
  },
  width: {
    type: [Number, String],
    default: '100%',
  },
  height: {
    type: [Number, String],
    default: 'auto',
  },
});

const page = ref<number>(0);
const lastPage = ref<number>(Math.ceil(props.categories.length / 15));

const localOptions = ref<ApexOptions>({
  chart: {
    type: 'bar',
    offsetX: -25,
    stacked: true,
    toolbar: { show: false },
    animations: { enabled: false },
  },
  noData: { text: '' },
  fill: { opacity: 1 },
  legend: { show: false },
  xaxis: {
    labels: {
      show: true,
      rotateAlways: true,
      style: {
        color: '#333333', // $text-dark
        fontFamily: "'Poppins', Arial, sans-serif", // $font-poppins
        fontSize: '13px',
        fontWeight: 500,
      },
    },
    axisBorder: {
      width: '120%',
      offsetX: -17,
      offsetY: 1,
    },
    axisTicks: { show: false },
  } as ApexXAxis,
  yaxis: {
    axisBorder: {
      show: true,
      offsetX: -18,
    },
    labels: { show: false },
    forceNiceScale: false,
  },
  states: {
    hover: {
      filter: { type: 'none' },
    },
    active: {
      filter: { type: 'none' },
    },
  },
  grid: {
    yaxis: {
      lines: { show: false },
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 8,
      dataLabels: {
        position: 'center',
        hideOverflowingLabels: true,
      },
    },
  },
  dataLabels: {
    style: {
      fontSize: '9px',
      fontFamily: "'Poppins', Arial, sans-serif", // $font-poppins
      fontWeight: '900',
    },
  },
  tooltip: { followCursor: false },
});

const mergedOptions = computed<ApexOptions>(() => {
  const startElement = page.value * props.barsPerScreen;
  const categories = props.categories.slice(startElement, startElement + props.barsPerScreen);

  // "deepmerge" is rightsided
  const options = deepmerge(localOptions.value, props.options) as ApexOptionsExtended;
  options.xaxis!.categories = categories;

  // if no yaxis.max defined by options, calculate for data scope
  if (!options.yaxis?.max) {
    // calculate max value for set of data displayed
    const maxList: Array<number> = [];
    drawnData.value.forEach(dataList => {
      dataList.data.forEach((value, index) => {
        if (!maxList[index]) maxList[index] = 0;
        maxList[index] += value;
      });
    });
    // set the max yaxis
    options.yaxis.max = Math.max(...maxList);
  }

  // Charts on dashboard define their own columnWidth option
  if (!props.options.plotOptions?.bar?.columnWidth) {
    options!.plotOptions!.bar!.columnWidth = `${categories.length * 4.6}%`;
  }

  // "page" and "barsPerScreen" must be accessible in parent component
  // especially for tooltip.
  options.page = page.value;
  options.barsPerScreen = props.barsPerScreen;

  return options;
});
const drawnData = computed<Array<ApexChartSerie>>(() => {
  const startElement = page.value * props.barsPerScreen;
  const series = props.series.map(ele => ({
    ...ele,
    data: ele.data.slice(startElement, startElement + props.barsPerScreen),
  }));

  return series;
});

watch(
  () => i18n.global.locale,
  () => {
    setLoadingTranslation();
  },
);

onMounted(() => {
  setLoadingTranslation();
});

function setLoadingTranslation() {
  if (localOptions.value.noData) localOptions.value.noData.text = t('loading');
}
</script>

<template>
  <div class="barchart">
    <div v-if="title" class="barchart__title">
      {{ title }}
    </div>
    <div class="barchart__apexchart">
      <VueApexCharts
        ref="apexchart"
        :height="height"
        :width="width"
        :options="mergedOptions"
        :series="drawnData"
      />
      <div v-if="yaxisTitle" class="barchart__yaxis-title">
        {{ yaxisTitle }}
      </div>
      <div class="barchart__nav-block">
        <button
          class="barchart__nav-button"
          :class="{ 'barchart__nav-button--hidden': page === 0 }"
          @click="page -= 1"
        >
          <font-awesome-icon icon="fa-chevron-left" />
        </button>
        <button
          class="barchart__nav-button"
          :class="{ 'barchart__nav-button--hidden': page + 1 === lastPage }"
          @click="page += 1"
        >
          <font-awesome-icon icon="fa-chevron-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.barchart {
  padding: 0 20px;
  border-radius: 9px;
  background: $background;

  &__title {
    height: 80px;
    padding: 20px 0 0 50px;
    font-weight: $font-weight-semi-bold;
    font-size: 21px;
    font-family: $font-poppins;
  }

  &__apexchart {
    position: relative;

    .apexcharts-canvas {
      margin: auto;
    }

    .apexcharts-tooltip {
      max-width: 250px;
      margin-left: -60px;
      color: $text-dark;
      font-size: 10px;
    }

    .apexcharts-bar-area {
      fill-opacity: 1;
    }
  }

  &__nav-block {
    position: absolute;
    top: 43%;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__nav-button {
    display: block;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 45%;
    background: $canvas;
    color: $primary-light;

    &:hover {
      background: $text-neutral;
    }

    &--hidden {
      visibility: hidden;
    }
  }

  &__yaxis-title {
    position: absolute;
    top: 5px;
    left: 3px;
    color: $text-neutral;
    font-weight: $font-weight-semi-bold;
    font-size: 9px;
    text-transform: uppercase;
  }
}
</style>

<i18n locale="fr">
{
  "loading": "Chargement des données..."
}
</i18n>

<i18n locale="en">
{
  "loading": "Loading..."
}
</i18n>
