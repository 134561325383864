<script setup lang="ts">
import OptionMenuDropdown from '@/components/layout/NavBarTop/OptionMenuDropdown.vue';
import SearchBox from '@/components/common/SearchBox.vue';
import Breadcrumbs from '@/components/layout/NavBarTop/Breadcrumbs.vue';
import HeaderMobile from './HeaderMobile.vue';
import Language from '@/components/layout/NavBarTop/Language.vue';
import SelectGroup from '@/components/layout/NavBarTop/SelectGroup.vue';
import { Permission } from '@/auth';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const isMobileScreen = computed<boolean>(() => store.state.userInterface.isMobileScreen);
const isMobileMenuOpen = computed<boolean>(() => store.state.userInterface.isMobileMenuOpen);
const hasSearchPermission = computed<boolean>(() => store.getters.hasPermission(Permission.RESEARCH));
</script>
<template>
  <div class="navbar" :class="{ 'navbar--mobile-menu': isMobileMenuOpen }">
    <div class="navbar__left">
      <SelectGroup v-if="!isMobileScreen" />
      <HeaderMobile v-if="isMobileScreen" />
      <Transition name="slide">
        <Breadcrumbs v-if="!isMobileMenuOpen" />
      </Transition>
    </div>
    <div v-if="!isMobileScreen" class="navbar__right">
      <SearchBox v-if="hasSearchPermission" />
      <Language />
      <v-divider class="navbar__divider" vertical></v-divider>
      <OptionMenuDropdown />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navbar {
  $navbar-content-height: 36px;

  @include small-screen {
    height: $navbar-top-mobile;
    padding: 0;
    background-color: $white;

    &--mobile-menu {
      height: $navbar-top;
    }
  }

  display: flex;
  gap: 12px;
  height: $navbar-top;
  padding: 16px;
  border-bottom: 1px solid $light-border;
  background-color: $canvas;

  &__divider {
    height: 24px;
  }

  &__left {
    @include small-screen {
      position: relative;
      flex-direction: column;
      width: 100%;
      height: $navbar-top-mobile;

      // display gradient on the right side of the breadcrumbs
      &::after {
        content: '';
        position: absolute;
        top: calc($header-mobile + 4px);
        right: 0;
        width: 25px;
        height: 17px;
        background: linear-gradient(to left, rgb(255 255 255 / 100%), rgb(255 255 255 / 0%));
        pointer-events: none;
      }
    }

    display: flex;
    flex-grow: 2;
    height: $navbar-content-height;
  }

  &__right {
    display: flex;
    gap: 8px;
    height: $navbar-content-height;
  }
}
</style>
