<script setup lang="ts">
import { ref } from 'vue';
import Modal from '@/components/layout/Modal.vue';
import Btn from '@/components/ui/Btn.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['close', 'submit']);

const localComment = ref<string>('');

const MAX_LENGTH = 200;
</script>

<template>
  <Modal modal-class="modal-resolve-urgency" @close="emit('close')">
    <template #title>
      {{ $t('confirmResolution') }}
    </template>

    <template #body>
      <div>
        <div class="modal-resolve-urgency__textarea">
          <textarea
            v-model="localComment"
            class="form-group__input form-group__input--area"
            :rows="6"
            :maxlength="MAX_LENGTH"
            :placeholder="t('yourComment')"
          />
          <div class="modal-resolve-urgency__textarea__counter">
            {{ localComment.length }}/{{ MAX_LENGTH }}
          </div>
        </div>
      </div>
      <div class="modal-resolve-urgency__extra-input">
        <slot name="extra-input" />
      </div>
    </template>

    <template #cta>
      <Btn type="primary" @click="emit('submit', localComment.trim())">
        {{ t('resolveAlert') }}
      </Btn>
    </template>
  </Modal>
</template>

<style lang="scss">
.modal-resolve-urgency {
  &__textarea {
    position: relative;

    textarea {
      resize: none;
    }

    &__counter {
      position: absolute;
      right: 5px;
      bottom: 3px;
      color: $text-neutral;
      font-size: 10px;
    }
  }

  &__extra-input {
    margin-top: 10px;
  }
}
</style>

<i18n locale="fr">
{
  "confirmResolution": "Confirmer la résolution de l'alerte ?",
  "falseAlert": "Fausse alerte",
  "resolveAlert": "Résoudre l'alerte",
  "comment": "Commentaire",
  "yourComment": "Votre commentaire"
}
</i18n>

<i18n locale="en">
{
  "confirmResolution": "Confirm alert resolution?",
  "falseAlert": "False alert",
  "resolveAlert": "Resolve alert",
  "comment": "Comment",
  "yourComment": "Your comment"
}
</i18n>
