export interface Service {
  service_id: string;
  start_date: string;
  end_date: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

/**
 * Enum for service exception types.
 */
export enum ServiceExceptionType {
  ADD = 1,
  REMOVE = 2,
}

export enum LocationType {
  STOP = '0',
  STATION = '1',
}

export interface ServiceException {
  _id: string;
  service_id: string;
  exception_type: ServiceExceptionType;
  date: string;
}

export interface Stop {
  stop_id: string;
  stop_lat: number;
  stop_lon: number;
  stop_name: string;
  stop_code?: string;
  location_type?: LocationType;
  parent_station?: string;
}

export interface StopTime {
  stop_id: string;
  stop_sequence: number;
  arrival_time: number;
  departure_time: number;
  pickup_type?: number;
  shape_dist_traveled?: number;
}

export interface Trip {
  trip_id: string;
  route_id: string;
  service_id: string;
  stop_times: Array<StopTime>;
  block_id?: string;
  direction_id?: number;
  shape_id: string;
  team_id?: string;
  trip_headsign?: string;
  trip_short_name?: string;
}

export interface Route {
  route_id: string;
  route_long_name: string;
  route_short_name: string;
  route_type: number;
  agency_id?: string;
  route_color?: string;
  route_text_color?: string;
  is_deactivated?: boolean;
  is_private?: boolean;
}

export interface Shape {
  shape_id: string;
  geometry: GeoJSON.LineString;
  routes: Array<string>;
  source: string;
}

export interface GtfsInfo {
  _id: string;
  group_id?: string;
  name: string;
  mod_user: string;
  mod_time: string; // Date in ISO format
}

export interface GtfsSchedule extends GtfsInfo {
  archived: number;
  published: number;
  scheduled: number;
  blocked?: boolean; // deprecated
}

export interface Publication {
  current_file: string; // Gtfs id
  ts: number; // Timestamp in seconds
  type: string;
  user_id: string;
}
