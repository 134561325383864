<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRouter, type RouteRecordName } from 'vue-router';
import { useStore } from 'vuex';
import { GroupRoute } from '@/libs/routing';
import type { Group } from '@/@types/group';

import Btn from '@/components/ui/Btn.vue';

const store = useStore();
const { t } = useI18n();
const router = useRouter();

// TODO temporary - until the limit is set for each group in admin
const maxStorage = 15;

const menuIcons = new Map([
  [GroupRoute.SETTINGS_GENERAL, 'toolbox'],
  [GroupRoute.SETTINGS_DRIVER, 'mobile'],
  [GroupRoute.SETTINGS_TEAMS, 'users'],
  [GroupRoute.SETTINGS_PASSENGER_COUNT, 'walking'],
  [GroupRoute.SETTINGS_PUNCTUALITY_PARAMS, 'chart-line'],
  [GroupRoute.SETTINGS_STOP_ZONES, 'map-marker-alt'],
  [GroupRoute.SETTINGS_DEPOTS, 'warehouse'],
  [GroupRoute.SETTINGS_ROUTE_MANAGEMENT, 'stream'],
  [GroupRoute.SETTINGS_TRIP_MANAGEMENT, 'bus'],
  [GroupRoute.SETTINGS_DATA_PERIOD, 'database'],
]);

const changed = ref<boolean>(false);

const currentRoute = computed<RouteRecordName | null | undefined>(() => {
  return router.currentRoute.value.name;
});

const group = computed<Group>(() => store.getters.group);
const memoryUsed = ref<number>();

const memoryUsedPercentage = computed<number>(() => {
  return memoryUsed.value ? (memoryUsed.value / 15) * 100 : 0;
});

const isStorageFull = computed<boolean>(() => {
  return memoryUsed.value !== undefined && memoryUsed.value >= maxStorage;
});

const progressBarColor = computed<string>(() => {
  return isStorageFull.value ? '#EB5757' : '#00b871'; // $primary-dark $danger
});
const progressBarBgColor = '#b3b3b3'; // $text-neutral

onMounted(() => {
  memoryUsed.value = Math.round((group.value.memory_use / Math.pow(10, 9)) * 100) / 100;
});

onBeforeRouteLeave(() => {
  if (
    changed.value &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_TEAMS &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_DEPOTS &&
    !window.confirm(t('unsavedChanges'))
  ) {
    return false;
  }
});

function onChangeTab(newTab: GroupRoute) {
  if (
    changed.value &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_TEAMS &&
    router.currentRoute.value.name !== GroupRoute.SETTINGS_DEPOTS &&
    !window.confirm(t('unsavedChanges'))
  )
    return;

  changed.value = false;
  router.replace({ name: newTab });
}

function updateChangeValue(value: boolean) {
  changed.value = value;
}
</script>

<template>
  <div class="settings">
    <div class="settings__menu">
      <div class="settings__menu-btns">
        <Btn
          v-for="btn in [
            GroupRoute.SETTINGS_GENERAL,
            GroupRoute.SETTINGS_DRIVER,
            GroupRoute.SETTINGS_TEAMS,
            GroupRoute.SETTINGS_PASSENGER_COUNT,
            GroupRoute.SETTINGS_PUNCTUALITY_PARAMS,
            GroupRoute.SETTINGS_STOP_ZONES,
            GroupRoute.SETTINGS_DEPOTS,
            GroupRoute.SETTINGS_ROUTE_MANAGEMENT,
            GroupRoute.SETTINGS_TRIP_MANAGEMENT,
            GroupRoute.SETTINGS_DATA_PERIOD,
          ]"
          :key="btn"
          :class="{ active: currentRoute === btn }"
          type="link-style"
          @click="onChangeTab(btn)"
          @mousedown.prevent
        >
          <font-awesome-icon :icon="menuIcons.get(btn)" />
          {{ $t(`menu.${btn}`) }}
        </Btn>
      </div>

      <div class="settings__data-storage" :class="{ danger: isStorageFull }">
        <!-- progress bar -->
        <div
          class="progress-bar"
          :style="{
            '--perc': `${memoryUsedPercentage}%`,
            '--color': `${progressBarColor}`,
            '--bg-color': `${progressBarBgColor}50`,
          }"
        />
        <div class="settings__memory-used">
          <font-awesome-icon v-if="isStorageFull" icon="fa-exclamation-circle" />
          {{ memoryUsed }} Go / {{ maxStorage }} Go
        </div>
        <Btn type="secondary" class="settings__memory-used-btn" link-url="https://web.pysae.com/tarifs-pysae">
          <font-awesome-icon icon="fa-circle-arrow-up" />
          {{ t('upgrade') }}
        </Btn>
      </div>
    </div>

    <div class="settings__content">
      <router-view @change="updateChangeValue" @save="changed = false" />
    </div>
  </div>
</template>

<style lang="scss">
$data-storage-height: 100px;

.settings {
  display: flex;

  &__content {
    flex: 1;
    overflow-y: auto;
    max-height: calc(100vh - $navbar-top);
    padding: $view-standard-padding;
  }

  &__data-storage {
    height: $data-storage-height;
    padding: $view-standard-padding;
    border-top: solid 1px $light-border;

    &.danger {
      background-color: $transparent-danger;
      color: $danger;
    }

    > .ui-btn {
      width: 100%;

      svg {
        margin-right: 8px;
      }
    }
  }

  &__menu {
    @include small-screen {
      min-height: calc(100vh - $navbar-top-mobile);
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: calc(100vh - $navbar-top);
    border-right: 1px solid $light-border;
  }

  &__menu-btns {
    @include small-screen {
      max-height: calc(100vh - $navbar-top-mobile - $data-storage-height);
    }

    overflow-y: auto;
    max-height: calc(100vh - $navbar-top - $data-storage-height);
    font-weight: $font-weight-semi-bold;
    scrollbar-width: none;

    .ui-btn + .ui-btn {
      margin-left: 0;
    }

    > .ui-btn {
      justify-content: left;
      width: 100%;
      height: 56px;
      border-bottom: 1px solid $light-border;
      border-radius: 0;
      color: $text-dark-variant;

      &.active {
        background-color: $transparent-primary;
        color: $primary-light;
        text-decoration: none;
      }

      &:hover:not(.active) {
        background-color: $transparent-dark-variant;
        color: $text-dark;
        text-decoration: none;
      }

      > svg {
        width: 16px;
        margin-right: 8px !important;
      }
    }
  }

  &__memory-used {
    margin: 5px 0 10px;
    font-weight: $font-weight-medium;
    font-size: 12px;
  }

  .danger {
    .settings__memory-used {
      font-weight: $font-weight-bold;
    }
  }

  label.required::after {
    content: '*';
    margin: 0 0.5em;
  }

  .error-msg {
    margin-left: 0.5em;
    color: $danger;
  }

  .progress-bar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 4px;
    border-radius: 0.5em;
    background: var(--bg-color);

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: var(--perc);
      height: 100%;
      background: var(--color);
    }
  }
}
</style>

<i18n locale="fr">
{
  "menu": {
    "data-period": "Période des données",
    "general": "Général",
    "driver-app": "Application conducteur",
    "teams": "Équipes",
    "passenger-count": "Comptages",
    "punctuality-params": "Rapport de ponctualité",
    "depots": "Gestion des dépôts",
    "stop-zones": "Distances et zones d'arrêt",
    "routes": "Gestion des lignes",
    "trips": "Gestion des courses"
  },
  "unsavedChanges": "Il y a des changements non sauvegardés.\nVoulez-vous vraiment continuer ?",
  "upgrade": "Mettre à niveau"
}
</i18n>

<i18n locale="en">
{
  "menu": {
    "data-period": "Data period",
    "general": "General",
    "driver-app": "Driver app",
    "teams": "Teams",
    "passenger-count": "Passenger count",
    "punctuality-params": "Punctuality reporting",
    "depots": "Bus depots management",
    "stop-zones": "Distance and stop zones",
    "routes": "Route management",
    "trips": "Trip management"
  },
  "unsavedChanges": "There are unsaved changes.\nDo you really want to leave?",
  "upgrade": "Upgrade"
}
</i18n>
