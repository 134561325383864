<script setup lang="ts">
import { computed, ref, watch, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, type LocationQuery, type RouteLocationNormalized, type RouteRecordName } from 'vue-router';
import { Store, useStore } from 'vuex';

const i18n = useI18n();
const route = useRoute();
const store = useStore();

interface Level {
  routeName: RouteRecordName | null | undefined;
  crumbName: string;
  query?: LocationQuery;
}

const crumbName = ref();
const parentCrumbName = ref();

const levels = computed(() => {
  const levels: Level[] = [
    {
      routeName: route.name,
      crumbName: crumbName.value || i18n.t(`breadcrumb.${route.name as string}`),
    },
  ];

  const parent = route.meta?.parent as { crumbName: String; name: String; query: LocationQuery };
  if (parent) {
    levels.unshift({
      routeName: parent.name as string,
      // Since we want to keep the same date, we use the current route query for the parent and grandparent
      query: route.query,
      crumbName: parentCrumbName.value || i18n.t(`breadcrumb.${parent.name as string}`),
    });
  }

  const grandParent = route.meta?.grandParent as { crumbName: String; name: String; query: LocationQuery };
  if (grandParent) {
    levels.unshift({
      routeName: grandParent.name as string,
      // Since we want to keep the same date, we use the current route query for the parent and grandparent
      query: route.query,
      crumbName: i18n.t(`breadcrumb.${grandParent.name as string}`),
    });
  }

  return levels;
});

watch(
  route,
  () => {
    crumbName.value = null;
    parentCrumbName.value = null;
    loadCrumbName();
    loadParentCrumbName();
  },
  { immediate: true },
);

async function loadCrumbName() {
  const generateCrumbName = route.meta.crumbName as (
    crumbName: Ref<string>,
    route: RouteLocationNormalized,
    store: Store<any>,
  ) => Promise<void>;
  if (generateCrumbName) {
    await generateCrumbName(crumbName, route, store);
  }
}

async function loadParentCrumbName() {
  const parent = route.meta.parent as { crumbName: Function; name: String; query: Object };
  const generateCrumbName = parent?.crumbName as (
    crumbName: Ref<string>,
    route: RouteLocationNormalized,
    store: Store<any>,
  ) => Promise<void>;
  if (generateCrumbName) {
    await generateCrumbName(parentCrumbName, route, store);
  }
}
</script>

<template>
  <div class="breadcrumbs">
    <template v-for="level in levels" :key="level.routeName">
      <font-awesome-icon icon="fa-chevron-right" />

      <router-link
        v-if="route.name !== level.routeName"
        :to="{ name: level.routeName, query: level.query || null }"
      >
        {{ level.crumbName }}
      </router-link>
      <span v-else>{{ level.crumbName }}</span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.breadcrumbs {
  @include small-screen {
    position: relative;
    overflow-x: auto;
    width: 100%;
    min-height: 21px;
    padding-right: 25px;
    padding-left: $view-standard-padding;

    // allow user to scroll horizontally when the screen is too small :
    white-space: nowrap;
    scrollbar-width: none;

    .fa-chevron-right:first-child {
      display: none;
    }
  }

  display: flex;
  align-items: center;
  margin-left: 5px;
  font-weight: 500;

  > a:hover {
    text-decoration: underline;
  }

  > .fa-chevron-right {
    padding: 0 10px;
    color: $text-neutral;
  }
}
</style>
