<template>
  <div class="teams">
    <div class="teams__header">
      <Btn type="primary" :disabled="lineInEdition" @click="addNewTeam">
        {{ $t('newTeam') }}
      </Btn>
    </div>

    <DataGridVuetify
      v-model:rendered-data-length="renderedDataLength"
      :title="$t('teams', { count: renderedDataLength })"
      :build-cell-injectors="buildCellInjectors"
      :data="teamsListFormatted"
      :datagrid="datagrid"
      :is-in-edition-mode="inEditionId === NEW_LINE_ID"
      :line-id-in-edition="inEditionId"
      :loading="loading"
      :tabs="tabs"
    >
      <template #actions="propsAction">
        <ActionCell
          :edit-mode="[NEW_LINE_ID, inEditionId].includes(propsAction.object.team_id)"
          :actions="
            propsAction.object.archived
              ? ['restore']
              : propsAction.object.isUsedInTrips
                ? ['edit']
                : ['archive', 'edit']
          "
          :object="propsAction.object"
          @archive="dataLine => showModal(dataLine, ModalType.ARCHIVE_TEAM)"
          @edit="dataLine => toggleEditionMode(dataLine, true)"
          @restore="dataLine => restoreTeam(dataLine)"
          @save="saveEdits"
          @switchOffEditionMode="dataLine => toggleEditionMode(dataLine, false)"
        />
      </template>
    </DataGridVuetify>

    <ModalArchiveRestore
      v-if="modalShown === ModalType.ARCHIVE_TEAM"
      :body="$t('confirmArchiveTeam')"
      :title="$t('archiveTeam')"
      @close="closeModal"
      @submit="archiveTeam"
    />
  </div>
</template>

<script>
import api from '@/api';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import { ColumnKey, getDatagrid } from './TeamList.conf.js';
import Btn from '@/components/ui/Btn.vue';
import ModalArchiveRestore from '@/components/ui/ModalArchiveRestore.vue';
import ActionCell from '@/components/Table/DataGridVuetify/cellsV2/ActionCell.vue';

export const NEW_LINE_ID = 'new-line';

/** @enum {string} */
export const ModalType = {
  ARCHIVE_TEAM: 'archive_vehicle',
};

/** @enum {string} */
export const Tabs = {
  ACTIVE: 'active',
  ARCHIVE: 'archived',
};

export default {
  name: 'TeamList',

  components: {
    ActionCell,
    Btn,
    DataGridVuetify,
    ModalArchiveRestore,
  },

  data() {
    return {
      ModalType,
      NEW_LINE_ID,
      tripsObject: null,

      /** @type {import('@/store').Team} */
      currentTeam: null,
      /** @type {import('@/components/Table/DataGridVuetify/models/DataGrid.models').DataGrid} */
      datagrid: getDatagrid(),
      /** @type {?String} */
      inEditionId: null,
      /** @type {Boolean} */
      loading: true,
      /** @type {ModalType} */
      modalShown: '',
      /** @type {Array<string>} */
      statusCategories: Object.values(Tabs),
      /** @type {number} */
      renderedDataLength: null,
      /** @type {import('@/store').Team} */
      teamInEditionOriginalState: null,
      /** @type {Array<import('@/store').Team>} */
      teamsListFormatted: [],
    };
  },

  computed: {
    /** @return {{[key in ColumnKey]: (data: {apiData: import('@/store').Team}) => Object}} */
    buildCellInjectors() {
      /**
       * @param {import('@/store').Team} apiDataRow
       * @return {({ value: object, field: string }) => void}
       */
      const bindValueChanged =
        apiDataRow =>
        ({ value, field }) => {
          this.updateValue(apiDataRow, value, field);
        };

      return {
        [ColumnKey.NAME]: ({ apiData }) => ({
          editMode: apiData.team_id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
        [ColumnKey.COLOR]: ({ apiData }) => ({
          editMode: apiData.team_id === this.inEditionId,
          valueChanged: bindValueChanged(apiData),
        }),
      };
    },

    /** @return {Boolean} */
    lineInEdition() {
      return this.inEditionId != null;
    },

    /** @return {Array<import('@/store').Team>} */
    teamsList() {
      return this.$store.getters.group.teams;
    },

    /** @return {Array<import('@/components/Table/DataGridVuetify/index.vue').Tab>} */
    tabs() {
      return this.statusCategories.map(category => ({
        value: category,
        name: this.$t(`tabs.${category}`),
        counter: null,
        dataList: [],
        filterField: 'archived',
        filterValues: Tabs.ARCHIVE === category ? [true] : [false],
        isDefaultActive: Tabs.ACTIVE === category,
        icon: Tabs.ARCHIVE === category ? 'fa:fas fa-archive' : '',
      }));
    },
  },

  async created() {
    this.tripsObject = this.$store.state.gtfs.tripsMap;
    this.formatTeamsList();
    this.loading = false;
  },

  methods: {
    addNewTeam() {
      if (!this.teamsListFormatted.find(v => v.team_id === NEW_LINE_ID)) {
        this.inEditionId = NEW_LINE_ID;
        this.teamsListFormatted.unshift({
          name: this.$t('newTeam'),
          color: '',
          team_id: NEW_LINE_ID,
          archived: false,
        });
      }
    },

    archiveTeam() {
      this.currentTeam.archived = true;
      this.saveEdits(this.currentTeam);
      this.closeModal();
    },

    closeModal() {
      this.modalShown = '';
      this.currentTeam = null;
    },

    formatTeamsList() {
      this.loading = true;
      const teamsList = this.teamsList.map(team => {
        const isTeamUsedInTrips = this.hasActiveTransportPlan(team.team_id);
        return { ...team, isUsedInTrips: isTeamUsedInTrips };
      });
      this.teamsListFormatted = teamsList.reduce((acc, team) => {
        if (!team.archived) {
          team.archived = false;
        }
        acc.push(team);
        return acc;
      }, []);
      this.loading = false;
    },

    /** @param {string} id */
    resetLine(id) {
      const elementIndex = this.teamsListFormatted.findIndex(el => el.team_id === id);
      if (id === NEW_LINE_ID) {
        this.teamsListFormatted.splice(elementIndex, 1);
      } else {
        // give the element its original values back
        this.teamsListFormatted[elementIndex] = { ...this.teamInEditionOriginalState };
      }
    },

    /**
     * @param {import('@/store').Team} team
     */
    restoreTeam(team) {
      team.archived = false;
      this.saveEdits(team);
    },

    /**
     * Edit team
     * @param {import('@/store').Team} apiDataRow
     */
    async saveEdits(apiDataRow) {
      if (apiDataRow.team_id === NEW_LINE_ID) {
        delete apiDataRow.team_id;
        await api.createNewTeam(this.$store.getters.group._id, apiDataRow);
        await this.$store.dispatch('groupUpdate');
        this.formatTeamsList();
      } else {
        const team = { ...apiDataRow };
        await api.modifyTeam(this.$store.getters.group._id, team);
        await this.$store.dispatch('groupUpdate');
        this.formatTeamsList();
      }
      this.inEditionId = null;
    },

    /**
     * Show a modal
     * @param {import('@/store').Team} apiDataRow
     * @param {ModalType} modalName
     */
    showModal(apiDataRow, modalName) {
      this.currentTeam = { ...apiDataRow };
      this.modalShown = modalName;
    },

    /**
     * Check if a teams is in an actif transport plan.
     * @param {string} teamId
     */
    hasActiveTransportPlan(teamId) {
      if (!this.tripsObject) {
        return false;
      }
      const trips = Object.values(this.tripsObject);
      return trips.some(trip => trip.team_id === teamId);
    },

    /**
     * Switch a line to edition mode or get out of edition mode and cancel previous changes made and not saved
     * @param {import('@/store').Team} apiDataRow
     * @param {boolean} editionMode
     */
    toggleEditionMode(apiDataRow, editionMode) {
      if (editionMode) {
        // if another edition was in progress, cancel it
        if (this.inEditionId) {
          this.resetLine(this.inEditionId);
        }
        this.inEditionId = apiDataRow.team_id;
        // keep a copy of the element about to be edited in case of edit cancellation
        this.teamInEditionOriginalState = { ...apiDataRow };
      } else {
        this.resetLine(apiDataRow.team_id);
        this.inEditionId = null;
        this.teamInEditionOriginalState = null;
      }
    },

    /**
     * Update a value - triggered upon focus lost on an EditableCell
     * @param {import('@/store').Team} apiDataRow
     * @param {string} value
     */
    updateValue(apiDataRow, value, field) {
      apiDataRow[field] = value;
    },
  },
};
</script>

<style lang="scss">
.teams {
  &__header {
    padding-bottom: 10px;
  }
}
</style>

<i18n locale="fr">
{
  "tabs": {
    "archived": "Archives",
    "active": "Équipes",
  },
  "archiveTeam": "Archiver l'équipe",
  "confirmArchiveTeam": "Êtes-vous sûr de vouloir archiver cette équipe ?",
  "edit": "Modifier",
  "newTeam": "Nouvelle équipe",
  "teams": "équipe | équipes"
}
</i18n>

<i18n locale="en">
{
  "tabs": {
    "archived": "Archives",
    "active": "Teams",
  },
  "archiveTeam": "Archive the team",
  "confirmArchiveTeam": "Do you want to archive the team?",
  "edit": "Edit",
  "newTeam": "New team",
  "teams": "team | teams"
}
</i18n>
