<script setup lang="ts">
defineProps({
  data: {
    type: [Number, String],
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <v-card link elevation="0" class="metric-card px-1">
    <v-card-title class="metric-card__title">{{ title }}</v-card-title>
    <v-card-text class="d-flex justify-space-between align-center metric-card__text">
      <div class="metric-card__ellipsis">
        <span class="metric-card__data">{{ data === null ? '-' : data }}</span>
        <span class="metric-card__info">{{ $t('today') }}</span>
      </div>
      <div><v-icon class="metric-card__arrow">fa:fas fa-arrow-right</v-icon></div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.metric-card {
  padding-top: 9px;
  padding-bottom: 9px;
  background-color: $canvas;

  &__arrow {
    color: $text-dark-variant;
    font-size: 12px;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: 14px;
  }

  &__data {
    font-weight: $font-weight-semi-bold;
    font-size: 20px;
  }

  &__info {
    color: $text-neutral;
    margin-inline: 5px;
  }

  &__ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__text {
    padding-bottom: 5px;
  }
}
</style>

<i18n locale="fr">
  {
    "today": "aujourd'hui",
  }
</i18n>

<i18n locale="en">
  {
    "today": "today",
  }
</i18n>
