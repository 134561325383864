import { UpdateType } from '@/api';
import i18n from '@/i18n';
import { dateGtfsFormatToObj, getISODate } from '@/libs/helpers/dates';
import { localeCompareSort } from '@/libs/helpers/strings';
import store from '@/store';
import { TripListDataFormatter } from '../TripsListPage/TripListDataFormatterHelper';

/** @enum {string} */
export const TripDetailParams = {
  ACTUALKM: 'actualKm',
  ALIGHTING: 'alighting',
  BOARDING: 'boarding',
  COMMENT: 'comment',
  DATE: 'date',
  DELAY: 'delay',
  DEVICE: 'device',
  DEVICE_STATUS: 'deviceStatus',
  DEVICE_TEAM: 'deviceTeam',
  DRIVERS: 'driver', // #DEBT this value is left in singular form to ensure compatibility with the old params already present in LocalStorage key 'tripDetailedPage/config'
  DUTY: 'duty',
  FIRST_STOP: 'firstStop',
  LAST_STOP: 'lastStop',
  LOADING: 'loading',
  NAME: 'name',
  OFF_ITINERARY_KM: 'off_itinerary_km',
  PROGRESSION: 'progression',
  RECORDED_ARRIVAL: 'recordedArrival',
  RECORDED_DEPARTURE: 'recordedDeparture',
  ROUTE: 'route',
  SERVICE: 'service',
  STOP_INFO: 'stopInfo',
  THEORETICAL_ARRIVAL: 'theoreticalArrival',
  THEORETICAL_DEPARTURE: 'theoreticalDeparture',
  THEORETICAL_KM: 'theoreticalKm',
  TRANSPORT_PLAN: 'transportPlan',
  TRIP_HEADSIGN: 'tripHeadsign',
  TRIP_ID: 'tripId',
  TRIP_STATUS: 'tripStatus',
  TRIP_TEAMS: 'tripTeams',
  TRIP_UPDATES: 'tripUpdates',
  UNRELIABLE_KM: 'unreliableKm',
  VEHICLES: 'vehicle', // #DEBT this value is left in singular form to ensure compatibility with the old params already present in LocalStorage key 'tripDetailedPage/config'
};

/** @enum {string} */
export const DefaultSelectedParams = {
  ACTUALKM: 'actualKm',
  DELAY: 'delay',
  DEVICE: 'device',
  DEVICE_TEAM: 'deviceTeam',
  LAST_STOP: 'lastStop',
  NAME: 'name',
  SERVICE: 'service',
  RECORDED_ARRIVAL: 'recordedArrival',
  RECORDED_DEPARTURE: 'recordedDeparture',
  ROUTE: 'route',
  THEORETICAL_KM: 'theoreticalKm',
  TRIP_STATUS: 'tripStatus',
};

/**
 * Get informations from trip, formated if needed
 * @param {TripDetailParams} param
 * @param {import('@/api').TripListItemV4} trip
 * @param {Date} date
 * @param {string} groupId
 * @param {string} tz
 * @returns {any}
 */
export const getInfo = (param, trip, date, groupId, tz = 'UTC') => {
  switch (param) {
    case TripDetailParams.NAME:
      return trip.formatted_name;
    case TripDetailParams.TRIP_STATUS:
      return { status: trip.status, problems: trip.problems };
    case TripDetailParams.ROUTE:
      return {
        route_color: trip.route.color,
        route_text_color: trip.route.text_color,
        route_short_name: trip.route.short_name,
        isDeactivated: store.getters.group.deactivated_routes.includes(trip.route.id),
      };
    case TripDetailParams.PROGRESSION:
      return trip.percent_km
        ? {
            value: Math.round(Number(trip.percent_km) / 10) * 10, // Round percent number by ten
          }
        : null;
    case TripDetailParams.DATE:
      return { date: dateGtfsFormatToObj(trip.service_date), options: { dateStyle: 'short' } };
    case TripDetailParams.TRIP_TEAMS:
      return { teamIds: trip.teams?.map(team => team.id) };
    case TripDetailParams.DUTY:
      return trip.block_id;
    case TripDetailParams.TRANSPORT_PLAN:
      return trip.gtfs;
    case TripDetailParams.DEVICE:
      return { device: trip.devices, date, groupId };
    case TripDetailParams.DEVICE_TEAM:
      return { teamIds: trip.devices?.[0]?.team?.id ? [trip.devices[0].team.id] : [] };
    case TripDetailParams.DELAY:
      return trip.delay;
    case TripDetailParams.ACTUALKM:
      return trip.reliable_km || trip.vk;
    case TripDetailParams.THEORETICAL_KM:
      return trip.tk;
    case TripDetailParams.UNRELIABLE_KM:
      return trip.unreliable_km;
    case TripDetailParams.SERVICE:
      return trip.service
        ? {
            value: (trip.service.recorded_stops / trip.service.planned_stops) * 100,
            service: trip.service,
          }
        : null;
    case TripDetailParams.FIRST_STOP:
      return {
        name: trip.first_stop.name,
        groupId,
        stopId: trip.first_stop.id,
        query: { date: getISODate(date) },
      };
    case TripDetailParams.THEORETICAL_DEPARTURE:
      return TripListDataFormatter.formatTimeHHMM(trip.departure_time, tz);
    case TripDetailParams.RECORDED_DEPARTURE:
      return TripListDataFormatter.formatTimeHHMM(trip.departure_stored, tz);
    case TripDetailParams.LAST_STOP:
      return {
        name: trip.last_stop.name,
        groupId,
        stopId: trip.last_stop.id,
        query: { date: getISODate(date) },
      };
    case TripDetailParams.THEORETICAL_ARRIVAL:
      return TripListDataFormatter.formatTimeHHMM(trip.arrival_time, tz);
    case TripDetailParams.RECORDED_ARRIVAL:
      return TripListDataFormatter.formatTimeHHMM(trip.arrival_stored, tz);
    case TripDetailParams.BOARDING:
      return trip.passenger_count?.boardings;
    case TripDetailParams.ALIGHTING:
      return trip.passenger_count?.alightings;
    case TripDetailParams.LOADING:
      return trip.passenger_count?.loading;
    case TripDetailParams.TRIP_UPDATES:
      return Object.keys(trip.updates).length !== 0 ? trip.updates : null;
    case TripDetailParams.STOP_INFO:
      return trip.updates?.[UpdateType.STOP_INFO];
    case TripDetailParams.COMMENT:
      return trip.updates?.[UpdateType.COMMENT];
    case TripDetailParams.VEHICLES: {
      /** @type {Array<import('@/components/common/DropList.vue').DropListValue>} */
      const formattedVehicles = trip.vehicles?.map(vehicle => {
        if (!vehicle || !vehicle.fleet_number) return { text: '-' };
        const vehicleText = `${vehicle.fleet_number} ${
          vehicle.license_plate ? `(${vehicle.license_plate})` : ''
        }`;
        return { text: vehicleText };
      });

      if (formattedVehicles?.length > 1) {
        formattedVehicles.sort((a, b) => localeCompareSort(a.text, b.text, i18n.global.locale));
      }
      return formattedVehicles;
    }
    case TripDetailParams.DRIVERS: {
      /** @type {Array<import('@/components/common/DropList.vue').DropListValue>} */
      const formattedDrivers = trip.drivers?.map(driver => {
        if (!driver?.name) return { text: '-' };
        const driverText = `${driver.name} ${driver.staff_number ? `(${driver.staff_number})` : ''}`;
        return { text: driverText };
      });

      if (formattedDrivers?.length > 1) {
        formattedDrivers.sort((a, b) => localeCompareSort(a.text, b.text, i18n.global.locale));
      }
      return formattedDrivers;
    }
    case TripDetailParams.DEVICE_STATUS:
      return trip.devices?.find(d => d.status) ? true : null;
    case TripDetailParams.TRIP_ID:
      return trip.id;
    case TripDetailParams.TRIP_HEADSIGN:
      return trip.headsign;
    case TripDetailParams.OFF_ITINERARY_KM:
      return trip.off_itinerary_km;
    default:
      return null;
  }
};
