<script setup lang="ts">
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import { useRouter, type RouteLocationRaw } from 'vue-router';
import { useStore } from 'vuex';

import Btn from '@/components/ui/Btn.vue';
import Highlighter from '@/components/ui/Highlighter.vue';
import { getISODate } from '@/libs/helpers/dates';
import { normalize, RE_ESCAPE } from '@/libs/helpers/strings';
import { GroupRoute } from '@/libs/routing';

import { type Device } from '@/store/devices';
import { type Trip, type Stop } from '@/store/gtfs';

const router = useRouter();
const store = useStore();

// Types :
interface SearchResult {
  id: string;
  name: string;
  priority: number | undefined;
  type: SearchResultType;
  partsName: string[];
  partsSub: string[];
  status?: DeviceStatus;
}

enum DeviceStatus {
  OFFLINE = 0,
  ONLINE = 1,
}

enum SearchResultType {
  DEVICE = 'device',
  STATION = 'station',
  STOP = 'stop',
  TRIP = 'trip',
}

enum SearchResultCategory {
  ALL = 'all',
  DEVICES = 'devices',
  TRIPS = 'trips',
  STOPS = 'stops',
}

// Constants :
const TABS = [
  { value: SearchResultCategory.ALL, label: 'Tout' },
  { value: SearchResultCategory.DEVICES, label: 'Appareils', icon: 'fa:fas fa-mobile-alt' },
  { value: SearchResultCategory.TRIPS, label: 'Courses', icon: 'fa:fas fa-bus' },
  { value: SearchResultCategory.STOPS, label: 'Arrêts', icon: 'fa:fas fa-map-marker-alt' },
];

const TYPE_ICON: Map<SearchResultType, string> = new Map([
  [SearchResultType.DEVICE, 'fa:fas fa-mobile-alt'],
  [SearchResultType.STOP, 'fa:fas fa-map-marker-alt'],
  [SearchResultType.STATION, 'fa:fas fa-map-marker-alt'],
  [SearchResultType.TRIP, 'fa:fas fa-bus'],
]);

const MIN_INPUT_LENGTH = 2;

// Emit :
const emit = defineEmits(['closeSearchbox']);

// Refs :
const input = ref('');
const listStops = ref<Stop[]>([]);
const listTrips = ref<Trip[]>([]);
const searchShow = ref(false);
const tripsFormattedNames = ref<Map<string, string>>(new Map());
const selectedTab = ref<SearchResultCategory>(SearchResultCategory.ALL);
const focusedResultIndex = ref(0);

// Computed :
const isMobileScreen = computed<boolean>(() => store.state.userInterface.isMobileScreen);

const listDevices = computed<Device[]>(() => Object.values(store.state.devices.list));

const results = computed<SearchResult[]>(() => {
  switch (selectedTab.value) {
    case SearchResultCategory.DEVICES:
      return searchDevices.value;
    case SearchResultCategory.TRIPS:
      return searchTrips.value;
    case SearchResultCategory.STOPS:
      return searchStops.value;
    default:
      return [...searchDevices.value, ...searchTrips.value, ...searchStops.value];
  }
});

function searchItems<T>(
  items: Array<T>,
  getId: (item: T) => string,
  getName: (item: T) => string,
  getType: () => SearchResultType,
  additionalChecks: (item: T) => boolean = () => true,
  additionalData: (item: T) => Partial<SearchResult> = () => ({}),
): SearchResult[] {
  if (input.value === '' || input.value.length < MIN_INPUT_LENGTH) return [];

  let inputValue = normalize(input.value) as string;
  inputValue = inputValue.replace(RE_ESCAPE, '\\$&');
  const re = new RegExp(inputValue, 'ig');

  const results: SearchResult[] = [];
  items.forEach(item => {
    if (!additionalChecks(item)) return;

    const normalizedId = normalize(getId(item));
    const normalizedName = normalize(getName(item));

    const matchId = normalizedId ? re.exec(normalizedId) : null;
    const matchName = normalizedName ? re.exec(normalizedName) : null;
    if (matchId || matchName) {
      let priority;
      if (matchId) priority = matchId.index;
      if (matchName) {
        if (priority) priority = Math.min(priority, matchName.index);
        else priority = matchName.index;
      }

      results.push({
        id: getId(item),
        name: getName(item),
        priority,
        partsName: highlightSearchedParts(getName(item)),
        partsSub: highlightSearchedParts(getId(item)),
        type: getType(),
        ...additionalData(item),
      });
    }
  });

  results.sort((a, b) => {
    const nameA = normalize(a.name) ?? '';
    const nameB = normalize(b.name) ?? '';
    return (a.priority ?? 0) - (b.priority ?? 0) || nameA.localeCompare(nameB);
  });

  return results;
}

const searchDevices = computed<SearchResult[]>(() => {
  return searchItems(
    listDevices.value,
    device => device.device_id,
    device => device.name || 'noName',
    () => SearchResultType.DEVICE,
    device => !device.archived,
    device => ({
      status: store.state.devices.online[device.device_id] ? DeviceStatus.ONLINE : DeviceStatus.OFFLINE,
    }),
  );
});

const searchStops = computed<SearchResult[]>(() => {
  return searchItems(
    listStops.value,
    stop => stop.stop_id,
    stop => stop.stop_name,
    () => SearchResultType.STOP,
  );
});

const searchTrips = computed<SearchResult[]>(() => {
  return searchItems(
    listTrips.value,
    trip => trip.trip_id,
    trip => tripsFormattedNames.value.get(trip.trip_id) || '',
    () => SearchResultType.TRIP,
  );
});

// Methods :
const closeSearchResult = () => {
  searchShow.value = false;
  window.removeEventListener('click', closeSearchResult);
};

/**
 * Search a part string in a string and split it arround the part if found
 */
const highlightSearchedParts = (textToSearch: string, searchedPart = input.value): string[] => {
  let highlightParts = [];
  if (textToSearch?.toLowerCase().includes(searchedPart.toLowerCase())) {
    // Split string while keeping the 'split' part
    const regex = new RegExp(`(${searchedPart})`, 'i');
    highlightParts = textToSearch.split(regex);
  } else {
    highlightParts = [textToSearch];
  }
  return highlightParts;
};

const fetchCollections = async () => {
  await Promise.all([
    store.dispatch('gtfs/getStopsMap').then((stopsMap: { [stopId: string]: Stop }) => {
      listStops.value = Object.values(stopsMap);
    }),

    store.dispatch('gtfs/getTripsMap').then(async (tripsMap: { [tripId: string]: Trip }) => {
      listTrips.value = tripsMap ? Object.values(tripsMap) : [];
      await generateTripsFormattedNames();
    }),
  ]);
};

const generateTripsFormattedNames = async () => {
  tripsFormattedNames.value = new Map();
  if (!listTrips.value) return;

  const tripsFormattedNamesMap = new Map();
  await Promise.all(
    listTrips.value.map(async trip => {
      const name = await store.dispatch('gtfs/formatTripName', {
        tripId: trip.trip_id,
        date: new Date(),
      });
      tripsFormattedNamesMap.set(trip.trip_id, name);
    }),
  );

  tripsFormattedNames.value = tripsFormattedNamesMap;
};

const getLinkFromObject = (objectName: string, id: string): RouteLocationRaw => {
  switch (objectName) {
    case SearchResultType.STOP:
    case SearchResultType.STATION:
      return { name: GroupRoute.STOP_DETAILED, params: { stopId: id } };
    case SearchResultType.TRIP:
      return {
        name: GroupRoute.TRIP_DETAILED,
        params: { tripId: id },
        query: { date: getISODate(new Date()) },
      };
    case SearchResultType.DEVICE:
      return { name: GroupRoute.DEVICE_DETAILLED, params: { deviceId: id } };
    default:
      return {};
  }
};

const getTypeIcon = (type: SearchResultType): string => {
  return TYPE_ICON.get(type) as string;
};

const showSearchResult = () => {
  searchShow.value = true;
  window.removeEventListener('click', closeSearchResult);
  setTimeout(() => window.addEventListener('click', closeSearchResult));
};

const navigateToResult = (index: number) => {
  if (results.value.length === 0) return;
  const result = results.value[index];
  const link = getLinkFromObject(result.type, result.id);
  router.push(link);
  input.value = '';
  selectedTab.value = SearchResultCategory.ALL;
};

const closeSearchbox = () => {
  emit('closeSearchbox');
  input.value = '';
  selectedTab.value = SearchResultCategory.ALL;
};

// Lifecycle hooks :
onMounted(() => {
  fetchCollections();
});

watch(input, () => {
  focusedResultIndex.value = 0;
  if (input.value.length < MIN_INPUT_LENGTH) {
    selectedTab.value = SearchResultCategory.ALL;
  }
});

watch(router.currentRoute, () => {
  input.value = '';
  selectedTab.value = SearchResultCategory.ALL;
});

onUnmounted(() => {
  window.removeEventListener('click', closeSearchResult);
});
</script>

<template>
  <div class="searchbox" @click="showSearchResult()">
    <v-icon class="searchbox__icon">fa:fas fa-search</v-icon>
    <v-icon v-if="isMobileScreen" class="searchbox__icon" @click="closeSearchbox()">fa:fas fa-times</v-icon>
    <input
      v-model="input"
      class="input rounded-pill"
      :placeholder="$t('search')"
      type="text"
      @keypress.enter="navigateToResult(focusedResultIndex)"
    />

    <div v-if="input.length >= MIN_INPUT_LENGTH && searchShow" class="results-wrapper">
      <v-tabs v-model="selectedTab" slider-color="#00b871" class="results-wrapper__tabs-container">
        <v-tab
          v-for="tab in TABS"
          :key="tab.value"
          :value="tab.value"
          :prepend-icon="tab.icon"
          class="results-wrapper__v-tabs"
        >
          {{ tab.label }}
        </v-tab>
      </v-tabs>

      <ul class="results-list">
        <!-- no result -->
        <li v-if="results.length === 0">
          <div class="result result--empty">
            <span>{{ $t('noResults') }}</span>
            <Btn
              v-if="[SearchResultCategory.ALL, SearchResultCategory.TRIPS].includes(selectedTab)"
              :route="{ name: GroupRoute.TRIP_LIST }"
              type="primary"
            >
              {{ $t('seeTrips') }}
            </Btn>
            <Btn
              v-else-if="SearchResultCategory.DEVICES === selectedTab"
              :route="{ name: GroupRoute.DEVICE_LIST }"
              type="primary"
            >
              {{ $t('seeDevices') }}
            </Btn>
            <Btn
              v-else-if="SearchResultCategory.STOPS === selectedTab"
              :route="{ name: GroupRoute.STOP_LIST }"
              type="primary"
            >
              {{ $t('seeStops') }}
            </Btn>
          </div>
        </li>

        <!-- Result list -->
        <template v-if="results.length > 0">
          <li
            v-for="(result, index) in results"
            :key="`${result.type}_${result.id}`"
            class="result"
            :class="{ 'result--focused': index === focusedResultIndex }"
            @mouseenter="focusedResultIndex = index"
          >
            <router-link
              tabindex="0"
              class="result__link"
              :to="getLinkFromObject(result.type, result.id)"
              @click="input = ''"
            >
              <div class="result__icon">
                <font-awesome-icon :icon="getTypeIcon(result.type)" />
              </div>

              <div class="result__infos">
                <div class="result__title">
                  <!-- Status indicator for devices -->
                  <span v-if="result.type === SearchResultType.DEVICE">
                    <span v-if="result.status === DeviceStatus.ONLINE">
                      <font-awesome-icon icon="fa-circle" class="device-status device-status--online" />
                    </span>
                    <span v-else>
                      <font-awesome-icon icon="fa-circle" class="device-status device-status--offline" />
                    </span>
                    "
                  </span>

                  <Highlighter :parts="result.partsName" class="result__title" />
                </div>

                <div class="result__sub">
                  <Highlighter :parts="result.partsSub" />
                </div>
              </div>
            </router-link>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.searchbox {
  @include small-screen {
    width: 90%;
  }

  position: relative;
  display: flex;
  align-items: center;

  &__icon {
    position: absolute;
    left: 12px;
    padding: 10px;
    padding-left: 5px;
    font-size: 16px;

    &.fa-times {
      right: 8px;
      left: auto;
    }
  }

  input {
    @include small-screen {
      width: 100%;
    }

    height: 36px;
    padding: 4px 10px 4px 36px;
    border: 1px solid $border;
    background-color: $canvas;
    box-shadow: none !important;
    font: inherit;
  }

  input:focus {
    border: 1px solid $text-dark-variant;
  }

  input::placeholder {
    color: red;
    color: $text-neutral;
  }

  .results-wrapper {
    @include small-screen {
      position: fixed;
      top: $header-mobile;
      left: 0;
      width: 100vw;
      min-height: calc(100vh - $header-mobile);
      margin-top: 0;
      border: none;
      border-radius: unset;
      box-shadow: none;

      &__tabs-container {
        position: sticky;
        top: 0;
        box-sizing: border-box;
        border-top: 1px solid $light-border;
        background-color: $white;

        .v-slide-group__content {
          border-bottom: 1px solid $border;

          .v-tab__slider {
            height: 5px;
          }
        }
      }
    }

    position: absolute;
    top: 100%;
    right: 0;
    z-index: $dropdown-index;
    overflow: auto;
    width: 500px;
    max-height: 400px;
    margin-top: 8px;
    border: 1px solid $border;
    border-radius: 8px;
    background-color: $canvas;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 30%);

    &__v-tabs {
      margin-left: 5px;
      color: $text-dark !important;
      font-size: 12px;
    }
  }

  .results-list {
    margin-top: -2px;
  }

  .result {
    border-top: 2px solid $border;

    &--empty {
      @include small-screen {
        align-items: flex-start;
        height: calc(100vh - $header-mobile - 47px); // 47px = height of the tabs

        span {
          margin-top: 5px;
        }
      }

      display: flex;
      align-items: center;
      padding: 18px;
      background-color: $background;
      color: $text-dark-variant;
      font-weight: 600;
      font-size: 16px;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      .ui-btn--link {
        margin-left: 15px;
        padding: 2px 8px;
        font-weight: 500;
        font-size: 12px;
      }
    }

    &--focused {
      background-color: $background-variant;

      .result__title {
        text-decoration: underline;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      color: $text-dark;
      font-size: 12px;
      text-decoration: none;

      &:hover,
      &:focus {
        background-color: $background-variant;

        .result__title {
          text-decoration: underline;
        }
      }

      .device-status {
        display: inline-block;
        margin-right: 5px;
        font-size: 10px;

        &--online {
          color: $primary-light;
        }

        &--offline {
          color: $danger;
        }
      }
    }

    &__icon {
      @include small-screen {
        margin-left: 5px;
        font-size: 18px;
      }

      padding: 10px;
      padding-left: 5px;
    }

    &__sub {
      color: $text-neutral;
    }

    &__infos {
      display: flex;
      flex: 1;
      flex-direction: column;

      .highlight {
        color: $primary-light;
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}
</style>

<i18n locale="fr">
{
  "noResults": "Aucun résultat",
  "search": "Recherche",
  "EVERYTHING": "TOUT",
  "seeTrips": "Voir les courses",
  "seeDevices": "Voir les appareils",
  "seeStops": "Voir les arrêts"
}
</i18n>

<i18n locale="en">
{
  "noResults": "No results",
  "search": "Search",
  "EVERYTHING": "ALL",
  "seeTrips": "See trips",
  "seeDevices": "See devices",
  "seeStops": "See stops"
}
</i18n>

<i18n locale="es">
{
  "search": "Buscar",
  "noResults": "Ningún resultado",
  "EVERYTHING": "TODO"
}
</i18n>

<i18n locale="de">
{
  "search": "Suche",
  "noResults": "Kein Ergebnis",
  "EVERYTHING": "ALLE"
}
</i18n>

<i18n locale="cz">
{
  "search": "Vyhledávání",
  "noResults": "Žádný výsledek",
  "EVERYTHING": "VŠE"
}
</i18n>

<i18n locale="pl">
{
  "search": "Wyszukaj",
  "noResults": "Brak wyniku",
  "EVERYTHING": "WSZYSTKIE"
}
</i18n>

<i18n locale="it">
{
  "search": "Cerca",
  "noResults": "Nessun risultato",
  "EVERYTHING": "TUTTO"
}
</i18n>
