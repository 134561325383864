<script setup lang="ts">
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed, ref, watch, onMounted, type PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { trips as ApiTrips, tripUpdates as TripUpdatesApi } from '@/api';
import MapboxMap from '@/components/map/MapboxMap.vue';
import DataGridVuetify from '@/components/Table/DataGridVuetify/index.vue';
import Btn from '@/components/ui/Btn.vue';
import HeaderDatePicker from '@/components/ui/HeaderDatepicker.vue';
import Calendar from '@/libs/calendar';
import { toCSV } from '@/libs/csv';
import {
  dateGtfsFormatToObj,
  dateObjToGtfsFormat,
  getISODate,
  timestampFormatHHMM,
  timestampMidnight,
} from '@/libs/helpers/dates';
import { arrToObj } from '@/libs/helpers/objects';
import { GroupRoute } from '@/libs/routing';

// Types
import type { Route, Stop, Trip, Publication } from '@/@types/gtfs.ts';
import type { Group } from '@/@types/group';
import type { MapStop } from '@/@types/mapbox';
import type { StopTimeHistory } from '@/@types/api/stopTimes';
import type { ApiTripUpdate } from '@/store-pinia/trip-updates';
import type { DataGrid } from '@/components/Table/DataGridVuetify/models/DataGrid.models';

import { ColumnKey, getDatagrid } from './StopDetailed.conf.js';
import StopDetailedInfoCard from './StopDetailedInfoCard.vue';

dayjs.extend(utc);
const store = useStore();
const router = useRouter();

interface RowStopDetailed {
  delay: number;
  gtfsId: string;
  realTime: string;
  route: Route;
  theoreticalTime: string;
  tripId: string;
  tripFormattedName: string;
  stopSequence: number;
  hasBeenCanceled: boolean;
  timeOffset: number;
  parent_station?: string;
}

interface StopDetailed extends Stop {
  parentStationId?: string;
  parentStationName?: string;
}

const ExportColumns = {
  ROUTES_ID: 'Route_ID',
  ROUTE_FORMATTED_NAME: 'Route_formatted_name',
  PARENT_STATION: 'Parent_station',
  TRIP_ID: 'Trip_id',
  TRIP_FORMATTED_NAME: 'Trip_formatted_name',
  THEORETICAL_TIME: 'Theoretical_time',
  REAL_TIME: 'Real_time',
  DELAY: 'Delay',
};

const props = defineProps({
  query: {
    default: () => ({}),
    type: Object as PropType<{ date?: string }>,
  },

  stopId: {
    required: true,
    type: String,
  },
});

const datagrid = ref<DataGrid>(getDatagrid());
const downloadLink = ref<string>();
const emptyRowList = ref<Boolean>(false);
const isLoaded = ref<{ [e: string]: Boolean }>({
  recordedStopTimes: false,
  routes: false,
  stops: false,
  trips: false,
  tripsFormattedNames: false,
  tripUpdates: false,
});
const stops = ref<{ [stopId: string]: Stop }>({});
const trips = ref<{ [gtfsId: string]: { [tripId: string]: Trip } }>({});
const tripsFormattedNames = ref<{ [gtfsTripId: string]: string }>({});
const tripUpdates = ref<ApiTripUpdate[]>([]);
const recordedStopTimes = ref<{ [gtfsTripId: string]: { [stopSequence: number]: number } }>({});
const renderedDataLength = ref<number>();
const routes = ref<{ [gtfsId: string]: { [routeId: string]: Route } }>({});
const rows = ref<RowStopDetailed[]>([]);
const rowsListLoading = ref<Boolean>(true);

const buildCellInjectors = computed(() => {
  return {
    [ColumnKey.TRIP]: () => ({ date: selectedDate.value, groupId: group.value._id }),
  };
});

const center = computed<[number, number]>(() => {
  if (!stop.value) return [0, 0];
  return [stop.value.stop_lon, stop.value.stop_lat];
});

const defaultStartDateGtfs = computed<string>(() => {
  return dateObjToGtfsFormat(dayjs(new Date()).subtract(8, 'day').toDate());
});

const defaultEndDateGtfs = computed<string>(() => {
  return dateObjToGtfsFormat(new Date());
});

const downloadFileName = computed<string>(() => {
  return dateObjToGtfsFormat(dayjs(new Date()).subtract(8, 'day').toDate());
});

const group = computed<Group>(() => store.getters.group);

const isAllLoaded = computed<boolean>(() => {
  return Object.values(isLoaded.value).every(loaded => loaded);
});

const mapStop = computed<MapStop | undefined>(() => {
  if (!stop.value) return undefined;
  return {
    id: stop.value.stop_id,
    highlight: true,
  };
});

const midnight = computed<number>(() => {
  const date = dateGtfsFormatToObj(selectedDateGtfs.value);
  if (!date) return 0;
  return timestampMidnight(date, group.value.tz);
});

const nonExistingStop = computed<boolean>(() => {
  if (stops.value && !stop.value) {
    return true;
  }
  return false;
});

const selectedDate = computed({
  get() {
    if (props.query.date) return dayjs(props.query.date).utc().toDate();

    return new Date();
  },

  set(value) {
    // Slow down date selection to avoid concurrent loading.
    if (!isAllLoaded.value) return;
    emptyRowList.value = false;

    router.push({
      name: GroupRoute.STOP_DETAILED,
      params: {
        groupId: store.getters.group._id,
        stopId: props.stopId,
      },
      query: {
        date: getISODate(value),
      },
    });
  },
});

const selectedDateGtfs = computed<string>(() => {
  return dateObjToGtfsFormat(selectedDate.value);
});

const stop = computed<StopDetailed | null>(() => {
  const stop: StopDetailed = { ...stops.value?.[props.stopId] };
  if (stop.parent_station) {
    // Get parent_station name from id
    stop.parentStationId = stop.parent_station;
    stop.parentStationName = stops.value[stop.parent_station].stop_name;
  }
  return Object.keys(stop).length !== 0 ? stop : null;
});

const stopTimes = computed<{ [gtfsId: string]: { [tripId: string]: { [stopSequence: number]: number } } }>(
  () => {
    const stopTimes: any = {};

    Object.entries(trips.value).forEach(([gtfsId, trips]) => {
      if (!stopTimes[gtfsId]) stopTimes[gtfsId] = {};

      Object.entries(trips).reduce((acc, [tripId, trip]) => {
        if (!stopTimes[gtfsId][tripId]) stopTimes[gtfsId][tripId] = {};

        trip.stop_times.forEach(st => {
          if (st.stop_id === props.stopId) {
            stopTimes[gtfsId][tripId][st.stop_sequence] = st.departure_time;
          }
        });

        return acc;
      }, []);
    });

    return stopTimes;
  },
);

watch(
  () => midnight.value,
  () => {
    loadRoutes();
  },
);

watch(
  () => recordedStopTimes.value,
  () => {
    if (isLoaded.value.recordedStopTimes && isLoaded.value.tripUpdates) {
      createRowsList();
    }
  },
);

watch(
  () => rows.value,
  () => {
    if (rows.value) {
      createDownloadLink();
    }
  },
);

watch(
  () => selectedDate.value,
  () => {
    loadStops();
    loadTripUpdates();
    // Also dependency of `loadFormattedNames`, but `stopTimes` should trigger it.
  },
);

watch(
  () => selectedDateGtfs.value,
  () => {
    loadTrips();
    // Also dependency of `loadFormattedNames`, but `stopTimes` should trigger it.
  },
);

watch(
  () => stopTimes.value,
  async () => {
    // Async dependencies: watcher will be re-run for each.
    if (!isLoaded.value.stops || !isLoaded.value.trips) return;

    await loadFormattedNames();
    loadRecordedStopTimes();
  },
);

watch(
  () => props.stopId,
  () => {
    loadRecordedStopTimes();
  },
);

onMounted(async () => {
  await Promise.all([
    loadRoutes(),
    loadStops(),
    loadTrips(),
    loadFormattedNames(),
    loadTripUpdates(),
    loadRecordedStopTimes(),
  ]);
});

function createDownloadLink() {
  if (downloadLink.value != null) {
    URL.revokeObjectURL(downloadLink.value);
    downloadLink.value = undefined;
  }

  const tableData: (string | undefined)[][] = [];

  rows.value.forEach((row: RowStopDetailed) => {
    tableData.push([
      row.route.route_id,
      row.route.route_long_name,
      row.parent_station,
      row.tripId,
      row.tripFormattedName,
      row.theoreticalTime,
      row.realTime,
      row.delay?.toString() || '0',
    ]);
  });

  const data = [Object.values(ExportColumns), ...tableData];

  downloadLink.value = toCSV(data);
}

/**
 * List of rows. Sorted by publication date of gtfs and stop time.
 */
async function createRowsList() {
  rowsListLoading.value = true;
  emptyRowList.value = false;
  const rowsLocal: any = [];
  const promises = Object.entries(stopTimes.value).map(async ([gtfsId, allTrips]) => {
    const promisesBis = Object.entries(allTrips).map(async ([tripId, stopSequences]) => {
      const trip: Trip = trips.value[gtfsId as keyof { [gtfsId: string]: { [tripId: string]: Trip } }][
        tripId as keyof { [tripId: string]: Trip }
      ] as Trip;
      const serviceId = trip.service_id;
      // Check if the trip is active on that date
      const tripActive = await Calendar.isTripActiveOnDate(gtfsId, serviceId, selectedDate.value);
      // Add the trip to the list only if the trip is active on that date
      if (tripActive) {
        Object.keys(stopSequences).forEach(stopSequence => {
          rowsLocal.push({
            gtfsId,
            stopSequence,
            tripId,
          });
        });
      }
    });
    await Promise.all(promisesBis);
  });

  await Promise.all(promises);

  // Order trips by departure time
  rowsLocal.sort(
    (a: any, b: any) =>
      stopTimes.value[a.gtfsId][a.tripId][a.stopSequence] -
        stopTimes.value[b.gtfsId][b.tripId][b.stopSequence] ||
      trips.value[a.gtfsId][a.tripId].stop_times[0].departure_time -
        trips.value[b.gtfsId][b.tripId].stop_times[0].departure_time ||
      (a.tripId !== b.tripId ? (a.tripId < b.tripId ? -1 : 1) : 0),
  );

  // Add route, tripFormattedName, theoreticalTime, realTime and delay to trip object
  rowsLocal.forEach((row: any, index: number) => {
    row.id = `${row.tripId}-${index}`;
    row.route = getTripRoute(row.gtfsId, row.tripId);
    row.routeName = row.route.route_short_name || row.route.route_long_name || row.route.route_id;
    row.tripFormattedName = tripsFormattedNames.value[row.gtfsId + row.tripId] || row.tripId;
    const tsTheoreticalTime = midnight.value + stopTimes.value[row.gtfsId][row.tripId][row.stopSequence];
    const tsRealTime = recordedStopTimes.value[row.gtfsId + row.tripId]
      ? recordedStopTimes.value[row.gtfsId + row.tripId][row.stopSequence]
      : null;
    row.theoreticalTime = formatHHMM(tsTheoreticalTime);
    row.realTime = tsRealTime ? formatHHMM(tsRealTime) : null;
    row.delay = row.realTime && tsRealTime ? Math.floor((tsRealTime - tsTheoreticalTime) / 60) : null;

    // Check trip updates and apply changes if any
    const relatedTripUpdates = tripUpdates.value?.find(update => update.trip_id === row.tripId);
    row.hasBeenCanceled = relatedTripUpdates
      ? hasRowBeenCanceled(relatedTripUpdates, Number(row.stopSequence))
      : false;
    row.timeOffset = relatedTripUpdates ? getRowTimeOffset(relatedTripUpdates, Number(row.stopSequence)) : 0;
  });

  rows.value = rowsLocal;
  if (isLoaded.value.trips && rows.value.length === 0) {
    emptyRowList.value = true;
  }
  rowsListLoading.value = false;
}

function hasRowBeenCanceled(relatedTripUpdates: ApiTripUpdate, stopSequence: number): boolean {
  if (relatedTripUpdates?.skipped_stop_time_seqs?.includes(stopSequence)) {
    return true;
  }
  return false;
}

function getRowTimeOffset(relatedTripUpdates: ApiTripUpdate, stopSequence: number): number {
  let timeOffset = relatedTripUpdates.delay || 0;
  const relatedDelay = relatedTripUpdates.delays
    ? relatedTripUpdates.delays.find(delay => delay.stop_sequence === stopSequence)
    : 0;
  if (relatedDelay) {
    timeOffset += relatedDelay.delay;
  }
  return timeOffset;
}

function formatHHMM(ts: number): string {
  return timestampFormatHHMM(ts, { refDate: selectedDate.value, tz: group.value.tz });
}

function getTripRoute(gtfsId: string, tripId: string): Route {
  const routeId = trips.value[gtfsId][tripId].route_id;
  return (routes.value[gtfsId] || {})[routeId];
}

async function loadFormattedNames() {
  isLoaded.value.tripsFormattedNames = false;
  const formattedNames: { [gtfsTripId: string]: string } = {};

  const promises = Object.entries(stopTimes.value).map(([gtfsId, trips]) =>
    Promise.all(
      Object.keys(trips).map(async tripId => {
        formattedNames[gtfsId + tripId] = await store.dispatch('gtfs/formatTripName', {
          gtfsId,
          tripId,
          date: selectedDate.value,
        });
      }),
    ),
  );

  await Promise.all(promises);
  tripsFormattedNames.value = formattedNames;
  isLoaded.value.tripsFormattedNames = true;
}

function goToPreviousDay() {
  const date = new Date(selectedDate.value);
  date.setDate(date.getDate() - 1);
  selectedDate.value = date;
}

function goToNextDay() {
  const date = new Date(selectedDate.value);
  date.setDate(date.getDate() + 1);
  selectedDate.value = date;
}

async function loadRecordedStopTimes() {
  isLoaded.value.recordedStopTimes = false;
  const recordedStopTimesLocal: { [gtfsTripId: string]: { [stopSequence: number]: number } } = {};

  const stopTimeEvents: StopTimeHistory[] = await ApiTrips.getHistoryStopTimes(
    store.getters.group._id,
    selectedDateGtfs.value,
    undefined,
    props.stopId,
  );
  const filteredEvents = stopTimeEvents.filter(
    st => ((stopTimes.value[st.gtfs_id] || {})[st.trip_id] || {})[st.stop_sequence] != null,
  );

  // Find best departure time
  filteredEvents.forEach(st => {
    if (st.event === 'departure') {
      const gtfsTripId = st.gtfs_id + st.trip_id;

      if (!recordedStopTimesLocal[gtfsTripId]) recordedStopTimesLocal[gtfsTripId] = {};

      const thStopTime = stopTimes.value[st.gtfs_id][st.trip_id][st.stop_sequence];
      const currentRecordedStopTime = recordedStopTimesLocal[gtfsTripId][st.stop_sequence];

      if (
        !currentRecordedStopTime ||
        Math.abs(currentRecordedStopTime - thStopTime) > Math.abs(st.ts - thStopTime)
      ) {
        recordedStopTimesLocal[gtfsTripId][st.stop_sequence] = st.ts;
      }
    }

    if (st.event === 'arrival' && st.last_stop) {
      const gtfsTripId = st.gtfs_id + st.trip_id;

      if (!recordedStopTimesLocal[gtfsTripId]) recordedStopTimesLocal[gtfsTripId] = {};

      // Already exists
      if (recordedStopTimesLocal[gtfsTripId][st.stop_sequence]) return;

      const thStopTime = stopTimes.value[st.gtfs_id][st.trip_id][st.stop_sequence];
      const currentRecordedStopTime = recordedStopTimesLocal[gtfsTripId][st.stop_sequence];

      if (
        !currentRecordedStopTime ||
        Math.abs(currentRecordedStopTime - thStopTime) > Math.abs(st.ts - thStopTime)
      ) {
        recordedStopTimesLocal[gtfsTripId][st.stop_sequence] = st.ts;
      }
    }
  });

  recordedStopTimes.value = recordedStopTimesLocal;
  isLoaded.value.recordedStopTimes = true;
}

async function loadRoutes() {
  isLoaded.value.routes = false;
  const publications: Publication[] = await store.dispatch('gtfs/getGtfsPublicationsIn', {
    fromTs: midnight.value,
    toTs: midnight.value + 86400,
  });
  const gtfsIds = arrToObj(publications, 'current_file');

  // Clean unused GTFS
  Object.keys(routes.value).forEach(gtfsId => {
    if (!gtfsIds[gtfsId]) {
      delete routes.value[gtfsId];
    }
  });

  // Load new GTFS
  await Promise.all(
    Object.keys(gtfsIds).map(async gtfsId => {
      if (!routes.value[gtfsId]) {
        routes.value[gtfsId] = await store.dispatch('gtfs/getRoutesMap', { gtfsId });
      }
    }),
  );

  isLoaded.value.routes = true;
}

/**
 * Load stops and trips data from GTFS.
 */
async function loadStops() {
  isLoaded.value.stops = false;

  // Get stops from last GTFS at `selectedDate`.
  const date = new Date(selectedDate.value);
  date.setDate(date.getDate() + 1);
  const ts = date.getTime() / 1000;

  stops.value = await store.dispatch('gtfs/getStopsMap', { ts });

  isLoaded.value.stops = true;
}

async function loadTrips() {
  isLoaded.value.trips = false;

  const allTrips = await store.dispatch('trips/getPublishedTripsMapOn', {
    dateGtfs: selectedDateGtfs.value,
  });
  trips.value = Object.freeze(allTrips);

  isLoaded.value.trips = true;
}

async function loadTripUpdates() {
  const publications: Publication[] = await store.dispatch('gtfs/getGtfsPublicationsIn', {
    fromTs: midnight.value,
    toTs: midnight.value + 86400,
  });

  const localTripUpdates: ApiTripUpdate[] = [];

  await Promise.all(
    publications.map(async publication => {
      const updates = await TripUpdatesApi.getTripUpdatesForADate(
        group.value._id,
        selectedDateGtfs.value,
        publication.current_file,
      );
      localTripUpdates.push(...updates);
    }),
  );

  tripUpdates.value = localTripUpdates;
  isLoaded.value.tripUpdates = true;
}
</script>

<template>
  <div class="stop-detailed">
    <div class="stop-detailed__header">
      <div class="stop-detailed__header-left">
        <HeaderDatePicker v-model:value="selectedDate" />
      </div>

      <div class="stop-detailed__header-right">
        <a :download="downloadFileName" :href="downloadLink">
          <Btn type="secondary">
            <i class="fas fa-download" aria-hidden="true"></i>
            <span class="btn-text">{{ $t('download') }}</span>
          </Btn>
        </a>
        <Btn
          type="primary"
          class="stop-detailed__primary-btn"
          :route="{
            name: GroupRoute.REPORTING_PUNCTUALITY,
            path: GroupRoute.REPORTING_PUNCTUALITY,
            query: { startDate: defaultStartDateGtfs, endDate: defaultEndDateGtfs },
          }"
        >
          <font-awesome-icon icon="chart-simple" />
          <span class="btn-text">{{ $t('punctualityReport') }}</span>
        </Btn>
      </div>
    </div>

    <div class="stop-detailed__main">
      <div class="stop-detailed__left-main-side">
        <DataGridVuetify
          v-if="!emptyRowList"
          ref="dataGrid"
          v-model:rendered-data-length="renderedDataLength"
          :title="$t('passages', { count: renderedDataLength })"
          :build-cell-injectors="buildCellInjectors"
          :data="rows"
          :datagrid="datagrid"
          :loading="rowsListLoading || !isAllLoaded"
        />
        <div v-else class="stop-detailed__no-data">
          <div>
            <div v-if="nonExistingStop" class="stop-detailed__no-data-text">
              {{ $t('nonExistingStop') }}
            </div>
            <div v-else class="stop-detailed__no-data-text">{{ $t('noPassage') }}</div>
            <div>
              <Btn type="secondary" @click="goToPreviousDay">{{ $t('previousDay') }}</Btn>
              <Btn type="secondary" @click="goToNextDay">{{ $t('nextDay') }}</Btn>
            </div>
          </div>
        </div>
      </div>

      <div class="stop-detailed__right-main-side">
        <StopDetailedInfoCard v-if="stop" :date="selectedDate" :group-id="group._id" :stop-info="stop" />

        <div class="stop-detailed__map">
          <MapboxMap
            v-if="stop"
            ref="mapboxMap"
            border-radius="8px"
            :center="center"
            :gtfs-id="group.current_file"
            :stops="mapStop ? [mapStop] : []"
            :trips="[]"
            dropdown-style-option-only
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
$header-height: 48px;
$info-card-height: 84px;

.stop-detailed {
  height: 100%;
  padding: $view-standard-padding;
  background-color: $canvas;

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }

  &__header-left {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__header-right {
    display: flex;
    gap: 10px;
    align-items: center;

    .fas.fa-download {
      margin-right: 10px;
    }
  }

  &__main {
    display: flex;
    height: 80vh;
  }

  &__map {
    position: relative;
    height: calc(100vh - $navbar-top - $header-height - $info-card-height - ($view-standard-padding * 4));
    margin-top: $view-standard-padding;
    margin-bottom: $view-standard-padding;
  }

  &__map-dropdown {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: $map-dropdown;
  }

  &__no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }

  &__no-data-text {
    margin-bottom: 20px;
  }

  &__left-main-side {
    width: 65%;
    margin-right: 20px;
  }

  &__right-main-side {
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .datagrid {
    &__body {
      overflow-y: auto;
      max-height: 80vh;
      border: $border;
      border-radius: 8px;
      background-color: $background;
      box-shadow: none;
    }
  }

  .fa-chart-simple {
    color: white;
  }

  .mapboxgl-canvas {
    border-radius: 8px;
  }
}
</style>

<i18n locale="fr">
{
  "latitude": "Latitude",
  "longitude": "Longitude",
  "nonExistingStop": "Cet arrêt n'était pas dans le plan de transport à cette date.",
  "noPassage": "Aucun passage à cet arrêt à cette date.",
  "notFound": "Non trouvé",
  "recordedTime": "Horaire temps-réel",
  "routeName": "Ligne",
  "scheduledTime": "Horaire théorique",
  "station": "Station",
  "stop": "Arrêt",
  "passages": "passages",
  "tripHeadsign": "Girouette",
  "tripName": "Nom de course formaté",
  "punctualityReport": "Rapport de ponctualité",
  "previousDay": "Jour précédent",
  "nextDay": "Jour suivant",
}
</i18n>

<i18n locale="en">
{
  "latitude": "Latitude",
  "longitude": "Longitude",
  "nonExistingStop": "This stop was not part of the transport plan at this date.",
  "noPassage": "No passage at this stop at this date.",
  "notFound": "Not found",
  "recordedTime": "Time in real time",
  "routeName": "Line",
  "scheduledTime": "Theoretical time",
  "station": "Station ",
  "stop": "Stop",
  "passages": "passages",
  "tripHeadsign": "Trip headsign",
  "tripName": "Formatted trip name",
  "punctualityReport": "Go to punctuality report",
  "previousDay": "Previous day",
  "nextDay": "Next day",
}
</i18n>
