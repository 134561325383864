import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import { GroupRoute } from '@/libs/routing';

const AppVersionCell = defineAsyncComponent(() => import('@/pages/DeviceListPage/cells/AppVersionCell.vue'));
const DateCell = defineAsyncComponent(() => import('@/components/Table/cells/DateCell.vue'));
const MultipleTeamSelector = defineAsyncComponent(() => import('@/components/ui/MultipleTeamSelector.vue'));
const ConnectionCell = defineAsyncComponent(() => import('@/pages/DeviceListPage/cells/ConnectionCell.vue'));
const GtfsCell = defineAsyncComponent(() => import('@/pages/DeviceListPage/cells/GtfsCell.vue'));
const StatusCell = defineAsyncComponent(() => import('@/pages/DeviceListPage/cells/StatusCell.vue'));
const ClickableCell = defineAsyncComponent(() => import('@/components/Table/cells/ClickableCell.vue'));

export const DEVICE_LIST_LS_COLUMNS = 'deviceList/columnsSelection';

/** @enum {string} */
export const ColumnKey = {
  DEVICE_ID: 'device_id',
  DEVICE_NAME: 'name',
  LAST_CONNECTION: 'ts',
  APP_VERSION: 'sortableAppVersion',
  DEVICE_OS: 'device_os',
  DEVICE_MODEL: 'device_model',
  TEAMS: 'teams',
  UPDATED_GTFS: 'isGtfsUpToDate',
  APP_SOURCE: 'installer',
  DRIVE_MODE: 'drive_mode',
  TRIP_ASSIGNEMENT: 'trip_filter',
  DRIVER: 'driverInfos',
  VEHICLE: 'vehicleInfos',
  SIMULATION_MODE: 'simulation_mode',
  ACTION: 'actions',
  STATUS: 'status',
  CONNECTION: 'connection',
  ARCHIVED: 'archived',
};

export const getDatagrid = () => {
  const ls = JSON.parse(localStorage.getItem(DEVICE_LIST_LS_COLUMNS));

  // Helpers
  const defaultNotSelected = columnKey => ls && ls.includes(columnKey);
  const defaultSelected = columnKey => !ls || ls.includes(columnKey);

  return new DataGrid({
    name: 'deviceListDatagrid',
    searchFields: ['device_id', 'name'],
    defaultSortBy: [{ key: ColumnKey.LAST_CONNECTION, order: SortOrder.DESC }],
    rowIdKey: 'device_id',
    selectableList: true,
    columnSelectionLocalStorageKey: DEVICE_LIST_LS_COLUMNS,
    hasActions: true,
    columns: [
      // DEVICE ID
      new DataGridColumn({
        key: ColumnKey.DEVICE_ID,
        title: 'column.deviceId',
        defaultSelected: defaultSelected(ColumnKey.DEVICE_ID),
        cellBuilder([value, object]) {
          return {
            component: ClickableCell,
            props: {
              highlight: true,
              displayValue: value,
              link: {
                name: GroupRoute.DEVICE_DETAILLED,
                params: { deviceId: value },
              },
            },
          };
        },
      }),

      // DEVICE_NAME
      new DataGridColumn({
        key: ColumnKey.DEVICE_NAME,
        title: 'column.deviceName',
        defaultSelected: defaultSelected(ColumnKey.DEVICE_NAME),
        selectable: false,
        cellBuilder([value, object]) {
          return {
            component: ClickableCell,
            props: {
              highlight: true,
              displayValue: value,
              link: {
                name: GroupRoute.DEVICE_DETAILLED,
                params: { deviceId: object.device_id },
              },
            },
          };
        },
      }),

      // STATUS
      new DataGridColumn({
        key: ColumnKey.STATUS,
        title: 'column.status',
        defaultSelected: defaultSelected(ColumnKey.STATUS),
        selectable: false,
        filterable: true,
        i18nPrefix: 'deviceStatus',
        cellBuilder([value, object]) {
          return {
            component: StatusCell,
            props: {
              status: value,
            },
          };
        },
      }),

      // CONNECTION
      new DataGridColumn({
        key: ColumnKey.CONNECTION,
        title: 'column.connection',
        selectable: true,
        filterable: true,
        i18nPrefix: 'deviceConnectionStatus',
        cellBuilder([value, object]) {
          return {
            component: ConnectionCell,
            props: {
              status: value,
            },
          };
        },
      }),

      // LAST_CONNECTION
      new DataGridColumn({
        key: ColumnKey.LAST_CONNECTION,
        title: 'column.lastConnection',
        defaultSelected: defaultSelected(ColumnKey.LAST_CONNECTION),
        cellBuilder([value, object]) {
          return {
            component: DateCell,
            props: {
              date: value,
            },
          };
        },
      }),

      // APP_VERSION
      new DataGridColumn({
        key: ColumnKey.APP_VERSION,
        title: 'column.appVersion',
        defaultSelected: defaultSelected(ColumnKey.APP_VERSION),
        selectable: false,
        filterable: true,
        cellBuilder([value, object]) {
          if (!value) return null;
          return {
            component: AppVersionCell,
            props: {
              status: object.app_version_states,
              version: object.app_version,
            },
          };
        },
      }),

      // DEVICE_OS
      new DataGridColumn({
        key: ColumnKey.DEVICE_OS,
        title: 'column.deviceOs',
        defaultSelected: defaultNotSelected(ColumnKey.DEVICE_OS),
        filterable: true,
      }),

      // DEVICE_MODEL
      new DataGridColumn({
        key: ColumnKey.DEVICE_MODEL,
        title: 'column.deviceModel',
        defaultSelected: defaultNotSelected(ColumnKey.DEVICE_MODEL),
        sortable: false,
        filterable: true,
      }),

      // TEAMS
      new DataGridColumn({
        key: ColumnKey.TEAMS,
        title: 'column.teams',
        defaultSelected: defaultSelected(ColumnKey.TEAMS),
        filterable: true,
        sortable: false,
        cellBuilder([value]) {
          return {
            component: MultipleTeamSelector,
            props: {
              editMode: false,
              classicUi: true,
              multipleSelection: true,
              selectedTeams: value,
              showAll: false,
            },
          };
        },
      }),

      // UPDATED_GTFS
      new DataGridColumn({
        key: ColumnKey.UPDATED_GTFS,
        title: 'column.updatedGtfs',
        defaultSelected: defaultSelected(ColumnKey.UPDATED_GTFS),
        sortable: false,
        filterable: true,
        i18nPrefix: 'updatedGtfs',
        cellBuilder([value, object]) {
          return {
            component: GtfsCell,
            props: {
              isGtfsUpToDate: value,
            },
          };
        },
      }),

      // APP_SOURCE
      new DataGridColumn({
        key: ColumnKey.APP_SOURCE,
        title: 'column.appSource',
        defaultSelected: defaultNotSelected(ColumnKey.APP_SOURCE),
        sortable: false,
        filterable: true,
      }),

      // // DRIVE_MODE
      new DataGridColumn({
        key: ColumnKey.DRIVE_MODE,
        title: 'column.driverMode',
        defaultSelected: defaultNotSelected(ColumnKey.DRIVE_MODE),
        sortable: false,
        filterable: true,
      }),

      // // TRIP_ASSIGNEMENT
      new DataGridColumn({
        key: ColumnKey.TRIP_ASSIGNEMENT,
        title: 'column.assignement',
        defaultSelected: defaultNotSelected(ColumnKey.TRIP_ASSIGNEMENT),
        sortable: false,
        filterable: true,
      }),

      // // DRIVER
      new DataGridColumn({
        key: ColumnKey.DRIVER,
        title: 'column.driver',
        defaultSelected: defaultNotSelected(ColumnKey.DRIVER),
        sortable: false,
        filterable: true,
      }),

      // // VEHICLE
      new DataGridColumn({
        key: ColumnKey.VEHICLE,
        title: 'column.vehicle',
        defaultSelected: defaultNotSelected(ColumnKey.VEHICLE),
        sortable: false,
        filterable: true,
      }),

      // // SIMULATION_MODE
      new DataGridColumn({
        key: ColumnKey.SIMULATION_MODE,
        title: 'column.simulationMode',
        defaultSelected: defaultNotSelected(ColumnKey.SIMULATION_MODE),
        sortable: false,
        filterable: true,
      }),
    ],
  });
};
