import { defineAsyncComponent } from 'vue';
import {
  DataGrid,
  DataGridColumn,
  SortOrder,
} from '@/components/Table/DataGridVuetify/models/DataGrid.models';
import store from '@/store';
import { Permission } from '@/auth';

const CommentCell = defineAsyncComponent(() => import('@/components/Table/cells/CommentCell.vue'));
const EditableCell = defineAsyncComponent(() => import('@/components/Table/cells/EditableCell.vue'));
const MultipleTeamSelector = defineAsyncComponent(() => import('@/components/ui/MultipleTeamSelector.vue'));

export const VEHICLES_LS_COLUMNS = 'vehiclesPage/columnsSelection';

export enum ColumnKey {
  FLEET_NUMBER = 'fleet_number',
  LICENSE_PLATE = 'license_plate',
  TEAMS = 'teams',
  COMMENT = 'comment',
  CAPACITY = 'capacity',
}

export const VehicleCapacityValidation = {
  IS_VALID_NUMBER_RANGE: (value: number | undefined) => {
    if (!value) return true;
    return value >= 0 && value <= 999 && /^\d+$/.test(String(value));
  },
};

export const getDatagrid = () => {
  const lsValue = localStorage.getItem(VEHICLES_LS_COLUMNS);
  const ls = lsValue ? (JSON.parse(lsValue) as Array<string>) : null;

  // Helpers
  const defaultSelected = (columnType: ColumnKey) => !ls || ls.includes(columnType);

  return new DataGrid({
    name: 'vehicleListDatagrid',
    searchFields: ['fleet_number', 'license_plate'],
    defaultSortBy: [{ key: ColumnKey.FLEET_NUMBER, order: SortOrder.ASC }],
    columnSelectionLocalStorageKey: VEHICLES_LS_COLUMNS,
    hasActions: true,

    columns: [
      // FLEET NUMBER
      new DataGridColumn({
        key: ColumnKey.FLEET_NUMBER,
        title: 'column.fleet_number',
        defaultSelected: defaultSelected(ColumnKey.FLEET_NUMBER),
        selectable: false,
        cellBuilder(
          [value, object],
          { valueChanged, editMode }: { valueChanged: Function; editMode: boolean },
        ) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'fleet_number',
            },
          };
        },
      }),

      // LICENSE PLATE
      new DataGridColumn({
        key: ColumnKey.LICENSE_PLATE,
        title: 'column.license_plate',
        defaultSelected: defaultSelected(ColumnKey.LICENSE_PLATE),
        selectable: false,
        cellBuilder(
          [value, object],
          { valueChanged, editMode }: { valueChanged: Function; editMode: boolean },
        ) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'license_plate',
            },
          };
        },
      }),

      // CAPACITY
      new DataGridColumn({
        key: ColumnKey.CAPACITY,
        title: 'column.capacity',
        defaultSelected: defaultSelected(ColumnKey.CAPACITY),
        selectable: false,
        cellBuilder(
          [value, object],
          { valueChanged, editMode }: { valueChanged: Function; editMode: boolean },
        ) {
          return {
            component: EditableCell,
            events: { valueChanged },
            props: {
              _id: object._id,
              value,
              editMode,
              type: 'capacity',
              placeholder: '100',
              validation: VehicleCapacityValidation.IS_VALID_NUMBER_RANGE,
            },
          };
        },
      }),

      // TEAMS
      new DataGridColumn({
        key: ColumnKey.TEAMS,
        title: 'column.teams',
        defaultSelected: defaultSelected(ColumnKey.TEAMS),
        filterable: true,
        sortable: false,
        cellBuilder([value], { valueChanged, editMode }: { valueChanged: Function; editMode: boolean }) {
          return {
            component: MultipleTeamSelector,
            events: { valueChanged },
            props: {
              editMode: editMode,
              classicUi: true,
              multipleSelection: true,
              selectedTeams: value ?? [],
              showAll: false,
            },
          };
        },
      }),

      // COMMENT
      ...(store.getters.hasPermission(Permission.VIEW_TRIP_COMMENTS)
        ? [
            new DataGridColumn({
              key: ColumnKey.COMMENT,
              title: 'column.comment',
              defaultSelected: defaultSelected(ColumnKey.COMMENT),
              sortable: false,
              cellBuilder([value], { showModal }: { showModal: Function }) {
                return {
                  component: CommentCell,
                  events: { showModal },
                  props: { comment: value },
                };
              },
            }),
          ]
        : []),
    ],
  });
};
